<template>
    <div>
        <CommonHeader />
         <!--*************alert-->
        <div :class="alertClass" id="hidemessage" v-if="message">
            <p class="txt">{{alertMessage}}</p>
            <i class="fa fa-times close_msg" aria-hidden="true" @click="close_msg()"></i>
        </div>
        <!--*************alert-->
        <viewClientList :clients="clientList" @changeStatus="actionClientList" />
        <LoadingPage v-if="main_loader"/>
    </div>
    </template>
    
    <script>
    import {postApi} from '../assets/js/helper.js';
    import CommonHeader from './CommonHeader.vue'
    import viewClientList from './viewClientList.vue'
    import LoadingPage from './LoadingPage.vue'
    
    export default {
        name: 'ClientList',
        components: {
            CommonHeader,
            viewClientList,
            LoadingPage,
        },
        data() {
            return {
                clientList: null,
                   message: false,
                alertMessage: "",
                alertClass: "",
                 main_loader:true,
                
            }
        },
        created() {
            this.clientListLoad();
            
        },
        methods: {
            
            async clientListLoad() {
                
                const response = await postApi(
                    '/subUserProfessionalList', {
                        sub_user_id: localStorage.getItem('admin_id'),
                        
                    }, {},
                    
                )

                console.log(response);
                try {
                    if (response.errorCode == '0') {
                          this.clientList = response.data;
                            this.main_loader=false;
                            return false;
                    }
    
                } catch (err) {
                     this.alertMessage = err;
                    this.alertClass = "error_msg";
                    this.message = true;
                    setTimeout(() => this.message = false, 1000);
                }
            },
    
            async actionClientList(payload) {
             
                
          
                const response = await postApi(
                    '/subUserAcceptReject', {
                        id: payload.id,
                        status: payload.status,
                    }, {},
                )
                try {
                    if (response.errorCode == '0') {
                   
                        if(payload.status=='2')
                {
this.clientList=this.clientList.filter(data =>data.id != payload.id);
        }
        else
        {
            this.clientListLoad();
        }
                        return false;
                    }
    
                } catch (err) {
                     this.alertMessage = err;
                    this.alertClass = "error_msg";
                    this.message = true;
                    setTimeout(() => this.message = false, 1000);
                    
                }
    
    
            },
            
    
        },
         close_msg() {
                 this.message = false;
            },
    }
    </script>
    
    <style>
    .success {
        position: fixed;
        top: 0%;
        z-index: 999;
        width: 20%;
        padding: 1%;
        background: #fff;
        box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
        border-radius: 5px;
        right: 0;
        border-left: 4px solid #ffae00;
        transition: 1s;
    }
    
    p.txt {
        width: 91%;
        float: left;
        margin: 0;
    }
    
    .error_msg {
        position: fixed;
        top: 0%;
        z-index: 999;
        width: 20%;
        padding: 1%;
        background: #fff;
        box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
        border-radius: 5px;
        right: 0;
        border-left: 4px solid #db0014;
        transition: 1s;
        color: red;
    }
    </style>
    
    <style scoped>
    h1.hed {
        color: #f97d40;
    }
    
    input#myInput {
        border: 2px solid black;
        background: transparent;
        /* border-bottom: 2px solid #ddd; */
    }
    
    .search i {
        background: linear-gradient(85deg, #f66a59, #ffae00);
        padding: 10px;
        color: white;
        border-radius: 10px;
    }
    
    table.list-box {
        margin-top: 2%;
    }
    
    .search {
        width: 25%;
        float: left;
    }
    
    h1.hed {
        color: #f97d40;
        width: 72%;
        float: left;
    }
    </style>
    