<template>
  <CommonHeader />
  <div id="main-content">
      <div id="page-container">
             <!--*****alert-->
          <div :class="alertClass" id="hidemessage" v-if="message"><p class="txt">{{alertMessage}}</p>
              <i class="fa fa-times close_msg" aria-hidden="true" @click="close_msg()"></i>
          </div>
      <!--*****alert-->
        <!--*****alert red-->
        <div :class="alertClassr" id="hidemessager" v-if="messager"><p class="txt">{{alertMessager}}</p>
              <i class="fa fa-times close_msgr" aria-hidden="true" @click="close_msgr()"></i>
          </div>
      <!--*****alert red-->
          <!--*********common*****-->
          <div class="about_company">
              <div class="left">
                  <h1>Company Name  : <span  v-if="userProfileData">{{userProfileData.company_name}} </span></h1>
                  <h3>GSTIN: <span v-if="userProfileData">{{userProfileData.gstin_text}} </span></h3>
                  <h3 style="font-size: 12px;"> <span v-if="userProfileData" >{{tax_payer}}</span></h3>
              </div>
              <div class="right1">
                  <button @click="removeAccess" > <i class="fa fa-trash" aria-hidden="true" style="color: #f98436;"> </i> Remove</button>
                  <router-link v-if="userProfileData" :to="{ name: 'UserProfile', params:{user_id: userProfileData.user_id,company_id: userProfileData.id} }" ><button><i class="fa fa-external-link" aria-hidden="true" style="color: #f98436;"></i> View Profile</button></router-link>
                  <button class="view_plan" v-if="userProfileData">{{userProfileData.plan}} Plan</button>
              </div>
              <div class="clearfix"></div>
          </div>
          
           <div class="calender">
              <input class="btn btn-success submit last_month"  type="submit" v-on:click="last_month"  value="Last Month">
              <input class="btn btn-success submit preview_month" type="submit" v-on:click="current_month"  value="Current Month">
              <datepicker v-model="date" inputFormat="dd-MM-yyyy" id="start_date" class="cal1"></datepicker>
              <span style="    float: left;
  width: 36px;
  text-align: center;
  margin-top: 8px;"> To </span>
              <datepicker v-model="date2" inputFormat="dd-MM-yyyy" id="end_date" class="cal1"></datepicker>
              <button  v-if="userProfileData" class="btn submit last_month" type="submit" v-on:click="custom_month" value="Custom Month">Custom Month <img src="https://instabill.co/public/front/images/loader.gif"
                  class="load_show"
                  v-if="loader"
                  style="width: 15px;"
                /></button>
              <div class="clearfix"></div>
          </div>
          <div class="wrap">
              <div class="box_es">
                  <i class="fa fa-bar-chart" aria-hidden="true"></i>
  
                  <h3>Sales</h3>
                  <h4 v-if="userDashboardData"> <i class="fa fa-inr" aria-hidden="true"></i> {{userDashboardData.revenue_current}}</h4>
              </div>
              <div class="box_es" style="width: 23%;">
                  <i class="fa fa-cloud-upload" aria-hidden="true"></i>
  
                  <h3>Purchase & Expense</h3>
                  <h4 v-if="userDashboardData"> <i class="fa fa-inr" aria-hidden="true"></i> {{userDashboardData.expence_current}}</h4>
              </div>
              <div class="box_es">
                  <i class="fa fa-line-chart" aria-hidden="true"></i>
  
                  <h3>Profit & Loss</h3>
                  <h4 v-if="userDashboardData"> <i class="fa fa-inr" aria-hidden="true"></i> {{userDashboardData.profit_current}}</h4>
              </div>
              <div class="box_es">
                  <i class="fa fa-bar-chart" aria-hidden="true"></i>
  
                  <h3>Output GST</h3>
                  <p v-if="userDashboardData">CGST <span><i class="fa fa-inr" aria-hidden="true"></i> {{userDashboardData.ouput_cgst}}</span></p>
                  <p v-if="userDashboardData">SGST <span><i class="fa fa-inr" aria-hidden="true"></i> {{userDashboardData.ouput_sgst}}</span></p>
                  <p v-if="userDashboardData">IGST <span><i class="fa fa-inr" aria-hidden="true"></i> {{userDashboardData.ouput_igst}}</span></p>
  
              </div>
              <div class="box_es" style="width: 17%;">
                  <i class="fa fa-bar-chart" aria-hidden="true"></i>
  
                  <h3>Input GST</h3>
                  <p v-if="userDashboardData">CGST <span><i class="fa fa-inr" aria-hidden="true"></i> {{userDashboardData.input_cgst}}</span></p>
                  <p v-if="userDashboardData">SGST <span><i class="fa fa-inr" aria-hidden="true"></i> {{userDashboardData.input_sgst}}</span></p>
                  <p v-if="userDashboardData">IGST <span><i class="fa fa-inr" aria-hidden="true"></i> {{userDashboardData.input_igst}}</span></p>
              </div>
          </div>
          <div class="clearfix"></div>
  
          <div>
          </div>
         
  
          <!--*******cards end her-->
          <div class="feature-box right">
              <i class="fa fa-file-excel-o lnr lnr-rocket gradient-bg" aria-hidden="true"></i>
              <div class="feature-content">
                  <h3 class="subtitle"> Download Excel</h3>
                  
              <div style="">
                  <p style="cursor:pointer;     width: 50%;
  float: left;" v-on:click="saleDownload">
                  <span><i class="fa fa-line-chart ic" aria-hidden="true"></i> Sales <img
                  src="https://instabill.co/public/front/images/loader.gif"
                  class="load_show"
                  v-if="loader5"
                  style="width: 15px;"
                /></span> 
                   </p>
                   
                   <p style="cursor:pointer;     width: 50%;
  float: left;" v-on:click="saleReturnDownload">
                  <span><i class="fa fa-area-chart ic" aria-hidden="true"></i> Sales Return <img
                  src="https://instabill.co/public/front/images/loader.gif"
                  class="load_show"
                  v-if="loader3"
                  style="width: 15px;"
                /></span> 
                   </p>

             </div>


             <div  style="">
              <p style="cursor:pointer;     width: 50%;
  float: left;" v-on:click="purchaseDownload">
                  <span><i class="fa fa-cart-arrow-down ic" aria-hidden="true"></i> 
                  Purchases <img
                  src="https://instabill.co/public/front/images/loader.gif"
                  class="load_show"
                  v-if="loader1"
                  style="width: 15px;"
                /></span></p>
              
                <p style="cursor:pointer;     width: 50%;
  float: left;" v-on:click="purchaseReturnDownload">
                  <span><i class="fa fa-cart-arrow-down ic" aria-hidden="true"></i> 
                  Purchases Return <img
                  src="https://instabill.co/public/front/images/loader.gif"
                  class="load_show"
                  v-if="loader4"
                  style="width: 15px;"
              /></span></p>

             
              </div>

              <div  style="">
                  <p style="cursor:pointer;     width: 50%;
  float: left;" v-on:click="expenseDownload">
                  <span><i class="fa fa-money ic" aria-hidden="true"></i>Expenses 
                  <img
                  src="https://instabill.co/public/front/images/loader.gif"
                  class="load_show"
                  v-if="loader2"
                  style="width: 15px;"
                />
                  </span></p>

                 
              </div>                


           


              </div>
          </div>
  
          <div class="feature-box right">
              <i class="fa fa-university lnr lnr-rocket gradient-bg" aria-hidden="true"></i>
              <div class="feature-content">
                  <h3 class="subtitle" style="width: 45%;float: left;">Banking   </h3> <button class="btn btn-primary gradient-bg noti" v-on:click="sendNotification()">Send Suspense Notification</button>
                  <!-- <span class="badge1">Pending Entry</span> <span class="badge1">918</span> -->
                  <div class="clear"> </div>
                  <router-link v-if="userProfileData" 
                  :to="{ name: 'ViewBankstatement', params:{user_id: userProfileData.user_id,company_id: userProfileData.id} }"><span><i class="fa fa-file-text-o ic" aria-hidden="true"></i> View Bank Statement </span></router-link>
                  <a href="#" v-on:click="showModal()"><span style=""><i class="fa fa-cloud-download ic" aria-hidden="true"></i> Import Bank Statement </span></a>
                  <a href="#" v-on:click="showModal2()"><span><i class="fa fa-download ic" aria-hidden="true"></i>Download Bank Statement </span></a>
              </div>
          </div>
          
          
          
          <div class="clearfix"></div>
          <div class="feature-box right">
              <i class="fa fa-file-excel-o lnr lnr-rocket gradient-bg" aria-hidden="true"></i>
           
              <div class="feature-content">
                  <h3 class="subtitle">Fetch Data from GST Portal</h3>
             <div  style="">
              <p style="cursor:pointer;     width: 100%;
  float: left;" v-on:click="showModal5">
<span><i class="fa fa-line-chart ic" aria-hidden="true"></i> GSTR-1 Sales </span></p>
              
              </div>

             <!-- <div  style="">
              <router-link v-if="userProfileData" 
          :to="{ name: 'Gstr2b', 
          params:{user_id: userProfileData.user_id,company_id: userProfileData.id} }"
          >
              <p style="cursor:pointer;     width: 100%;
  float: left;">
<span><i class="fa fa-cart-arrow-down ic" aria-hidden="true"></i> GSTR-2B Purchases </span></p></router-link>
              </div> -->

              <div  style="">
                
                <p style="cursor:pointer;     width: 100%;
    float: left;"  v-on:click="showModal4">
  <span><i class="fa fa-cart-arrow-down ic" aria-hidden="true"></i> GSTR-2B Purchases </span></p>
                </div> 


              </div>
          </div>

          <div class="box_d">
            
              <div class="feature-content " style="text-align:center">
                  <h3 class="subtitle">All Business Reports</h3>
            
                  <button v-on:click="showModal6()"><i class="fa fa-download" aria-hidden="true"></i> Download</button>

              </div>
          </div>

          <div class="clearfix"></div>

          <div class="box1">
              <h3>Tally Integration</h3>
       <router-link v-if="userProfileData" 
          :to="{ name: 'TallyIntegration', params:{user_id: userProfileData.user_id,company_id: userProfileData.id} }">
            <button><i class="fa fa-external-link" aria-hidden="true"></i> View</button> </router-link>
          </div>
  
          <div class="box1">
              <h3>Documents</h3>
              <router-link v-if="userProfileData" 
              :to="{ name: 'Documents', params:{user_id: userProfileData.user_id,company_id: userProfileData.id} }">
                <button><i class="fa fa-external-link" aria-hidden="true"></i> View</button> </router-link>
               
          </div>

          <!-- <div class="box1">
              <h3>GSTR JSON</h3>
              <button v-on:click="showModal3()"><i class="fa fa-download" aria-hidden="true"></i> Download</button>
          </div> -->


          <div class="box1">
                 <h3>GST Filling</h3>
                  <!-- <button><router-link to="/CompanyDetails" style="color:white"> View </router-link></button> -->
                  <button v-on:click="showModal3()"><i class="fa fa-download" aria-hidden="true"></i> Show Details </button>
                    </div>
  
        


          <!-- <div class="box1">
              <h3>All Business Reports</h3>
              <router-link to="AllReports"><button @click="showalert()">View</button></router-link>
          </div> -->

         
  
          <!--******* Import Bank Statement sidebar *******-->
           <div id="setting2" class="setting gst-side" v-if="isModalVisible">
              <form v-on:submit.prevent="upload_bank_statement" method="post" enctype="multipart/form-data">
                  <div style="" class="">
                      <div class="search1"  style="">
                          <div class="add-gst" style="margin-bottom: 0px;">
                              <h3>Import Excel/PDF</h3>
                              <button type="button" v-on:click="closeModal()" class="close" id="setting_close1" data-dismiss="alert"><i class="fa fa-times" aria-hidden="true"></i><span class="sr-only">Close</span></button>
  
                              <div></div>
                          </div>
  
                          <div class="gstIn" style="margin-bottom:10px;">
  
                              <label style="color: #000;margin-bottom: 5px;font-weight: 600;" for="other_account_no">ACCOUNT NUMBER*</label><br>
<input style="border: 1px solid gray; padding: 7px;border-radius: 5px;" 
list="account_list" v-on:change="fetchAccountDetail()" v-model="account_no"  placeholder="ACCOUNT NUMBER*" 
required="">
<datalist id="account_list" >
  <div v-for="(accounts,index) in userAccountList"  :key="index">
<option   v-if="accounts.type==1" >
  {{accounts.account_no}}</option>
</div>
</datalist>
<br>

                          </div>
                          <div class="gstIn-corporate" style="margin-bottom:10px;">
  
                              <label style="color: #000;margin-bottom: 5px;font-weight: 600;" for="other_corporate_id">BANK NAME*</label><br>
                              <input style="border: 1px solid gray; padding: 7px;border-radius: 5px;" type="text" v-model="bank_name" placeholder="BANK NAME*" required=""><br>
  
                          </div>
  
                          <div class="gst-user" style="margin-bottom:10px;">
  
                              <label style="color: #000;margin-bottom: 5px;font-weight: 600;" for="other_bank_user_id">IFSC Code*</label><br>
                              <input style="border: 1px solid gray; padding: 7px;border-radius: 5px;" type="text" v-model="ifsc_code" placeholder="IFSC Code*" required="">
  
                          </div>
  
                          <div class="gst-user" style="margin-bottom:10px;">
                              <label style="color: #000;margin-bottom: 5px;font-weight: 600;" for="bank_user_id">IMPORT DOCUMENT*</label>
                              <div class="panel panel-default" style="">
  
                                  <div id="_2_1" class="uploader_purchase">
  
                                      <input id="fileUpload" type="file" name="fileUpload"  ref="file" required="" accept=".csv,.pdf" v-on:change="onFileChange">
  
                                      <label for="fileUpload" id="file-drag_1">
                                          <img id="file-image_1" src="#" alt="" class="hidden1">
                                           <div id="start2">
                                              <i class="fa fa-download" aria-hidden="true" style="font-size:40px;"></i>
                                              <div> </div>
                                              <div id="notimage_2" class="hidden2" style="color :red">Please select an CSV file format</div>
                                              <span id="file-upload-btn" class="btn btn-primary">Browse</span>
                                              
                                          </div>
                                          <div id="response2" class="hidden2" v-if="upload">
                                              <div id="messages2">{{import_file_name}}</div>
                                              <div class="progress">
                                                  <div class="value" :style="{ width: `${value}%` }"></div>
                                              </div>
                                          </div>
                                      </label>
                                      <div class="form-group" style="display: none;">
  
                                          <input type="checkbox" name="header" checked=""> File contains header row?
  
                                      </div>
                                  </div>
  
                              </div>
                              <div class="clear"></div>
                              <div class="gst-user" style="margin-bottom:10px;">
                                  <button type="submit" class="btn btn-success account_submit">
                                      <img src="https://instabill.co/public/front/images/loader.gif"
                                      class="load_show"
                                      v-if="loader7"
                                      style="width: 15px;"
                                    />
                                      UPLOAD
                                  </button>
  
                              </div>
  
                          </div>
                      </div>
                  </div>
              </form>
          </div>
          <!--*******Import Bank Statement sidebar closed *****-->
  
          <!--*******connect icici bank  sidebar*****-->
          <div id="setting" class="setting gst-side" style="" v-if="isModalVisible1">
              <div style="margin-bottom:20px; " class="" >
                  <div class="search1"  style="color:#cccc;float:left;margin-bottom:5px; margin-left:30px;margin-top:7px;">
                      <div class="add-gst" style="margin-bottom: 0px;">
                          <h3>ACCOUNT details</h3>
                          <div></div>
                          <img src="@/assets/img/icici_logo2.png" />
  
                          <p style="">Enter your Account Number credentials for Instabill<br> to fetch details from the ICICI Bank.</p>
                      </div>
  
                      <form v-on:submit.prevent="connectBank" method="post"  v-if="connect_icic_top">
                      <div class="gstIn" style="margin-bottom:10px;">
  
                          <label for="account_no">ACCOUNT NUMBER*</label><br>
                          <input  type="text" id="account_no"  v-model="account_no"  placeholder="ACCOUNT NUMBER*" required=""><br>
  
                      </div>
                      <div class="gstIn-corporate" style="margin-bottom:10px;">
  
                          <label for="corporate_id">CORPORATE ID*</label><br>
                          <input type="text"  id="corporate_id" v-model="corporate_id" placeholder="CORPORATE ID*" required=""><br>
  
                      </div>
                       <div class="gstIn-corporate" style="margin-bottom:10px;">
  
                          <label for="alias_id">ALIAS ID (Optional)</label><br>
                          <input type="text"  id="alias_id" v-model="alias_id" placeholder="ALIAS ID" required=""><br>
  
                      </div>
  
                      <div class="gst-user">
  
                          <label for="bank_user_id">USER ID*</label><br>
                          <input type="text"  id="bank_user_id" v-model="bank_user_id" placeholder="USER ID*" required="">
                          
                          
                          <button class="btn btn-success account_submit" style="margin-top:10px" type="submit">SUBMIT</button>
  
                          <a href="https://cadigital.icicibank.com/SmartFormWeb/apps/services/www/SmartFormWeb/desktopbrowser/default/index.html?source=BCApplication-InstaBill#/" target="_BLANK" style="color:red; font-size:15px;">
                              <div id="link_icici" style="margin-top:15px;">Don't have ICICI Bank account?<br> Open Current bank account in ICICI Bank<br> while sitting at your place. </div>
                          </a>
  
                      </div>
                     
  </form>
    
  
                  </div>
                  <button type="button" v-on:click="closeModal1()" class="close" id="setting_close" data-dismiss="alert"><i class="fa fa-times" aria-hidden="true"></i><span class="sr-only">Close</span></button>
            
            <div class="clear"></div>
              <div class="error-msg" style="display: block;" v-if="verify">
                  <div class="" style="padding-top:30px;">
                      <p style="color: #000;font-size: 15px;"><b style="font-size: 15px;
                          font-weight: 600;">INSTRUCTION TO CONNECT WITH BANKING</b></p>
                    
                      <p style="color: #000;font-size: 15px;">#1 Login to ICICI Corporate Net Banking</p>
                      <p style="color: #000;font-size: 15px;">#2 Select Option From Menu:<br>CONNECTED BANKING</p>
                      <p style="color: #000;font-size: 15px;">#3 Select Connected Banking Approvals</p>
                      <p style="color: #000;font-size: 15px;">#4 Select Instabill and Click On Approve</p>
                      <a href="https://drive.google.com/file/d/1arjGNzkfKtoCFCIMzC5xBODB28YOPZxV/view?usp=sharing" target="_blank" style="color: #256ff9;font-size: 15px;"><b style="font-weight: 600;">Guide to connect ICICI bank with Instabill</b></a>
                      <div style="">
                          <a href="https://wa.me/+917827950640" target="_blank" style="color: #256ff9;font-size: 15px; padding-top:10px"><b style="font-weight: 600;">Contact Support <img src="https://upload.wikimedia.org/wikipedia/commons/5/5e/WhatsApp_icon.png" style="width: 20px;"></b></a>
                      </div>
                  </div>
                  <div>
  
                      <form method="POST" action="https://instabill.co/icici_registration_verify" accept-charset="UTF-8"><input name="_token" type="hidden" value="FFySHO2aN76SUbz5KGz84fA8gDgKxu5DUmZo29OZ">
                          <input type="hidden" name="account_no" value="">
                          <input type="hidden" name="corporate_id" value="">
                          <input type="hidden" name="bank_user_id" value="">
                          <div class="entr-otp-wrp">
                              <div id="loader" style="display: none">
                              </div>
                          </div>
                      </form>
                  </div>
  
                  <div class="btn-devide-content">
                      <button id="setting_closes" v-on:click="closeModal1()" class="cancel">Cancel</button>
  
                      <button class="verify-otp" v-on:click="verify_account">Verify</button>
  
                  </div>
              </div>
              
            </div>
  
  
          </div>
          <!--*******connect icici bank  sidebar closed*****-->
  
          <!--*******download_excel_sidebar*****-->
          <div class="download_excel_sidebar" v-if="isModalVisible2">
              <p class="head_sb">Download Statement <span aria-hidden="" class="closed_open_m" v-on:click="closeModal2()"><i class="fa fa-times" aria-hidden="true"></i></span></p>
       <div  v-if="userAccountList">
            <div class="ac_detail" v-for="(account,index) in userAccountList" :key="index" >
                  <div class="left-a" >
                      <p><b>Ac No. </b>{{account.account_no}}</p>
                      <p><b>{{account.bank_name}} Bank</b></p>
                      <p><b style="font-style: italic; font-weight: 100;font-size: 12px;"><i class="fa fa-refresh" aria-hidden="true"></i> Last update on </b>{{account.last_update}}</p>
                  </div>
                  <p class="down" v-on:click="bank_statement_download(account.id)">
                  <i class="fa fa-download download_remove" aria-hidden="true" :id="'json' + index"></i>
                
                  </p>
                  
              </div> 
       </div>
         </div>
          <!--*******download_excel_sidebar end*****-->
          <div class="gstr_json_sidebar" v-if="isModalVisible3">
              <p class="head_sb">GSTR JSON <span aria-hidden="" class="closed_open_m" v-on:click="closeModal3()"><i class="fa fa-times" aria-hidden="true"></i></span></p>
  
              <div class="select_da">
                  <select name="month" id="month" v-model="month">
                     <option value="01">January</option>
                      <option value="02">February</option>
                      <option value="03">March</option>
                      <option value="04">April</option>
                      <option value="05">May</option>
                      <option value="06">June</option>
                      <option value="07">July</option>
                      <option value="08">August</option>
                      <option value="09">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                      <option value="13">Quarter1</option>
                      <option value="14">Quarter2</option>
                      <option value="15">Quarter3</option>
                      <option value="16">Quarter4</option>
                      <option value="1">Apr-June</option>
                      <option value="2">Jul-Sep</option>
                      <option value="3">Oct-Dec</option>
                      <option value="4">Jan-Mar</option>
                </select>
                  <select name="year" id="year" v-model="year">
                      <option value="2008">2008</option>
                      <option value="2009">2009</option>
                      <option value="2010">2010</option>
                      <option value="2011">2011</option>
                      <option value="2012">2012</option>
                      <option value="2013">2013</option>
                      <option value="2014">2014</option>
                      <option value="2015">2015</option>
                      <option value="2016">2016</option>
                      <option value="2017">2017</option>
                      <option value="2018">2018</option>
                      <option value="2019">2019</option>
                      <option value="2020">2020</option>
                      <option value="2021">2021</option>
                      <option value="2022">2022</option>
                      <option value="2023">2023</option>
                      <option value="2024">2024</option>
                      <option value="2025">2025</option>
                      
                  </select>
                  <!-- <span class="new_search_button" ><i class="fa fa-search "></i></span> -->
                  <div class="clearfix"></div>
              </div>
  
              <div class="ac_detail" v-if="gstr">
                  <div class="left-a">
                      <p><b>GSTR -1 </b></p>
                  </div>
                  <p class="down" v-on:click="gstr1Download"><i class="fa fa-download " aria-hidden="true"></i></p>
              </div>
  
              <div class="ac_detail" v-if="gstr">
                  <div class="left-a">
                      <p><b>GSTR -3B </b></p>
                  </div>
                  <p class="down" v-on:click="gstr3bDownload"><i class="fa fa-download " aria-hidden="true"></i></p>
              </div>
              
                  <div class="ac_detail" v-if="cmp">
                  <div class="left-a">
                      <p><b>CMP -08 </b></p>
                  </div>
                  <p class="down" v-on:click="cmp08Download"><i class="fa fa-download" aria-hidden="true"></i></p>
              </div>
             
          </div>
  

<!--*******download_excel_sidebar end*****-->






          <div class="gstr_json_sidebar" v-if="isModalVisible5">
              <p class="head_sb">FETCH GSTR-1 DATA <span aria-hidden="" class="closed_open_m" v-on:click="closeModal5()"><i class="fa fa-times" aria-hidden="true"></i></span></p>
            <label>From</label> 
              <div class="select_da">
                  <select name="month3" id="month3" v-model="month3">
                     <option value="1">January</option>
                      <option value="2">February</option>
                      <option value="3">March</option>
                      <option value="4">April</option>
                      <option value="5">May</option>
                      <option value="6">June</option>
                      <option value="7">July</option>
                      <option value="8">August</option>
                      <option value="9">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                </select>
                  <select name="year3" id="year3" v-model="year3">
                      <option value="2019">2019</option>
                      <option value="2020">2020</option>
                      <option value="2021">2021</option>
                      <option value="2022">2022</option>
                      <option value="2023">2023</option>
                      <option value="2024">2024</option>
                      <option value="2025">2025</option>
                  </select>
              </div>
              <div class="clearfix"></div>
              <br>
              <label>To</label> 
              <div class="select_da">
                   
                  <select name="month4" id="month4" v-model="month4">
                      <option value="1">January</option>
                      <option value="2">February</option>
                      <option value="3">March</option>
                      <option value="4">April</option>
                      <option value="5">May</option>
                      <option value="6">June</option>
                      <option value="7">July</option>
                      <option value="8">August</option>
                      <option value="9">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                </select>
                  <select name="year4" id="year4" v-model="year4">
                      <option value="2019">2019</option>
                      <option value="2020">2020</option>
                      <option value="2021">2021</option>
                      <option value="2022">2022</option>
                      <option value="2023">2023</option>
                      <option value="2024">2024</option>
                      <option value="2025">2025</option>
                  </select>
              </div>
              <div class="clearfix"></div>
              <br>
             <div class="select_da">
<label>GSTIN</label>
<br>
<input style="    border: 1px solid #d8e0e5;
    padding: 7px;
    border-radius: 5px;
    width: 95%;
    border-radius: 5px !important;
  " type="text" readonly maxlength="15" v-model="gstin"  placeholder="15 digits GST Number" required="">
             </div>
             <div class="clearfix"></div>
              <br>
              <div class="select_da">
<label>GST Username</label>
<br>
<input style="    border: 1px solid #d8e0e5;
    padding: 7px;
    border-radius: 5px;
    width: 95%;
    border-radius: 5px !important;" type="text"  placeholder="GSTN login username" v-model="username" required="">
</div>
<div class="tally_status"><button type="submit" class="btn btn-success account_submit" v-on:click="gstr1_otpsend">Submit</button></div>

<!-- otp section -->

<div class="resend-otp" v-if="sendotp1">
      <div class="add-gst">
          <br>
          <p style="color: #000;font-size: 15px;">Enter the One Time Password (OTP) Received on your mobile<br>number registered with the GST portal.</p>
      </div>
      
      
<div class="gstIn" style="margin-bottom:30px;">
  <form method="POST" action="gstr1_verify_otp" accept-charset="UTF-8">
          <div class="entr-otp-wrp">
            <input style="border: 1px solid #d8e0e5; padding: 7px;width: 94%;border-radius: 5px;" type="number" v-model="otp" placeholder="Enter OTP" maxlength="6" required="">
           </div>
  </form>
</div>

      <button type="submit" class="verify-otp"  v-on:click="verify_otp_gstr1">verify OTP 
      <img src="https://instabill.co/public/front/images/loader.gif"
                  class="load_show"
                  v-if="loader6"
                  style="width: 15px;"
                /></button>
  </div>


          </div>



          <!--*******download BPR Report end*****-->
          <div class="gstr_json_sidebar" v-if="isModalVisible6">
              <p class="head_sb">  Download Report<span aria-hidden="" class="closed_open_m" v-on:click="closeModal6()"><i class="fa fa-times" aria-hidden="true"></i></span></p>
  
              <div class="select_da">
                  <select name="month" id="month" v-model="month" style="width:47%!important">
                     <option value="1">January</option>
                      <option value="2">February</option>
                      <option value="3">March</option>
                      <option value="4" >April</option>
                      <option value="5">May</option>
                      <option value="6">June</option>
                      <option value="7">July</option>
                      <option value="8">August</option>
                      <option value="9">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                </select>
                  <select name="year" id="year" v-model="year" style="width:47%!important">
                      <option value="2008">2008</option>
                      <option value="2009">2009</option>
                      <option value="2010">2010</option>
                      <option value="2011">2011</option>
                      <option value="2012">2012</option>
                      <option value="2013">2013</option>
                      <option value="2014">2014</option>
                      <option value="2015">2015</option>
                      <option value="2016">2016</option>
                      <option value="2017">2017</option>
                      <option value="2018">2018</option>
                      <option value="2019">2019</option>
                      <option value="2020">2020</option>
                      <option value="2021">2021</option>
                      <option value="2022">2022</option>
                      <option value="2023">2023</option>
                      <option value="2024">2024</option>
                      <option value="2025">2025</option>
                      
                  </select>
                
                  <div class="clearfix"></div>
                  <br>
                  <span style="display: flex;
  align-items: center;" @click="downloadBpr()" class="span_one">
                  Business Performance  <img   src="https://instabill.co/public/images/pdf.png"
              class="load_show"
              style="width: 22px;"
            />
                  </span>
                 

                  <br>
                  <span style="display: flex;
  align-items: center;" v-if="gstr" class="span_two">
                  GSTR-1  <img  v-on:click="gstr1pdfDownload"  src="https://instabill.co/public/images/pdf.png"
              class="load_show"
              style="width: 22px;"
            />
            <img  src="https://instabill.co/public/front/images/loader.gif"
                  class="load_show"
                  v-if="loader8"
                  style="width: 15px;"
                />

            <img v-on:click="gstr1excelDownload"  src="https://instabill.co/public/images/xls.png"
            class="load_show"
            style="width: 22px;"
          />
          <img  src="https://instabill.co/public/front/images/loader.gif"
          class="load_show"
          v-if="loader9"
          style="width: 15px;"
        />
        <br>
                  </span>



<br>
                 
                  <span  style="display: flex;
  align-items: center;" v-if="gstr" class="span_three">
                  GSTR-3B <img  v-on:click="gstr3bpdfDownload"  src="https://instabill.co/public/images/pdf.png"
              class="load_show"
              style="width: 22px;"
            />
            <img  src="https://instabill.co/public/front/images/loader.gif"
                  class="load_show"
                  v-if="loader10"
                  style="width: 15px;"
                />

            <img v-on:click="gstr3bexcelDownload"  src="https://instabill.co/public/images/xls.png"
            class="load_show"
            style="width: 22px;"
          />
          <img  src="https://instabill.co/public/front/images/loader.gif"
          class="load_show"
          v-if="loader11"
          style="width: 15px;"
        />
                  </span>




                  <br>
                  <span style="display: flex;
  align-items: center;" v-if="cmp">
  
                  CMP-08  <img  v-on:click="cmppdfDownload"  src="https://instabill.co/public/images/pdf.png"
              class="load_show"
              style="width: 22px;"
            />
            <img  src="https://instabill.co/public/front/images/loader.gif"
                  class="load_show"
                  v-if="loader12"
                  style="width: 15px;"
                />

            <img v-on:click="cmpexcelDownload"  src="https://instabill.co/public/images/xls.png"
            class="load_show"
            style="width: 22px;"
          />
          <img  src="https://instabill.co/public/front/images/loader.gif"
          class="load_show"
          v-if="loader13"
          style="width: 15px;"
        />
                  </span>



              </div>

  
             
          </div>
  
<!--*******download BPR Report end*****-->



 <!--fetch gst2rb sidebar-->
 <div class="gstr_json_sidebar" v-if="isModalVisible4">
  <p class="head_sb">FETCH GSTR-2B DATA <span aria-hidden="" class="closed_open_m" v-on:click="closeModal4()"><i class="fa fa-times" aria-hidden="true"></i></span></p>
<label>From</label> 
  <div class="select_da">
      <select name="month1" id="month1" v-model="month1">
         <option value="1">January</option>
          <option value="2">February</option>
          <option value="3">March</option>
          <option value="4">April</option>
          <option value="5">May</option>
          <option value="6">June</option>
          <option value="7">July</option>
          <option value="8">August</option>
          <option value="9">September</option>
          <option value="10">October</option>
          <option value="11">November</option>
          <option value="12">December</option>
    </select>
      <select name="year1" id="year1" v-model="year1">
          <option value="2021">2021</option>
          <option value="2022">2022</option>
          <option value="2023">2023</option>
          <option value="2024">2024</option>
          <option value="2025">2025</option>
      </select>
  </div>
  <div class="clearfix"></div>
  <br>
  <label>To</label> 
  <div class="select_da">
       
      <select name="month2" id="month2" v-model="month2">
          <option value="1">January</option>
          <option value="2">February</option>
          <option value="3">March</option>
          <option value="4">April</option>
          <option value="5">May</option>
          <option value="6">June</option>
          <option value="7">July</option>
          <option value="8">August</option>
          <option value="9">September</option>
          <option value="10">October</option>
          <option value="11">November</option>
          <option value="12">December</option>
    </select>
      <select name="year2" id="year2" v-model="year2">
          <option value="2021">2021</option>
          <option value="2022">2022</option>
          <option value="2023">2023</option>
          <option value="2024">2024</option>
          <option value="2025">2025</option>
      </select>
  </div>
  <div class="clearfix"></div>
  <br>
 <div class="select_da">
<label>GSTIN</label>
<br>
<input style="    border: 1px solid #d8e0e5;
    padding: 7px;
    border-radius: 5px !important;
    width: 95%;
" type="text" readonly maxlength="15" v-model="gstin"  placeholder="15 digits GST Number" required="">
 </div>
 <div class="clearfix"></div>
  <br>
  <div class="select_da">
<label>GST Username</label>
<br>
<input style="    border: 1px solid #d8e0e5;
    padding: 7px;
    border-radius: 5px !important;
    width: 95%;" type="text"  placeholder="GSTN login username" v-model="username" required="">
</div>
<div class="tally_status"><button type="submit" class="btn btn-success account_submit" v-on:click="gstr2b_otpsend">Submit</button></div>

<!-- otp section -->

<div class="resend-otp" v-if="sendotp">
<div class="add-gst">
<br>
<p style="color: #000;font-size: 15px;">Enter the One Time Password (OTP) Received on your mobile<br>number registered with the GST portal.</p>
</div>


<div class="gstIn" style="margin-bottom:30px;">
<form method="POST" action="gstr2b_verify_otp" accept-charset="UTF-8">
<div class="entr-otp-wrp">
<input style="border: 1px solid gray; padding: 7px;width: 79%;border-radius: 5px;" type="number" v-model="otp" placeholder="Enter OTP" maxlength="6" required="">
</div>
</form>
</div>

<button type="submit" class="verify-otp"  v-on:click="verify_otp_gstr2a">verify OTP 
<img src="https://instabill.co/public/front/images/loader.gif"
      class="load_show"
      v-if="loader6"
      style="width: 15px;"
    /></button>
</div>


</div>

 <!--fetch end  gst2rb sidebar-->

      </div>
  </div>
  
  </template>
  
  <script>
  import CommonHeader from './CommonHeader.vue';
  import datepicker from 'vue3-datepicker'
  import axios from 'axios'
  import { postApi , postApi2} from "../assets/js/helper.js";
  export default {
      name: 'ViewDetails',
      components: {
          datepicker,
          CommonHeader
      },
      props:["user_id","company_id"],
      created() {
        localStorage.setItem('reloaded', 'false');
        localStorage.setItem('reloaded2', 'false');
          window.scrollTo(0, 0);
   this.getProfileData();
   this.getUserDashboardData(this.userId,this.companyId,this.date,this.date2);
   this.getAccountList();
   const current = new Date();
   var month=current.getMonth()+"";
   if(month == "0")
       {
         month="01";
       }
       else
       {
         month=parseInt(month) + 1;
      }
   this.month=month;
   this.year=current.getFullYear();
     
  
  },
      data() {
          return {
              date: new Date(new Date().getFullYear(),new Date().getMonth(),1),
              date2: new Date(new Date().getFullYear(),new Date().getMonth()+1,0),
              isModalVisible: false,
              isModalVisible1: false,
              isModalVisible2: false,
              isModalVisible3: false,
              isModalVisible4: false,
              isModalVisible5: false,
              isModalVisible6: false,
              sendotp:false,
              sendotp1:false,
              userId:this.user_id,
              companyId:this.company_id,
              userProfileData:null,
              userDashboardData:null,
              userAccountList:null,
              account_no:"",
              corporate_id:"",
              alias_id:"",
              import_file_name:"",
              bank_user_id:"",
              bank_name:"",
              ifsc_code:"",
              file:"",
              value:0,
              upload:false,
              tax_payer:"",
              loader:false,
              message: false,
              messager: false,
              alertMessage:"",
              alertMessager:"",
              alertClass:"",
              alertClassr:"",
              gstr:false,
              cmp:false,
              year:"",
              month:"",
              year1:"2023",
              month1:"1",
              year2:"2023",
              month2:"1",
              year3:"2023",
              month3:"1",
              year4:"2023",
              month4:"1",
              loader1:"",
              loader2:"",
              loader3:"",
              loader4:"",
              loader5:"",
              loader6:"",
              loader7:"",
              loader8:"",
              loader9:"",
              loader10:"",
              loader11:"",
              loader12:"",
              loader13:"",
              verify:false,
              connect_icic_top:true,
              gstin:"",
              username:"",
              otp:"",
              extension:"",
              
          }
      },
      methods: {
          showModal() {
              this.isModalVisible = true;
          },
          closeModal() {
              this.isModalVisible = false;
          },
          showModal1() {
              this.isModalVisible1 = true;
          },
          closeModal1() {
              this.isModalVisible1 = false;
              this.account_no="";
              this.corporate_id="";
              this.bank_user_id="";
          },
          showModal2() {
              this.isModalVisible2 = true;
          },
          closeModal2() {
              this.isModalVisible2 = false;
          },
          showModal3() {
              this.isModalVisible3 = true;
          },
          closeModal3() {
              this.isModalVisible3 = false;
          },
          showModal4() {
                this.isModalVisible4 = true;
            },
          closeModal4() {
              this.isModalVisible4 = false;
          },
          showModal5() {
              this.isModalVisible5 = true;
          },
          closeModal5() {
              this.isModalVisible5 = false;
          },
          showModal6() {
              this.isModalVisible6 = true;
          },
          closeModal6() {
              this.isModalVisible6 = false;
          },
   async getProfileData() {
  
   const response = await postApi(
          "/view_user_profile",
          {
            company_id: this.companyId,
          },
          {}
        );
        try {
          if (response.errorCode == "0") {
            console.log(response.data);
           
         this.userProfileData=response.data;
  
         this.gstin=this.userProfileData.gstin_text;
         this.username=this.userProfileData.gstin_username;
         if(response.data.gst_composite_scheme == "0")
         {
  this.tax_payer="Normal Taxpayer";
  this.gstr=true;
  this.cmp=false;
  
         }
         else
         {
          this.cmp=true;
          this.gstr=false;
          this.tax_payer="Composition Taxpayer";
         }
         return false;
          } 
        } catch (err) {
          this.alertMessage = err;
                  this.alertClass = "error_msg";
                  this.message = true;
                  setTimeout(() => this.message = false, 3000);
          
        }
   },
  
   async getUserDashboardData(userId,companyId,date,date2) {
      this.loader = true;
   const response = await postApi(
          "/view_user_dashboard_details",
          {
            user_id:userId,
            company_id: companyId,
            start_date:date,
            end_date:date2,
          },
          {}
        );
        try {
          if (response.errorCode == "0") {
            console.log(response.data);
         this.userDashboardData=response.data;
          this.loader = false;
         return false;
          } 
        } catch (err) {
          this.alertMessage = err;
                  this.alertClass = "error_msg";
                  this.message = true;
                  setTimeout(() => this.message = false, 3000);
        }
   },
  
   custom_month(){
       this.getUserDashboardData(this.userId,this.companyId,this.date,this.date2);
   },
   last_month(){
       this.date=new Date(new Date().getFullYear(),new Date().getMonth()- 1,1);
      this.date2=new Date(new Date().getFullYear(),new Date().getMonth(),0);
      
       this.getUserDashboardData(this.userId,this.companyId,this.date,this.date2);
   },
   current_month(){
      this.date=new Date(new Date().getFullYear(),new Date().getMonth(),1);
      this.date2=new Date(new Date().getFullYear(),new Date().getMonth()+1,0);
       this.getUserDashboardData(this.userId,this.companyId,this.date,this.date2);
   },
  saleDownload(){
  
  var companyId = this.companyId;
  var date=document.getElementById("start_date").value;
  var date2=document.getElementById("end_date").value;
  this.loader5 = true;
  axios(
      {
  url:"https://instabill.co/api/invoice/"+companyId+"/"+date+"/"+date2+"",
  method:"GET",
  responseType:"blob",
      }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', "invoice.csv")
    link.click()
    this.loader5 = false;
  });
  },
  
  purchaseDownload(){
      this.loader1 = true;
  var companyId = this.companyId;
  var date=document.getElementById("start_date").value;
  var date2=document.getElementById("end_date").value;
  axios(
      {
  url:"https://instabill.co/api/expence/"+companyId+"/"+date+"/"+date2+"/purchase",
  method:"GET",
  responseType:"blob",
      }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', "purchase.csv")
    link.click()
    this.loader1 = false;
  });
  },
  
  expenseDownload(){
      this.loader2 = true;
  var companyId = this.companyId;
  var date=document.getElementById("start_date").value;
  var date2=document.getElementById("end_date").value;
  axios(
      {
  url:"https://instabill.co/api/expence/"+companyId+"/"+date+"/"+date2+"/expense",
  method:"GET",
  responseType:"blob",
      }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', "expense.csv")
    link.click()
    this.loader2 = false;
  });
  },
  
  gstr1Download(){
  
  var parent = event.target;
  console.log(parent);    
  event.target.classList.remove('fa-download');
  event.target.classList.add('fa-spinner', 'fa-pulse');
  
  var companyId = this.companyId;
  var month=document.getElementById("month").value;
  var year=document.getElementById("year").value;
  
  axios(
      {
  url:"https://instabill.co/api/gstr1_download/"+companyId+"/"+month+"/"+year+"",
  method:"GET",
  responseType:"blob",
      }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', "gstr1.json")
    link.click()
     parent.classList.remove('fa-spinner', 'fa-pulse');
    parent.classList.add('fa-download');
  });
  },
  
  gstr3bDownload(){
      var parent = event.target;
  console.log(parent);    
  event.target.classList.remove('fa-download');
  event.target.classList.add('fa-spinner', 'fa-pulse');
  
  var companyId = this.companyId;
  var month=document.getElementById("month").value;
  var year=document.getElementById("year").value;
  
  axios(
      {
  url:"https://instabill.co/api/gstr3b_download/"+companyId+"/"+month+"/"+year+"",
  method:"GET",
  responseType:"blob",
      }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', "gstr3b.json")
    link.click()
     parent.classList.remove('fa-spinner', 'fa-pulse');
    parent.classList.add('fa-download');
  });
  },
    
  cmp08Download(){
      var parent = event.target;
  console.log(parent);    
  event.target.classList.remove('fa-download');
  event.target.classList.add('fa-spinner', 'fa-pulse');
  var companyId = this.companyId;
  var month=document.getElementById("month").value;
  var year=document.getElementById("year").value;
  
  axios(
      {
  url:"https://instabill.co/api/cmp08_download/"+companyId+"/"+month+"/"+year+"",
  method:"GET",
  responseType:"blob",
      }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', "cmp-08.json")
    link.click()
     parent.classList.remove('fa-spinner', 'fa-pulse');
    parent.classList.add('fa-download');
  });
  },
  
  async getAccountList() {
   const response = await postApi(
          "/get_bank_account_lists",
          {
            user_id:this.user_id,  
            company_id: this.companyId,
          },
          {}
        );
        try {
          if (response.errorCode == "0") {
            console.log(response.data);
         this.userAccountList=response.data;
  
         return false;
          } 
        } catch (err) {
          this.alertMessage = err;
                  this.alertClass = "error_msg";
                  this.message = true;
                  setTimeout(() => this.message = false, 3000);
        }
   },  
  
  bank_statement_download(id){
  
  // console.log(event.target);
  var parent = event.target;
  console.log(parent);    
  event.target.classList.remove('fa-download');
  event.target.classList.add('fa-spinner', 'fa-pulse');
  
  
  var user_id=this.user_id;    
  var companyId = this.companyId;
  var date=document.getElementById("start_date").value;
  var date2=document.getElementById("end_date").value;
  
  axios(
      
      {
          
  url:"https://instabill.co/api/bank_transaction_excel/"+user_id+"/"+companyId+"/"+id+"/"+date+"/"+date2+"",
  method:"GET",
  responseType:"blob",
      }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', "bankStatemnt.csv")
    link.click();
    parent.classList.remove('fa-spinner', 'fa-pulse');
    parent.classList.add('fa-download');
  });
    
   
  },
  
  
  async connectBank(){
      
  var account_no=this.account_no;
  var corporate_id=this.corporate_id;
  var bank_user_id=this.bank_user_id;
  var alias_id=this.alias_id;
  // alert(user_id+" "+company_id+" "+account_no+" "+corporate_id+" "+bank_user_id);
   const response = await postApi2(
          "/icici_registration",
          {
            user_id:this.user_id,  
            company_id: this.companyId,
            account_no:account_no,
            corporate_id:corporate_id,
            bank_user_id:bank_user_id,
            alias_id:alias_id,
          },
          {}
        );
        try {
          if (response.errorCode == "0") {
                  this.alertMessage = response.errorMsg;
                  this.alertClass = "success";
                  this.message = true;
                  setTimeout(() => this.message = false, 3000);
  this.connect_icic_top=false;
      this.verify=true;
         return false;
          } 
  else
  {
       this.alertMessage = response.errorMsg;
                  this.alertClass = "error_msg";
                  this.message = true;
                  setTimeout(() => this.message = false, 3000);
      
  }
  
        } catch (err) {
                  this.alertMessage = err;
                  this.alertClass = "error_msg";
                  this.message = true;
                  setTimeout(() => this.message = false, 3000);
    
        }
  },
    
  async verify_account(){
  var account_no=this.account_no;
  var corporate_id=this.corporate_id;
  var bank_user_id=this.bank_user_id;
  const response = await postApi2(
          "/icici_registration_verify",
          {
            user_id:this.user_id,  
            company_id: this.companyId,
            account_no:account_no,
            corporate_id:corporate_id,
            bank_user_id:bank_user_id,
          },
          {}
        );
        try {
          if (response.errorCode == "0") {         
  this.account_no="";
  this.corporate_id="";
  this.bank_user_id="";
  this.alias_id="";
  this.isModalVisible1 = false;
  this.verify=false;
                  this.alertMessage = response.errorMsg;
                  this.alertClass = "success";
                  this.message = true;
                  setTimeout(() => this.message = false, 3000);
                  
   return false;
          } 
  else
  {
     
                   this.alertMessage = response.errorMsg;
                  this.alertClass = "error_msg";
                  this.message = true;
                  setTimeout(() => this.message = false, 3000);
  }
  
        } catch (err) {
           this.alertMessage = err;
                  this.alertClass = "error_msg";
                  this.message = true;
                  setTimeout(() => this.message = false, 3000);
          
        }
  },
   
  upload_bank_statement(){
      this.loader7=true;
  this.file=this.$refs.file.files[0];
  this.extension=this.$refs.file.files[0].type;

  let formData = new FormData();
     formData.append('fileUpload', this.file);
     formData.append('account_no', this.account_no);
     formData.append('bank_name', this.bank_name);
     formData.append('ifsc_code', this.ifsc_code);
     formData.append('user_id', this.user_id);
     formData.append('company_id', this.company_id);
     formData.append('header', true);

if(this.extension == "application/pdf")
{
axios.post("https://instabill.co/api/import_entera_bank_statement", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
       console.log(response);
       this.loader7=false;
       this.alertMessage = "Bank Statement Import Succesfully";
 this.message = true;
 setTimeout(() => this.message = false, 3000);  
      this.isModalVisible=false;        
       this.$router.push({ 
      name: 'Documents', 
   params:{ 
   user_id:this.user_id,
   company_id:this.company_id
   } 
  });
      });
}else{   
    axios.post("https://instabill.co/api/import_otherbank_header", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
       console.log(response);

if(response.data.errorCode=="1")
{
this.alertMessager = response.data.errorMsg;
 this.messager = true;
 setTimeout(() => this.messager = false, 3000); 
}
else{
  localStorage.setItem("headers", JSON.stringify(response.data.data));
   this.$router.push({ 
      name: 'ImportBanking', 
   params:{ 
   user_id:this.user_id,
   company_id:this.company_id,
   csv_db_id:response.data.csv_data_file_id,
   bank_id:response.data.bank_id,
   } 
  });
  
}
 }).catch((error) => {
      console.log(error);
      });

  }

  } ,
  
  onFileChange(){
        if(this.file !== null){
          this.upload=true;

     var file= document.getElementById("fileUpload").files[0].name;
  this.import_file_name=file;

  this.value += 100;
                   
  }
   
    },
  
  async removeAccess()
    {
    var check=confirm("Are You Sure?");
  if(check == true )
  {
   const response = await postApi(
                  '/subUserReject', {
                     user_id:this.user_id,
                     company_id:this.company_id,
                  }, {},
              )
              try {
                  if (response.errorCode == '0') {
                     this.$router.push("/ClientList");
                      this.alertMessage = "Access remove Successfully";
                  this.alertClass = "success";
                  this.message = true;
                  setTimeout(() => this.message = false, 1000);
                          
                      return false;
                  }
  
              } catch (err) {
                  this.alertMessage = err;
                  this.alertClass = "error_msg";
                  this.message = true;
                  setTimeout(() => this.message = false, 1000);
  
                 
              }
  
  }
  
    },
 
  showalert(){
                  this.alertMessage = "Coming Soon";
                  this.alertClass = "success";
                  this.message = true;
                  setTimeout(() => this.message = false, 1000);
    },
  
  close_msg() {
               this.message = false;
          },
           close_msgr() {
               this.message = false;
          },
      
  saleReturnDownload(){
  var companyId = this.companyId;
  var date=document.getElementById("start_date").value;
  var date2=document.getElementById("end_date").value;
  this.loader3 = true;
  axios(
      {
  url:"https://instabill.co/api/credit/"+companyId+"/"+date+"/"+date2+"",
  method:"GET",
  responseType:"blob",
      }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', "credit.csv")
    link.click()
    this.loader3 = false;
  });
  },
  
  purchaseReturnDownload(){
      this.loader4 = true;
  var companyId = this.companyId;
  var date=document.getElementById("start_date").value;
  var date2=document.getElementById("end_date").value;
  axios(
      {
  url:"https://instabill.co/api/debit/"+companyId+"/"+date+"/"+date2+"",
  method:"GET",
  responseType:"blob",
      }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', "debit.csv")
    link.click()
    this.loader4 = false;
  });
  },
   
    async sendNotification() {
   const response = await postApi(
          "/sendBankingNotification",
          {
            user_id:this.user_id,  
            company_id: this.companyId,
          },
          {}
        );
        try {
          if (response.errorCode == "0") {
                  this.alertMessage ="Notification Send Successfully" ;
                  this.alertClass = "success";
                  this.message = true;
                  setTimeout(() => this.message = false, 3000);
         return false;
          } 
        } catch (err) {
          this.alertMessage = err;
                  this.alertClass = "error_msg";
                  this.message = true;
                  setTimeout(() => this.message = false, 3000);
        }
   }
   ,

   async fetchAccountDetail() {
var account_no=this.account_no;
if(account_no != "")
{
 const response = await postApi(
          "/fetchAccountDetail",
          {
            user_id:this.user_id,  
            company_id: this.companyId,
            account_no: this.account_no,
          },
          {}
        );
        try {
          if (response.errorCode == "0") {
          this.bank_name=response.data[0].bank_name;
          this.ifsc_code=response.data[0].ifsc_code;
         return false;
          }
          else {
         console.log(response);
        }


        } catch (err) {
         console.log(err);
        }
   }

      
      },

      async gstr2b_otpsend() {

       var gstin=this.gstin;
       var username=this.username;
       var month1=this.month1;
       var month2=this.month2;
       var year1=this.year1;
       var year2=this.year2;
     
       if(gstin=="" || username=="" || month1=="" || month2=="" || year1=="" || year2=="" ){
         
            this.alertMessage = "Please Fill all form details";
 this.message = true;
 setTimeout(() => this.message = false, 3000);  
       }
      else{  
   const response = await postApi(
          "/send_otp_gstr2a",
          {
            gstin:gstin,
            username:username
          },
          {}
        );
        try {
          if (response.errorCode == "0") {
       alert(response.errorMsg);
       this.alertMessage = response.errorMsg;
 this.message = true;
 setTimeout(() => this.message = false, 3000); 
       this.sendotp = true;
          }else{
              this.alertMessager = response.errorMsg;
 this.messager = true;
 setTimeout(() => this.messager = false, 3000); 
          } 
        } catch (err) {
          alert(err);
        }
   } 

  }  ,
  
 async verify_otp_gstr2a(){

      var otp=this.otp;
      var gstin=this.gstin;
       var username=this.username;
      var  year1=this.year1;
      var  month1=this.month1;
      var  year2=this.year2;
      var  month2=this.month2;
      
      if(gstin=="" || username=="" || month1=="" || month2=="" || year1=="" || year2=="" ){

           this.alertMessage = "Please Fill all form details";
 this.message = true;
 setTimeout(() => this.message = false, 3000);  
       }
    
      if(otp==""){
 this.alertMessage = "Please Enter OTP";
 this.message = true;
 setTimeout(() => this.message = false, 3000);  
      }else{
          this.loader6=true;
     
   const response = await postApi(
          "/gstr2b_verify_otp",
          {
            gstin:gstin,
            username:username,
            otp:otp,
          },
          {}
        );
        try {
          if (response.errorCode == "0") {
         var authtoken=response.data;
 
          for(var k=year1 ; k<=year2;k++){
              if(k == year2){
for(var i=month1 ; i<=month2;i++){
  const response = await postApi(
          "/get_datagstr2b_gstportal",
          {
            gstin:gstin,
            username:username,
            month:i,
            year:k,
            authtoken:authtoken,
            userId:this.user_id,  
            company_id: this.companyId,
           },
          {}
        );
        try {
          if (response.errorCode == "0") {
           console.log("asd");
          }else{
              console.log("asd");
          } 
        } catch (err) {
          alert(err);
        }
}
 }
 else{
 for(var j=month1 ; j<=12;j++){
  const response = await postApi(
          "/get_datagstr2b_gstportal",
          {
            gstin:gstin,
            username:username,
            month:j,
            year:k,
            authtoken:authtoken,
            userId:this.user_id,  
            company_id: this.companyId,
           },
          {}
        );
        try {
          if (response.errorCode == "0") {
           console.log("asd");
          }else{
              console.log("asd");
          } 
        } catch (err) {
          alert(err);
        }
}
 }
} 
 this.alertMessage = "GSTR-2B Data Fetch Successfully";
 this.message = true;
 setTimeout(() => this.message = false, 3000);  
this.sendotp = false;
this.otp = false;
this.loader6=false;
this.isModalVisible4 = false;
          }else{
              this.alertMessager = response.errorMsg;
 this.messager = true;
 setTimeout(() => this.messager = false, 3000); 
              this.loader6=false;
              this.isModalVisible4 = false;
          } 
        } catch (err) {
          alert(err);
        }
  }
},

async gstr1_otpsend() {

var gstin=this.gstin;
var username=this.username;
var month3=this.month3;
var month4=this.month4;
var year3=this.year3;
var year4=this.year4;

if(gstin=="" || username=="" || month3=="" || month4=="" || year3=="" || year4=="" ){

 this.alertMessage = "Please Fill all form details";
 this.message = true;
 setTimeout(() => this.message = false, 3000);
}
else{  
const response = await postApi(
 "/send_otp_gstr2a",
 {
   gstin:gstin,
   username:username
 },
 {}
);
try {
 if (response.errorCode == "0") {

this.alertMessage = response.errorMsg;
 this.message = true;
 setTimeout(() => this.message = false, 3000);
this.sendotp1 = true;
 }else{
     this.alertMessager = response.errorMsg;
 this.messager = true;
 setTimeout(() => this.messager = false, 3000); 
 } 
} catch (err) {
 alert(err);
}
} 

}  ,

async verify_otp_gstr1(){

var otp=this.otp;
var gstin=this.gstin;
var username=this.username;
var  year3=this.year3;
var  month3=this.month3;
var  year4=this.year4;
var  month4=this.month4;

if(gstin=="" || username=="" || month3=="" || month3=="" || year4=="" || year4=="" ){

  this.alertMessage = "Please Fill all form details";
 this.message = true;
 setTimeout(() => this.message = false, 3000);
}

if(otp==""){
this.alertMessage = "Please Enter OTP";
 this.message = true;
 setTimeout(() => this.message = false, 3000);
}else{
  this.loader6=true;

const response = await postApi(
  "/gstr2b_verify_otp",
  {
    gstin:gstin,
    username:username,
    otp:otp,
  },
  {}
);
try {
  if (response.errorCode == "0") {
 var authtoken=response.data;

  for(var k=year3 ; k<=year4;k++){
      if(k == year4){
for(var i=month3 ; i<=month4;i++){
const response = await postApi(
  "/get_datagstr1_gstportal",
  {
    gstin:gstin,
    username:username,
    month:i,
    year:k,
    authtoken:authtoken,
    userId:this.user_id,  
    company_id: this.companyId,
   },
  {}
);
try {
  if (response.errorCode == "0") {
   console.log("asd");
  }else{
      console.log("asd");
  } 
} catch (err) {
  alert(err);
}
}
}
else{
for(var j=month3 ; j<=12;j++){
const response = await postApi(
  "/get_datagstr1_gstportal",
  {
    gstin:gstin,
    username:username,
    month:j,
    year:k,
    authtoken:authtoken,
    userId:this.user_id,  
    company_id: this.companyId,
   },
  {}
);
try {
  if (response.errorCode == "0") {
   console.log("asd");
  }else{
      console.log("asd");
  } 
} catch (err) {
  alert(err);
}
}
}
}
this.alertMessage = "GSTR-1 Data Fetch Successfully";
 this.message = true;
 setTimeout(() => this.message = false, 3000);     
this.sendotp1 = false;
this.otp = false;
this.loader6=false;
  }else{
      this.alertMessager = response.errorMsg;
 this.messager = true;
 setTimeout(() => this.messager = false, 3000); 
      this.loader6=false;
  } 
} catch (err) {
  alert(err);
}
}
},


downloadBpr(){
var url="https://instabill.co/br/"+this.user_id+"/"+this.company_id+"/"+this.month+"/"+this.year;

window.open(url, '_blank');
},

gstr1pdfDownload(){
  
  var companyId = this.companyId;
  var userId=this.userId;
  var date=document.getElementById("start_date").value;
  var date2=document.getElementById("end_date").value;
  this.loader8 = true;
  axios(
      {
  url:"https://instabill.co/api/pro_gstr1/"+userId+"/"+companyId+"/"+date+"/"+date2+"",
  method:"GET",
  responseType:"blob",
      }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', "gstr1.pdf")
    link.click()
    this.loader8 = false;
  });
  },

gstr1excelDownload(){
  
  var companyId = this.companyId;
  var userId=this.userId;
  var date=document.getElementById("start_date").value;
  var date2=document.getElementById("end_date").value;
  this.loader9 = true;
  axios(
      {
  url:"https://instabill.co/api/pro_gstr1_excel/"+userId+"/"+companyId+"/"+date+"/"+date2+"",
  method:"GET",
  responseType:"blob",
      }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', "gstr1.csv")
    link.click()
    this.loader9 = false;
  });
  },

gstr3bpdfDownload(){
  
  var companyId = this.companyId;
  var userId=this.userId;
  var date=document.getElementById("start_date").value;
  var date2=document.getElementById("end_date").value;
  this.loader10 = true;
  axios(
      {
  url:"https://instabill.co/api/pro_gstr3b/"+userId+"/"+companyId+"/"+date+"/"+date2+"",
  method:"GET",
  responseType:"blob",
      }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', "gstr3b.pdf")
    link.click()
    this.loader10 = false;
  });
  },

gstr3bexcelDownload(){
  
  var companyId = this.companyId;
  var userId=this.userId;
  var date=document.getElementById("start_date").value;
  var date2=document.getElementById("end_date").value;
  this.loader11 = true;
  axios(
      {
  url:"https://instabill.co/api/pro_gstr3b_excel/"+userId+"/"+companyId+"/"+date+"/"+date2+"",
  method:"GET",
  responseType:"blob",
      }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', "gstr3b.csv")
    link.click()
    this.loader11 = false;
  });
  },


  cmppdfDownload(){
  
  var companyId = this.companyId;
  var userId=this.userId;
  var date=document.getElementById("start_date").value;
  var date2=document.getElementById("end_date").value;
  this.loader12 = true;
  axios(
      {
  url:"https://instabill.co/api/pro_gstr4/"+userId+"/"+companyId+"/"+date+"/"+date2+"",
  method:"GET",
  responseType:"blob",
      }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', "cmp-08.pdf")
    link.click()
    this.loader12 = false;
  });
  },

cmpexcelDownload(){
  
  var companyId = this.companyId;
  var userId=this.userId;
  var date=document.getElementById("start_date").value;
  var date2=document.getElementById("end_date").value;
  this.loader13 = true;
  axios(
      {
  url:"https://instabill.co/api/pro_gstr4_excel/"+userId+"/"+companyId+"/"+date+"/"+date2+"",
  method:"GET",
  responseType:"blob",
      }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', "cmp-08.csv")
    link.click()
    this.loader13 = false;
  });
  },


      }   
  }

  </script>
  
  <style scoped>
  input.btn.btn-success.submit.last_month {
  margin-left: 1%;
}
  .feature-box{position: relative;}
  button.btn.btn-primary.gradient-bg.noti {
      position: absolute;
      top: 5%;
      right: 2%;
      padding: 2px 7px;
      border-radius: 5px!important;
      border: 1px solid #fd9f14;
      font-size: 13px;
      letter-spacing: 0.5px;
  }
  
  .error-msg {
      border-top: 2px solid #8080802e;
      margin-top: 5%;
  }
  div#notimage_2 {
      display: none;
  }
  
  .uploader_purchase label:hover {
      border-color: orange;
  }
  
  .progress {
      height: 8px;
      width: 70%;
      border-radius: 0.5em;
      overflow: hidden;
      border: thin solid #ddd;
      margin: auto;
      margin-bottom: 8%;
  }
  .error-msg {
      padding-left: 8%;
  }
  .v3dp__datepicker {    margin-right: 0%;
      display: inline-block;
      width: 14%!important}
  .clear{clear:both;}
  .error-msg p{padding:0px!important;}
  .progress .value {
      width: 0%;
      height: 100%;
      background: linear-gradient(85deg, #f66a59, #ffae00);
      transition-property: width;
      transition-duration: 0.5s;
  }
  
  button#setting_closes {
      padding: 8px 50px;
      border-radius: 36px;
      border: 1px solid gray;
      background: none;
      text-transform: capitalize;
      cursor: pointer;
      margin-left: 5px;
      transition: 1s;
  }
  .select_da span {
  color: #070707;
  border-radius: 3px;
  cursor: pointer;
  display: inline-flex;
}
  button.verify-otp {
      background: linear-gradient(to top left, #ffae00 0%, #f66a59 100%) !important;
      border-color: #fea40d;
      color: #fff;
      padding: 8px 50px;
      border-radius: 36px;
      text-transform: capitalize;
      cursor: pointer;
      margin-left: 5px;
      transition: 1s;
      border: 1px solid transparent;
  }
  .box_d {
  margin-top: 20px;
  padding: 30px 30px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 20px 50px 0px rgb(153 153 153 / 10%);
  width: 48%;
  float: left;
  margin-right: 2%;
  height: 170px;
}

.box_d button {
  background: linear-gradient(85deg, #f66a59, #ffae00);
  color: #fff;
  letter-spacing: 1px;
  font-size: 14px;
  border: 0px;
  padding: 2% 5%;
  border-radius: 5px;
  transition: 1s;
  margin-top: 2%;
  box-shadow: 2px 3px 16px #ccc7c7;
}
  #loader_remove{display:none;}
  .btn-devide-content {
      border-top: 2px solid #8080802e;
      margin-top: 8%;
      padding-top: 10%;
  }
  
  .wrap {
      width: 98%;
      margin-left: 0;
  }
  span.span_one {
    padding: 2%;
    width: 66%;justify-content: space-between;
    border-radius: 5px!important;
    color: #000!important;
    border:1px solid #eee
}
span.span_two {
    padding: 2%;justify-content: space-between;
    width: 44%;
    border-radius: 5px!important;
    color: #000!important;border:1px solid #eee
}
span.span_three {
    padding: 2%;justify-content: space-between;
    width: 44%;
    border-radius: 5px!important;
    color: #000!important;border:1px solid #eee
}
span.span_one:hover {
    background: linear-gradient(45deg, rgb(248 122 67) 0%, rgb(249 130 57) 7%, rgb(248 123 67) 17%, rgb(249 126 62) 42%, rgb(253 157 23) 56%, rgb(253 158 22) 68%, rgb(253 163 13) 83%, rgb(252 155 25) 95%, rgb(254 163 14) 100%);
    color: #fff !important;
}
div#hidemessager {
    position: fixed;
    top: 0%;
    z-index: 9999999;
    width: 25%;
    padding: 1%;
    background: #fff;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    border-radius: 5px;
    right: 0;
    border-left: 4px solid red;
    transition: 1s;
    color: red;
}
div#hidemessage {
    position: fixed;
    top: 0%;
    z-index: 9999999;
    width: 25%;
    padding: 1%;
    background: #fff;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    border-radius: 5px;
    right: 0;
    border-left: 4px solid #00db92;
    transition: 1s;
    color: #00db92;
}
span.span_two:hover {
    background: linear-gradient(45deg, rgb(248 122 67) 0%, rgb(249 130 57) 7%, rgb(248 123 67) 17%, rgb(249 126 62) 42%, rgb(253 157 23) 56%, rgb(253 158 22) 68%, rgb(253 163 13) 83%, rgb(252 155 25) 95%, rgb(254 163 14) 100%);
    color: #fff !important;
}
span.span_three:hover {
    background: linear-gradient(45deg, rgb(248 122 67) 0%, rgb(249 130 57) 7%, rgb(248 123 67) 17%, rgb(249 126 62) 42%, rgb(253 157 23) 56%, rgb(253 158 22) 68%, rgb(253 163 13) 83%, rgb(252 155 25) 95%, rgb(254 163 14) 100%);
    color: #fff !important;
}
  .clear {
      clear: both;
  }
  .left-a {
      width: 76%;
      float: left;
  }
  span.badge1 {
      display: inline-block;
      border: 1px solid #ffae00;
      border-radius: 50px;
      color: #000;
      font-size: 0.8125rem;
      font-weight: 400 !important;
      line-height: 1;
      padding: 0.5rem 0.75rem;
  }
  button.btn.submit.last_month {
      background: linear-gradient(to top left, #ffae00 0%, #f66a59 100%) !important;
      border-color: #fea40d;
      border-radius: 5px!important;
      margin-right: 1%;
      color: #fff;
  }
      .msg {
      position: fixed;
      top: 0%;
      z-index: 999;
      width: 20%;
      padding: 1%;
      background: #fff;
      box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
      border-radius: 5px;
      right: 0;
      border-left: 4px solid #ffae00;transition: 1s;
  }
  p.txt {
      width: 91%;
      float: left;
      margin: 0;
  }
  .account_submit {
    margin-top: 5%;
}
  .error_msg {
      position: fixed;
      top: 0%;
      z-index: 999;
      width: 20%;
      padding: 1%;
      background: #fff;
      box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
      border-radius: 5px;
      right: 0;
      border-left: 4px solid #db0014;
      transition: 1s;
      color: red;
  }

  .gstr_json_sidebar {
  float: right;
  z-index: 9999;
  position: fixed;
  right: 0;
  top: 0px;
  background: white;
  height: 100%;
  transition: 1s;
  border: 1px solid #d4d7dc;
  border-right: none;
  border-top: none;
  box-shadow: 0px 3px 21px 0px rgb(0 0 0 / 20%);
  width: 350px;
  padding: 15px;
  overflow-y: scroll;
}
span.closed_open_m {
    width: 40px;
    height: 27px;
    text-align: center;
    line-height: 20px;
}
  </style>