<template>
<CommonHeader />

<div id="page-container">
    <div class="pay_tax">
        <div class="row row1" v-if="Slide_1">
            <h2>Pay Taxes</h2><br>
            <div class="col-md-3 challan_open" id="sunil">
                <div class="btn-box">
                    <a type="button" id="Next_ch280" class="slide1 for_height" v-on:click="ch281()">
                        <input type="radio" placeholder="Income Tax" name="challan" value="280">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95.91 110.2">
                            <title>Asset 31</title>
                            <g id="Layer_2" data-name="Layer 2">
                                <g id="Layer_1-2" data-name="Layer 1">
                                    <path id="a" d="M91.54,102l-1.81-2.31-8.59,9.54h0a2.32,2.32,0,0,1-3.5,0l-8.75-9.46L60.32,109a2.32,2.32,0,0,1-3.48.11l-9.09-9.34-8.26,9.45-.15.18a2.31,2.31,0,0,1-3.53,0l-8.65-9.63-8.93,9.46h0a2.32,2.32,0,0,1-3.48,0L6.26,99.62,4.5,101.89a2.44,2.44,0,0,1-2,1.12A2.64,2.64,0,0,1,0,100.24V2.77A2.63,2.63,0,0,1,2.48,0H93.41a2.64,2.64,0,0,1,2.5,2.77v97.45h0a2.86,2.86,0,0,1-.87,2.09,2.28,2.28,0,0,1-3.49-.27ZM14.78,16H35.07v6.11H28.31V40H21.55V22.11H14.79V16ZM49.24,36.23H42.05L41,40H34L42.1,16h7.29l8.07,24h-7.2l-1-3.76ZM48,30.58,45.65,22l-2.36,8.6ZM58,16h7.35l3.81,7.38L72.9,16h7.25L73.46,27.62,80.79,40h-7.5l-4.22-7.7L64.81,40H57.38l7.43-12.5L58,16ZM71.12,76.23a2.14,2.14,0,0,1,0,4.23H19a2.14,2.14,0,0,1,0-4.23ZM81.54,62.58a2.14,2.14,0,0,1,0,4.24H18.46a2.14,2.14,0,0,1,0-4.24Zm.09-13.65a2.1,2.1,0,0,1,1.76,2.79,1.92,1.92,0,0,1-1.83,1.45H18.46a2.14,2.14,0,0,1,0-4.24ZM90.82,5.65H5.07V93.33a2.29,2.29,0,0,1,2.78.36.69.69,0,0,1,.14.15H8l8.64,9.73,8.89-9.45a2.32,2.32,0,0,1,3.48,0h0l8.56,9.55,8.3-9.53a2.31,2.31,0,0,1,3.47-.1h0l9.13,9.41,8.67-9.26a2.31,2.31,0,0,1,3.47,0l8.68,9.4L88,93.92a2.3,2.3,0,0,1,2.82-.55V5.65Z"></path>
                                </g>
                            </g>
                        </svg>

                        <p>Income Tax</p>
                    </a>
                </div>
            </div>

            

            <div class="col-md-3 challan_open" id="sunil1">
                <div class="btn-box">
                    <a type="button" id="Next_ch281" class="slide1 for_height " v-on:click="tds281()">
                        <input type="radio" placeholder="Income Tax" name="challan" value="281">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95.91 110.2">
                            <title>Asset 31</title>
                            <g id="Layer_2" data-name="Layer 2">
                                <g id="Layer_1-2" data-name="Layer 1">
                                    <path id="a" d="M91.54,102l-1.81-2.31-8.59,9.54h0a2.32,2.32,0,0,1-3.5,0l-8.75-9.46L60.32,109a2.32,2.32,0,0,1-3.48.11l-9.09-9.34-8.26,9.45-.15.18a2.31,2.31,0,0,1-3.53,0l-8.65-9.63-8.93,9.46h0a2.32,2.32,0,0,1-3.48,0L6.26,99.62,4.5,101.89a2.44,2.44,0,0,1-2,1.12A2.64,2.64,0,0,1,0,100.24V2.77A2.63,2.63,0,0,1,2.48,0H93.41a2.64,2.64,0,0,1,2.5,2.77v97.45h0a2.86,2.86,0,0,1-.87,2.09,2.28,2.28,0,0,1-3.49-.27ZM14.78,16H35.07v6.11H28.31V40H21.55V22.11H14.79V16ZM49.24,36.23H42.05L41,40H34L42.1,16h7.29l8.07,24h-7.2l-1-3.76ZM48,30.58,45.65,22l-2.36,8.6ZM58,16h7.35l3.81,7.38L72.9,16h7.25L73.46,27.62,80.79,40h-7.5l-4.22-7.7L64.81,40H57.38l7.43-12.5L58,16ZM71.12,76.23a2.14,2.14,0,0,1,0,4.23H19a2.14,2.14,0,0,1,0-4.23ZM81.54,62.58a2.14,2.14,0,0,1,0,4.24H18.46a2.14,2.14,0,0,1,0-4.24Zm.09-13.65a2.1,2.1,0,0,1,1.76,2.79,1.92,1.92,0,0,1-1.83,1.45H18.46a2.14,2.14,0,0,1,0-4.24ZM90.82,5.65H5.07V93.33a2.29,2.29,0,0,1,2.78.36.69.69,0,0,1,.14.15H8l8.64,9.73,8.89-9.45a2.32,2.32,0,0,1,3.48,0h0l8.56,9.55,8.3-9.53a2.31,2.31,0,0,1,3.47-.1h0l9.13,9.41,8.67-9.26a2.31,2.31,0,0,1,3.47,0l8.68,9.4L88,93.92a2.3,2.3,0,0,1,2.82-.55V5.65Z"></path>
                                </g>
                            </g>
                        </svg>

                        <p>TDS</p>
                    </a>
                </div>
            </div>

            <div class="col-md-3 challan_open" id="sunil1">
                <div class="btn-box">
                    <a type="button" id="Next_ch281" class="slide1 for_height" v-on:click="gstopen()">
                        <input type="radio" placeholder="Income Tax" name="challan" value="281">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95.91 110.2">
                            <title>Asset 31</title>
                            <g id="Layer_2" data-name="Layer 2">
                                <g id="Layer_1-2" data-name="Layer 1">
                                    <path id="a" d="M91.54,102l-1.81-2.31-8.59,9.54h0a2.32,2.32,0,0,1-3.5,0l-8.75-9.46L60.32,109a2.32,2.32,0,0,1-3.48.11l-9.09-9.34-8.26,9.45-.15.18a2.31,2.31,0,0,1-3.53,0l-8.65-9.63-8.93,9.46h0a2.32,2.32,0,0,1-3.48,0L6.26,99.62,4.5,101.89a2.44,2.44,0,0,1-2,1.12A2.64,2.64,0,0,1,0,100.24V2.77A2.63,2.63,0,0,1,2.48,0H93.41a2.64,2.64,0,0,1,2.5,2.77v97.45h0a2.86,2.86,0,0,1-.87,2.09,2.28,2.28,0,0,1-3.49-.27ZM14.78,16H35.07v6.11H28.31V40H21.55V22.11H14.79V16ZM49.24,36.23H42.05L41,40H34L42.1,16h7.29l8.07,24h-7.2l-1-3.76ZM48,30.58,45.65,22l-2.36,8.6ZM58,16h7.35l3.81,7.38L72.9,16h7.25L73.46,27.62,80.79,40h-7.5l-4.22-7.7L64.81,40H57.38l7.43-12.5L58,16ZM71.12,76.23a2.14,2.14,0,0,1,0,4.23H19a2.14,2.14,0,0,1,0-4.23ZM81.54,62.58a2.14,2.14,0,0,1,0,4.24H18.46a2.14,2.14,0,0,1,0-4.24Zm.09-13.65a2.1,2.1,0,0,1,1.76,2.79,1.92,1.92,0,0,1-1.83,1.45H18.46a2.14,2.14,0,0,1,0-4.24ZM90.82,5.65H5.07V93.33a2.29,2.29,0,0,1,2.78.36.69.69,0,0,1,.14.15H8l8.64,9.73,8.89-9.45a2.32,2.32,0,0,1,3.48,0h0l8.56,9.55,8.3-9.53a2.31,2.31,0,0,1,3.47-.1h0l9.13,9.41,8.67-9.26a2.31,2.31,0,0,1,3.47,0l8.68,9.4L88,93.92a2.3,2.3,0,0,1,2.82-.55V5.65Z"></path>
                                </g>
                            </g>
                        </svg>

                        <p>GST</p>
                    </a>
                </div>
            </div>
            <div class="center">
                <div style="text-align: center;"><img src="https://instabill.co/public/ca_images/Powered-by-icici.png" class="img2" style="    width: 230px;" /></div>
            </div>
        </div>

        <!--***********income tax create 19-1-2022*********-->

        <section id="ch281" style="" v-if="isModalVisible">

            <div class="row">

                <div class="col-md-12">
                    <div class="field">

                        <select name="top" id="top" class="add_width">
                            <option value="">----</option>
                            <option value="Income Tax">Income Tax</option>
                            <option value="Corporation Tax">Corporation Tax</option>
                        </select>
                        <div id="for_label">
                            <label for="top">Type of Payment<span style="color:#ff0000;">*</span></label>
                        </div>

                    </div>
                </div>

                <div class="col-md-12">
                    <div class="field">
                        <select name="mhc" id="mhc">
                            <option value="">----</option>
                            <option value="0020">0020- Income Tax on companies(Corporation tax)</option>
                            <option value="0021">0021 - Income Tax Other than companies</option>
                        </select>
                        <div id="for_label">
                            <label for="mhc">Major head Codes<span style="color:#ff0000;">*</span></label>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="field">
                        <select name="minor_hc" id="minor_hc">
                            <option value="">----</option>
                            <option value="100">100- Advance Tax</option>
                            <option value="102">102 - Surtax</option>
                            <option value="106">106 - Tax on distributed profits</option>
                            <option value="107">107 - Tax on distributed income</option>
                            <option value="300">300 - Self assessment tax</option>
                            <option value="400">400 - Tax on Regular Assessment</option>
                            <option value="110">110 - Secondary Adjustment tax</option>
                            <option value="111">111 - Accretion Tax</option>
                        </select>
                        <div id="for_label">
                            <label for="minor_hc">Minor Head Codes<span style="color:#ff0000;">*</span></label>
                        </div>
                    </div>
                </div>

                <div class="col-md-12" id="nop" v-if="nop">
                    <div class="field">
                        <select name="nature_py" id="nature_py"></select>
                        <div id="for_label">
                            <label for="nature_py">Nature of Payments<span style="color:#ff0000;">*</span></label>
                        </div>
                    </div>
                </div>

                <div class="btn-box" style="margin-top: 15px;">
                    <button type="button" id="challan_back_281" v-on:click="challan_back_281()">Back</button>
                    <button type="button" id="challan_next_281" v-on:click="challan_next_281()" class="bg_color">Next</button>
                </div>

            </div>

        </section>
     
        <!--***********income tax create end*********-->
        
        <section id="Form3" v-if="Slide_2">

            <div class="row">
                <div class="col-md-6" id="pan_no" v-if="pan">
                    <label for="account_no">PAN<span style="color:#ff0000;">*</span></label>
                    <input type="text" name="pan" id="pan" maxlength="10" placeholder="PAN*" value="">
                </div>
                <div class="col-md-6" id="tan_no" v-if="tan">

                    <label for="account_no">TAN<span style="color:#ff0000;">*</span></label>
                    <input type="text" name="tan" id="tan" maxlength="10" placeholder="TAN*" value="">

                </div>
                <div class="col-md-6" id="assess_y">

                    <label for="name">Assessment Year<span style="color:#ff0000;">*</span></label>

                    <select id="assessment_year" placeholder="Assessment Year" name="assessment_year">
                        <option value="">----</option>
                        <option value="2016-17">2016-17</option>
                        <option value="2017-18">2017-18</option>
                        <option value="2018-19">2018-19</option>
                        <option value="2019-20">2019-20</option>
                        <option value="2020-21">2020-21</option>
                        <option value="2021-22">2021-22</option>
                    </select>

                </div>

                <div class="col-md-12">
                    <label for="account_no">Address<span style="color:#ff0000;">*</span></label><br>
                    <textarea rows="2" name="address" id="address" placeholder="Address">HOUSE NO-19ANEW MANDINEAR RAILWAY STATIONUttar Pradesh</textarea>

                </div>
                <div class="col-md-6">
                    <label for="account_no">Pincode<span style="color:#ff0000;">*</span></label><br>
                    <input type="number" name="pincode" id="pincode" maxlength="6" placeholder="Pincode" value="251001">

                </div>

                <div class="col-md-6">

                    <label for="name">State<span style="color:#ff0000;">*</span></label>
                    <select placeholder="state" name="state" id="state">
                        <option value="">----</option>
                        <option value="ANDM &amp; NICOBAR">ANDM &amp; NICOBAR</option>
                        <option value="ANDHRA PRADESH">ANDHRA PRADESH</option>
                        <option value="ARUNACHAL PRAD">ARUNACHAL PRAD</option>
                        <option value="ASSAM">ASSAM</option>
                        <option value="BIHAR">BIHAR</option>
                        <option value="CHANDIGARH">CHANDIGARH</option>
                        <option value="DADRA NGR HAV &amp; DAMAN DIU">DADRA NGR HAV &amp; DAMAN DIU</option>
                        <option value="DELHI">DELHI</option>
                        <option value="GOA">GOA</option>
                        <option value="GUJARAT">GUJARAT</option>
                        <option value="HARYANA">HARYANA</option>
                        <option value="HIMACHAL PRADESH">HIMACHAL PRADESH</option>
                        <option value="JAMMU &amp; KASHMIR">JAMMU &amp; KASHMIR</option>
                        <option value="KARNATAKA">KARNATAKA</option>
                        <option value="KERALA">KERALA</option>
                        <option value="LAKSHWADEEP">LAKSHWADEEP</option>
                        <option value="MADHYA PRADESH">MADHYA PRADESH</option>
                        <option value="MAHARASHTRA">MAHARASHTRA</option>
                        <option value="MANIPUR">MANIPUR</option>
                        <option value="MEGHALYA">MEGHALYA</option>
                        <option value="MIZORAM">MIZORAM</option>
                        <option value="NAGALAND">NAGALAND</option>
                        <option value="ORISSA">ORISSA</option>
                        <option value="PONDICHERRY">PONDICHERRY</option>
                        <option value="PUNJAB">PUNJAB</option>
                        <option value="RAJASTHAN">RAJASTHAN</option>
                        <option value="SIKKIM">SIKKIM</option>
                        <option value="TAMILNADU">TAMILNADU</option>
                        <option value="TRIPURA">TRIPURA</option>
                        <option value="UTTAR PRADESH">UTTAR PRADESH</option>
                        <option value="WEST BENGAL">WEST BENGAL</option>
                        <option value="CHATTISHGARH">CHATTISHGARH</option>
                        <option value="UTTARAKHAND">UTTARAKHAND</option>
                        <option value="JHARKHAND">JHARKHAND</option>
                        <option value="TELANGANA">TELANGANA</option>
                        <option value="OUTSIDE INDIA">OUTSIDE INDIA</option>
                        <option value="LADAKH">LADAKH</option>
                    </select>

                </div>

            </div>

            <div class="btn-box" style="margin-top: 15px;">
                <button type="button" id="Back2a" v-on:click="Back2a()">Back</button>
                <button type="button" id="Next4a" v-on:click="Next4a()" class="bg_color">Next</button>
            </div>
        </section>


        <section id="Form4" style="left: 40px;" v-if="Slide_3">
            <div class="row">

                <div class="col-md-6">
                    <label for="income_text_amt">Income Tax Amount<span style="color:#ff0000;">*</span></label><br>
                    <input type="number" name="income_tax_amt" onchange="amt_cal()" id="income_tax_amt" placeholder="0" value="0">
                </div>
                <div class="col-md-6">
                    <label for="surcharge_amt">Surcharge Amount<span style="color:#ff0000;">*</span></label><br>
                    <input type="number" name="surcharge_amt" onchange="amt_cal()" id="surcharge_amt" placeholder="0" value="0">
                </div>
                <div class="col-md-6">
                    <label for="education_cess_amt">Education cess Amount<span style="color:#ff0000;">*</span></label><br>
                    <input type="number" name="education_cess_amt" onchange="amt_cal()" id="education_cess_amt" placeholder="0" value="0">
                </div>

                <div class="col-md-6">
                    <label for="equalisation_levy_amt">Equalisation Levy Amount<span style="color:#ff0000;">*</span></label><br>
                    <input type="number" name="equalisation_levy_amt" onchange="amt_cal()" id="equalisation_levy_amt" placeholder="0" value="0">
                </div>

                <div class="col-md-6">
                    <label for="interest_amount">Interest Amount<span style="color:#ff0000;">*</span></label><br>
                    <input type="number" name="interest_amt" onchange="amt_cal()" id="interest_amt" placeholder="0" value="0">
                </div>

                <div class="col-md-6">
                    <label for="other_fee_amt">Other Fee Amount<span style="color:#ff0000;">*</span></label><br>
                    <input type="number" name="other_fee_amt" onchange="amt_cal()" id="other_fee_amt" placeholder="0" value="0">
                </div>

                <div class="col-md-6">
                    <label for="other_fee_amt">Penalty Amount<span style="color:#ff0000;">*</span></label><br>
                    <input type="number" name="penalty_amt" onchange="amt_cal()" id="penalty_amt" placeholder="0" value="0">
                </div>

                <div class="col-md-6">
                    <label for="other_amt">Other Amount<span style="color:#ff0000;">*</span></label><br>
                    <input type="number" onchange="amt_cal()" name="other_amt" id="other_amt" placeholder="0" value="0">
                </div>

                <div class="col-md-6">
                    <label for="total_transaction_amount">Total Transaction Amount<span style="color:#ff0000;">*</span></label><br>
                    <input type="number" name="total_transaction_amount" value="0" id="total_transaction_amount" placeholder="0" readonly="">
                </div>

            </div>

            <div class="btn-box" style="
    margin-top: 23px;">
                <button type="button" id="Back3" v-on:click="Back3()">Back</button>
                <button id="Next5" class="bg_color" v-on:click="Next5()">Next</button>
            </div>
        </section>
        <!--form5 start here create by pooja-->
        <section id="form5" v-if="Slide_4">

            <div class="row">

                <div class="col-md-12">
                    <label for="Name">Name<span style="color:#ff0000;">*</span></label><br>
                    <input type="text" name="name" value="" id="customer_Name" placeholder="Name">
                </div>
                <div class="col-md-12">
                    <label for="customer_number">Mobile<span style="color:#ff0000;">*</span></label><br>
                    <input type="number" name="name" value="" id="customer_Number" placeholder="Mobile">
                </div>

                <div class="col-md-12">
                    <label for="UPI_ID">UPI ID<span style="color:#ff0000;">*</span></label><br>
                    <input type="text" name="upi_id" value="" id="UPI_ID" placeholder="UPI ID">
                </div>

                <div class="btn-box" style="margin-top: 15px;">
                    <button type="button" id="Pay_ment" class="startClock bg_color" v-on:click="Pay_ment()">Pay <span id="net_amount">0</span></button>
                </div>

            </div>
        </section>
        <!--form5 end here create by pooja-->
        <section id="upi_Id" v-if="Slide_5">
            <div class="row">

                <div class="col-md-12">
                    <p style="color:#000;">Payment request send to <span><b style="font-size: 15px;font-weight: 700;">9999175701@paytm</b> <i class="fa fa-check" aria-hidden="true"></i></span></p>
                </div>
                <div class="col-md-12">
                    <p style="margin-bottom:7px;color:#000;text-align:center">Complete the payment on the UPI app in </p>
                    <!--<button onclick="myFunction()">timer</button>-->
                 
                    <div id="countdown"></div>

                </div>

                <div class="col-md-12">
                    <div class="upi_img">
                        <div class="Upi">
                            <div class="upi_box">
                                <div class="upi_left after_brupi"><img src="@/assets/img/upi_img.png" /></div>
                                <p>Open the UPI app on your phone</p>
                            </div>
                            <div class="clearfix"></div>
                            <div class="upi_box">
                                <div class="upi_left"><img src="@/assets/img/p_successful.png" /></div>
                                <p>Open the UPI app on your phone</p>
                            </div>
                        </div>
                    </div>
                    <p style="font-size: 12px;color:red;">Note: Please do not refresh this page, as your payment is<br> in progress state</p>
                </div>

                <div class="btn-box" style="margin-top: 15px;">
                    <!--<button type="button" id="go_Back">Go Back</button>-->
                </div>

            </div>
        </section>

        <section id="payment_failed" v-if="Slide_6">
            <div class="row">
                
                    <div class="col-md-12">
                    <img src="@/assets/img/payment_failed.png" />
                         <p>Transaction failed due to timeout.</p>
                    </div> 
                      
                    <div class="btn-box" style="margin-top: 15px;">
                        <button type="button" id="try_Again" class="startClock bg_color">Try Again</button>
                    </div>
                    
                    
            </div>
        </section>
        <!-- ************gst sidebar -->
        <section id="gst_f" v-if="Slide_7">
                 <div class="row">
                     <h3 style="font-size: 25px;    font-weight: 700;color: #f97d40;padding-left: 41%;margin-bottom: 10px;">GST Challan List</h3>
                    <table style="width: 100%;">
                        <thead style="border-bottom: 2px solid #fff;">
                            <tr class="tr-bg">
                                <th>Challan Number</th>
                                <th>Expiry Date</th>
                                <th>CGST</th>
                                <th>SGST</th>
                                <th>IGST</th>
                                <th>CESS</th>
                                <th>Total</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody id="data"><tr><td align="center" colspan="8">No challan pending for payment. Create challan from Gst portal and try payment again.</td></tr></tbody>
                        
                        
                    </table>
              
                  </div>
            
            <div class="btn-box" style="margin-top: 15px;">
                <button type="button" id="Back_gst1" v-on:click="Back_gst1()">Back</button>
            </div>
         </section>
        

    </div>

</div>
</template>

<script>
import CommonHeader from './CommonHeader.vue';
import $ from 'jquery';
export default {
    name: 'TaxPayment',
    components: {
        CommonHeader
    },
  
    data() {
        return {
                isModalVisible: false,
                Slide_1: true,
                Slide_2: false,
                Slide_3: false,
                Slide_4: false,
                Slide_5: false,
                Slide_6: false,
                Slide_7: false,
                nop:false,
                tan:false,
                pan:false,
            

        }
    },
    
    methods: {

        ch281() {

            this.Slide_1 = false;
            
            this.isModalVisible = true;
        
        },
        challan_back_281() {
            this.Slide_1 = true;
            this.isModalVisible = false;
        },
        challan_next_281() {
            this.isModalVisible = false;
            this.Slide_2 = true;
            this.pan = true;
        },
        Back2a() {
            this.Slide_2 = false;
            this.isModalVisible = true;
        },
        Next4a() {
            this.Slide_2 = false;
            this.Slide_3 = true;
        },
        Back3() {
            this.Slide_2 = true;
            this.Slide_3 = false;
        },
        Next5() {
            this.Slide_4 = true;
            this.Slide_3 = false;
        },
        Pay_ment(){
            this.Slide_5 = true;
            this.Slide_4 = false;
        },

       // tds slider start here
       tds281(){
        this.Slide_1 = false;
        this.isModalVisible = true;
        this.nop = true;
        this.pan = false;
       },
       gstopen(){
        this.Slide_1 = false;
        this.Slide_7 = true;
       },
       Back_gst1(){
        this.Slide_1 = true;
        this.Slide_7 = false;
       },

    },
}

//slide script
$(function () {
    $('#Next_ch280').click(function () { // when a .myDiv is clicked
        $('.row1').css("left", "-970px");
        $('#ch281').css("left", "43%");

    })

});
$(function () {
    $('#challan_back_281').click(function () { // when a .myDiv is clicked
        $('.row1').css("left", "24%");
        $('#ch281').css("left", "200%");

    })

});
</script>

<style scoped>

.active1 {
    background: #ffffff !important;
    color: #e97625;
}

.active1 svg {
    fill: #e97625;
}

section {
    width: 410px;
    position: absolute;
    top: 100px;
    left: 43%;
    transition: 0.5s;
    transition-timing-function: ease;
    background: #fff;
    padding: 2% 3%;
    border-radius: 5px;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
}
</style>
