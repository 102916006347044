<template>
<div class="sign_page">
    <!--*************alert-->
    <div :class="alertClass" id="hidemessage" v-if="message">
        <p class="txt">{{alertMessage}}</p>
        <i class="fa fa-times close_msg" aria-hidden="true" @click="close_msg()"></i>
    </div>
    <!--*************alert-->
    <div class="sign_left">
        <img src="https://instabill.co/public/ca_images/sign.png" style="width: 65%;" />
        <p style="font-size: 19px;">
            Login to manage client GST filing, connect Tally, Instabill Referral
            Earning and many more...
        </p>
    </div>
    <div class="sign_right">
        <div class="inner_r">
            <img src="https://instabill.co/public/instabillpro_logo.png" />
            <h2>Recover Password</h2>
            <form v-on:submit.prevent="forgetPassword">
                <div class="box_in">
                    <label>Email</label><br />
                    <input type="text" placeholder="Enter Email ID" v-model="email" />
                </div>

                <div class="box_in">
                    <button type="submit">
                        Send Me Email
                        <img src="https://instabill.co/public/front/images/loader.gif" class="load_show" v-if="loader" style="width: 15px;" />
                    </button>
                    <p style="margin-top:10px">
                        Have you an account?
                        <router-link to="/">Sign In </router-link>
                    </p>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

  
<script>
import {
    checkEmail,
    postApi
} from "../assets/js/helper.js";
export default {
    name: "forgetPassword",
    data() {
        return {
            email: "",
            loader: false,
            message: false,
            alertMessage: "",
            alertClass: "",

        };
    },
    methods: {
        async forgetPassword() {

            if (this.email == "") {
                this.alertMessage = "Please Enter Email ID";
                this.alertClass = "error_msg";
                this.message = true;
                setTimeout(() => this.message = false, 1000);

                return false;
            }

            //check email is valid
            if (!checkEmail(this.email)) {
                this.alertMessage = "Please enter valid email";
                this.alertClass = "error_msg";
                this.message = true;
                setTimeout(() => this.message = false, 1000);

                return false;
            }
            this.loader = true;
            const response = await postApi(
                "/forgetPassword", {
                    email: this.email,
                }, {}
            );
            try {
                if (response.errorCode == "0") {
                    this.alertMessage = "Password sent to your email.";
                    this.alertClass = "success";
                    this.message = true;
                    setTimeout(() => this.message = false, 1000);

                    this.loader = false;
                    return false;
                }
                if (response.errorCode == "1") {

                    this.alertMessage = response.errorMsg;
                    this.alertClass = "error_msg";
                    this.message = true;
                    setTimeout(() => this.message = false, 1000);
                    this.loader = false;
                    return false;
                }

            } catch (err) {
                alert(err);
            }
        },
         close_msg() {
             this.message = false;
        },
    },
};
</script>
  
<style scoped>
.inner_r {
    padding-top: 30%;
}

.success {
    position: fixed;
    top: 0%;
    z-index: 999;
    width: 20%;
    padding: 1%;
    background: #fff;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    border-radius: 5px;
    right: 0;
    border-left: 4px solid #ffae00;
    transition: 1s;
}

p.txt {
    width: 91%;
    float: left;
    margin: 0;
}

.error_msg {
    position: fixed;
    top: 0%;
    z-index: 999;
    width: 20%;
    padding: 1%;
    background: #fff;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    border-radius: 5px;
    right: 0;
    border-left: 4px solid #db0014;
    transition: 1s;
    color: red;
}
</style>
