<template>
<CommonHeader />
 <div id="main-content">
    <div id="page-container">
        <!--**************************common**************-->
            <h1 class="hed" style="margin-left: 0%;">GSTR-2B</h1>
            <div class="clear"></div>
       

<div class="filter">
<ul>
<li style="width: 25%;">
    <datepicker v-model="date" inputFormat="dd-MM-yyyy" id="start_date" class="cal1"></datepicker>
    <datepicker v-model="date2" inputFormat="dd-MM-yyyy" id="end_date" class="cal1"></datepicker>
                
</li>
<li>
<input type="submit" value="Submit" onclick="update_account_statement()" class="btn btn-success orange_Color">
</li>

<li>
    <div class="btn-group btn-group_map">
        <button type="button" class="btn  btn-rounded active " id="first" @click="first1" style="">Unmap</button>
       <button type="button" class="btn btn-rounded " id="second" @click="second" style="background-color:#ffffff;color:#ffae00; border:1px solid #f66a59;">Map</button>
</div>
</li>
<li>
<div class="xls_pdf">
    <form>
    <img src="https://instabill.co/public/images/pdf.png">
    </form>
     <form>
    <img src="https://instabill.co/public/images/xls.png">
    </form>
    </div>
</li>
<li style="float: right;
    margin-right: 10px;">
<div class="search searcha" style="">
 <input type="text" placeholder="Search.." id="search" name="search" style="">
<button class="new_search_button" onclick="search()">
<i class="fa fa-search " style="background:linear-gradient(85deg, #f66a59, #ffae00); padding:10px;color:white;border-radius:10px;"></i></button>
</div>
</li>
<li>
<button class="orange_Color" v-on:click="showModal4">Fetch GSTR-2B Data</button>
</li>
</ul>
</div>

            <table class="list-box">
                <tr class="list">
                    <th><input type="checkbox"></th>
                    <th>Date</th>
                    <th>Invoice number</th>
                    <th>Party name</th>
                    <th style="text-align:right">Invoice Total</th>
                </tr>
                <tbody id="load_data">
                  <tr>
                    <td><input type="checkbox"></td>
                    <td>01/3/2024</td>
                    <td>4801444886</td>
                    <td>GOOGLE INDIA PVT LTD</td>
                    <td style="text-align:right">10723226.54 00 <i class="fa fa-chevron-down accordion-toggle collapsed" aria-hidden="true" data-toggle="collapse" data-target="#data0" aria-expanded="false"></i></td>
                </tr>
                <tr>
                    <td colspan="5">
                        <div id="data0" class="box-group accordian-body dev-info collapse" style="">
                            <div class="left_val">
                                <p><b>Party Name</b> GOOGLE INDIA PVT LTD</p>
                                <p><span>GSTIN</span> 06AACCG0527D1Z8</p>
                            </div>
                            <div class="right_val">
                                <p><b>Taxable Value</b> 612903.85  </p>
                                <p><span>CGST</span> 0</p>
                                <p><span>SGST</span> 0</p>
                                <p><span>IGST</span> 110322.69</p>
                                <p><span>Cess</span> 0</p>
                                <p><b>Total</b> 723226.54</p>
                            </div>
                        </div>
                    </td>
                </tr>
             </tbody>
            </table>
    </div>
 </div>

 <!--fetch gst2rb sidebar-->
   <div class="gstr_json_sidebar" v-if="isModalVisible4">
                <p class="head_sb">FETCH GSTR-2B DATA <span aria-hidden="" class="closed_open_m" v-on:click="closeModal4()"><i class="fa fa-times" aria-hidden="true"></i></span></p>
              <label>From</label> 
                <div class="select_da">
                    <select name="month1" id="month1" v-model="month1">
                       <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                  </select>
                    <select name="year1" id="year1" v-model="year1">
                        <option value="2021">2021</option>
                        <option value="2022">2022</option>
                        <option value="2023">2023</option>
                        <option value="2024">2024</option>
                        <option value="2025">2025</option>
                    </select>
                </div>
                <div class="clearfix"></div>
                <br>
                <label>To</label> 
                <div class="select_da">
                     
                    <select name="month2" id="month2" v-model="month2">
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                  </select>
                    <select name="year2" id="year2" v-model="year2">
                        <option value="2021">2021</option>
                        <option value="2022">2022</option>
                        <option value="2023">2023</option>
                        <option value="2024">2024</option>
                        <option value="2025">2025</option>
                    </select>
                </div>
                <div class="clearfix"></div>
                <br>
               <div class="select_da">
<label>GSTIN</label>
<br>
<input style="border: 1px solid gray;
    padding: 7px;
    border-radius: 5px;
    width: 79%;
    " type="text" readonly maxlength="15" v-model="gstin"  placeholder="15 digits GST Number" required="">
               </div>
               <div class="clearfix"></div>
                <br>
                <div class="select_da">
<label>GST Username</label>
<br>
<input style="border: 1px solid gray; padding: 7px;border-radius: 5px; width: 79%;" type="text"  placeholder="GSTN login username" v-model="username" required="">
</div>
 <div class="tally_status"><button type="submit" class="btn btn-success account_submit" v-on:click="gstr2b_otpsend">Submit</button></div>

<!-- otp section -->

 <div class="resend-otp" v-if="sendotp">
        <div class="add-gst">
            <br>
            <p style="color: #000;font-size: 15px;">Enter the One Time Password (OTP) Received on your mobile<br>number registered with the GST portal.</p>
        </div>
        
        
<div class="gstIn" style="margin-bottom:30px;">
    <form method="POST" action="gstr2b_verify_otp" accept-charset="UTF-8">
            <div class="entr-otp-wrp">
              <input style="border: 1px solid gray; padding: 7px;width: 79%;border-radius: 5px;" type="number" v-model="otp" placeholder="Enter OTP" maxlength="6" required="">
             </div>
    </form>
</div>

        <button type="submit" class="verify-otp"  v-on:click="verify_otp_gstr2a">verify OTP 
        <img src="https://instabill.co/public/front/images/loader.gif"
                    class="load_show"
                    v-if="loader6"
                    style="width: 15px;"
                  /></button>
    </div>


            </div>


            
</template>

<script>
    import CommonHeader from './CommonHeader.vue';
    import datepicker from 'vue3-datepicker';
    import $ from 'jquery';
     export default {
         name: 'ViewBankstatement',
        components: {
             datepicker,
            CommonHeader
        },
        data() {
            return {
                date: new Date(new Date().getFullYear(),new Date().getMonth(),1),
                date2: new Date(new Date().getFullYear(),new Date().getMonth()+1,0),
                isModalVisible4: false
        
         
            }
        },
        methods: {
        showModal4() {
                this.isModalVisible4 = true;
            },
             closeModal4() {
                this.isModalVisible4 = false;
            },
            
            
        }
          
     }

     $(function () {
        $('#first').click(function () {
        $('#first').css({ "background": "linear-gradient(85deg, rgb(246, 106, 89), rgb(255, 174, 0))", "color": "#fff", "border": "1px solid rgb(246, 106, 89)" });
        $('#second').css({ "background": "#fff", "color": "#ffae00", "border": "1px solid #f66a59" });
        
        })
});
     $(function () {
        $('#second').click(function () {
        $('#second').css({ "background": "linear-gradient(85deg, rgb(246, 106, 89), rgb(255, 174, 0))", "color": "#fff", "border": "1px solid rgb(246, 106, 89)" });
        $('#first').css({ "background": "#fff", "color": "#ffae00", "border": "1px solid #f66a59" });
        
        })
});


</script>
<style>
.left_val {
    width: 57%;
    float: left;
    padding: 1%;
    box-sizing: border-box;
}
.show {
    height: auto!important;
}

.collapse {
    overflow: hidden;
    height: 0px;
}
.right_val {
    width: 43%;
    float: left;
    padding: 1%;
    box-sizing: border-box;
}

.right_val p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 4px;
    margin: 0px;
}

.left_val p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 4px;
    margin: 0px;
}

.left_val p b {
    font-size: 14px;
    font-weight: 600;
}

.right_val p b {
    font-size: 14px;
}
tbody#load_data i {
    background: linear-gradient(to top left, #ffae00 0%, #f66a59 100%) !important;
    color: #fff;
    font-size: 10px;
    width: 16px;
    height: 16px;
    border-radius: 50px;
    line-height: 4px;
    padding: 6px 0px;
    text-align: center;
    margin: auto;
}
li .v3dp__datepicker input {
    height: 38px!important;
    border-radius: 5px!important;
    text-align: center!important;
}
table.list-box {
    width: 99%;
     margin-left: 0%;}

.selected {border-radius:5px;
    background: #fda110!important;}
.banktab {
    float: left;
    width: 53%;
    margin-right: 2%;
}

.filter ul li {
    list-style: none;
    float: left;
    margin-right: 1%;
}

.filter ul {
    padding: 0px;
}

.orange_Color {
    color: #fff;
    background: linear-gradient(to top left, #ffae00 0%, #f66a59 100%) !important;
    border-color: #fea40d;
    border-radius: 5px!important;
    border: 0px!important;
    height: 38px;
    padding: 1% 17px;
}
.xls_pdf {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4%;
}
.xls_pdf form{
cursor:pointer
}
.searcha {
    display: inline-block !important;
    height: calc(2.25rem + 2px) !important;
    line-height: 1.5 !important;
    color: #495057 !important;
    vertical-align: middle !important;
    background: #fff !important;
    background-size: 8px 10px !important;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem !important;
    transition: all ease-out 2s !important;
    margin: 0px;
    margin-right: 15px;
    position: relative;width: 178px!important;
}
.search {
    width: 100%!important;
}
.searcha button {
    position: absolute;
    right: 0;
    top: 0px;
    border-radius: 0px!important;
    border: 0px;
    height: 100%;
    padding: 0px;
}
.searcha button i {
    border-radius: 0px!important;
}
.searcha input {
    width: 100%;
    height: 100%;
    background: transparent;
    border: 0px;
    padding: 1% 2%;
}
.dropdown-menu.show a{    padding: 0% 4%;}
.dropdown-menu.drop-add-css1.show {
    right: 0px !important;
    left: -400px!important;
    top: 98%!important;
    overflow-y: scroll;
    min-height: 200px;
    width: 400px;
    transform: none!important;
    max-height: 400px;
}
span.bank_N {
    color: #fc991b;
}
.banking_right a#setting_side {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
button#btnGroupDrop1 {margin-right: 5px;
    border-radius: 50px!important;
}
.bank_list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
button#btnGroupDrop1:hover {
    color: #fff;
}
label.badge.badge-outline-success.badge-pill {
    color: #fff;
    border: 1px solid #fff;
    padding: 0.5rem 0.75rem;
    margin-right: 1%;
}
.btn-group.btn-group_map {
    border-radius: 50px;
}

button#first {
    border-radius: 50px 0px 0px 50px!important;
}
.v3dp__datepicker {
    display: inline;
    float: left;
    width: 48%;
    margin-top: 0px;
    height: 38px;
    margin-right: 2%;
}


button#second {
    border-radius: 0px 50px 50px 0px!important;
}
.clear{clear:both}
</style>