<template>
    <div class="loader">Loading...</div>
  </template>
  
  <script>
  export default {
  
  }
  </script>
  <style scope>
  body {
    background: #eaecfa;
  }
  
  .loader {
       width: 250px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      position: absolute;
      top: 68%;
      left: 58%;
      transform: translate(-50%, -50%);
      font-family: helvetica, arial, sans-serif;
      text-transform: uppercase;
      font-weight: 900;
      color: #333;
      letter-spacing: 0.2em;
  }
  .loader::before, .loader::after {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    background: #fd9d16;
    position: absolute;
    -webkit-animation: load 0.7s infinite alternate ease-in-out;
            animation: load 0.7s infinite alternate ease-in-out;
  }
  .loader::before {
    top: 0;
  }
  .loader::after {
    bottom: 0;
  }
  
  @-webkit-keyframes load {
    0% {
      left: 0;
      height: 30px;
      width: 15px;
    }
    50% {
      height: 8px;
      width: 40px;
    }
    100% {
      left: 235px;
      height: 30px;
      width: 15px;
    }
  }
  
  @keyframes load {
    0% {
      left: 0;
      height: 30px;
      width: 15px;
    }
    50% {
      height: 8px;
      width: 40px;
    }
    100% {
      left: 235px;
      height: 30px;
      width: 15px;
    }
  }
  </style>