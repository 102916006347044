<template>
<CommonHeader />
<div id="main-content">
    <div id="page-container">
        <!--**************************common**************-->
        <div class="report">
            <div class="row">
                    <h1>Reports <router-link to="ViewDetails"><button class="back_user1">Back</button></router-link></h1>
                    <div class="col-md-4 col-lg-4 col-xs-6 col-sm-6 report">
                     <h4><i class="fa fa-balance-scale" aria-hidden="true"></i> SALES</h4>
                      <ul>
                        <li><a href="https://instabill.co/web_sales_customer/view" target="_blank"><i class="fa fa-star-o" aria-hidden="true"></i> Sale by Customer</a></li>
                        <li><a href="https://instabill.co/web_sales_product/view" target="_blank"><i class="fa fa-star-o" aria-hidden="true"></i> Sale by Product</a></li>
                          <li><a href="https://instabill.co/hsn_report/view" target="_blank"><i class="fa fa-star-o" aria-hidden="true"></i> Sale by HSN/SAC </a></li>
                          <li><a href="https://instabill.co/invoice_report/view" target="_blank"><i class="fa fa-star-o" aria-hidden="true"></i> Invoice Report</a></li>
                        <li><a href="https://instabill.co/quotation_report/view" target="_blank"><i class="fa fa-star-o" aria-hidden="true"></i> Quotation Report</a></li>
                      </ul>

                    </div>

                    <div class="col-md-4 col-lg-4 col-xs-6 col-sm-6 report">
                      <h4><i class="fa fa-shopping-cart" aria-hidden="true"></i>  PURCHASE</h4>
                      <ul>
                           <li><a href="https://instabill.co/purchase_party_report/view" target="_blank"><i class="fa fa-star-o" aria-hidden="true"></i> Purchase by Party</a></li>
                        <li><a href="https://instabill.co/web_purchase_report/view" target="_blank"><i class="fa fa-star-o" aria-hidden="true"></i> Purchase by Product</a></li>
                       
                        <li><a href="https://instabill.co/purchase_order_report/view" target="_blank"><i class="fa fa-star-o" aria-hidden="true"></i> Purchase Order Report</a></li>
                      </ul>
                    </div>

                    <div class="col-md-4 col-lg-4 col-xs-6 col-sm-6 report">
                    <h4><i class="fa fa-cart-arrow-down" aria-hidden="true"></i> EXPENSE </h4>
                      <ul>
                        <li><a href="https://instabill.co/web_expence_report" target="_blank"><i class="fa fa-star-o" aria-hidden="true"></i> Expense Summary Report</a></li>
                        <li><a href="https://instabill.co/expense_report/view" target="_blank"><i class="fa fa-star-o" aria-hidden="true"></i> Expense Transaction Report</a></li>
                        
                      </ul>
                    </div>
                    <div class="col-md-4 col-lg-4 col-xs-6 col-sm-6 report">
                      <br>
                    <h4><i class="fa fa-male" aria-hidden="true"></i> PARTY </h4>
                      <ul>
                        <li><a href="https://instabill.co/all_parties_report/view" target="_blank"><i class="fa fa-star-o" aria-hidden="true"></i> All Parties Report</a></li>
                        <li id="setting_side" style="cursor:pointer"><a><i class="fa fa-star-o" aria-hidden="true"></i> Party Statement</a></li>
                        <li><a href="https://instabill.co/creditor_report_list" target="_blank"><i class="fa fa-star-o" aria-hidden="true"></i> Creditor Report</a></li>
                        <li><a href="https://instabill.co/debtor_report" target="_blank"><i class="fa fa-star-o" aria-hidden="true"></i> Debtor Report</a></li>
                        <!-- <li id="Party"><a ><i class="fa fa-star-o" aria-hidden="true"></i> Party Report by Items</a></li> -->
                        <li id="Items" style="cursor:pointer"><a><i class="fa fa-star-o" aria-hidden="true"></i> Party Report by Items</a></li>
                      </ul>
                    </div>
                    
                                                             <div class="col-md-4 col-lg-4 col-xs-6 col-sm-6 report">
                      <br>
                    <h4><i class="fa fa-file-text-o" aria-hidden="true"></i> INVENTORY</h4>
                      <ul>
                         
                         <li><a href="https://instabill.co/stock_summery_report/view" target="_blank"><i class="fa fa-star-o" aria-hidden="true"></i> Stock Summary Report</a></li> 
                           
                        <li><a href="https://instabill.co/inventoryList" target="_blank"><i class="fa fa-star-o" aria-hidden="true"></i> Stock Detail report</a></li>
                        <li id="transaction" style="cursor:pointer"><a><i class="fa fa-star-o" aria-hidden="true"></i> Stock Item transaction report</a></li>
                        <li><a href="https://instabill.co/low_stock_report/view" target="_blank"><i class="fa fa-star-o" aria-hidden="true"></i> Low Stock Summary Report</a></li>
                        <li><a href="https://instabill.co/stock_tracking_report/view" target="_blank"><i class="fa fa-star-o" aria-hidden="true"></i> Stock Tracking Report</a></li>
                      </ul>
                    </div>
                                                            
                    
                                        <div class="col-md-4 col-lg-4 col-xs-6 col-sm-6 report">
                      <br>
                    <h4><img src="@/assets/img/gst.png" /> GST </h4>
                      <ul>
                                              <li><a href="https://instabill.co/gstr1" target="_blank"><i class="fa fa-star-o" aria-hidden="true"></i> GSTR-1</a></li>
                        <li><a href="https://instabill.co/gstr2a" target="_blank"><i class="fa fa-star-o" aria-hidden="true"></i> GSTR-2A ITC RECONCILIATION</a></li>
                       <li><a href="https://instabill.co/gstr3b" target="_blank"><i class="fa fa-star-o" aria-hidden="true"></i> GSTR-3B</a></li>
                                                <li><a href="https://instabill.co/eway_list" target="_blank"><i class="fa fa-star-o" aria-hidden="true"></i> E-Way Bill</a></li>
                                                <li><a href="https://instabill.co/e_invoice_list/view" target="_blank"><i class="fa fa-star-o" aria-hidden="true"></i> E-Invoice</a></li> 
                                              </ul>
                    </div>
                                        <div class="col-md-4 col-lg-4 col-xs-6 col-sm-6 report">
                      <br>
                    <h4> <i class="fa fa-files-o" aria-hidden="true"></i>  OTHER REPORTS</h4>
                      <ul>
                        <li><a href="https://instabill.co/day_book_report/view" target="_blank"><i class="fa fa-star-o" aria-hidden="true"></i> Day Book</a></li>
                        <li><a href="https://instabill.co/profit_loss_report/view" target="_blank"><i class="fa fa-star-o" aria-hidden="true"></i> Profit &amp; Loss Report </a></li>
                        
                        <li><a href="https://instabill.co/payment_report" target="_blank"><i class="fa fa-star-o" aria-hidden="true"></i> Instamojo Payment Report </a></li>
                        <li><a href="https://instabill.co/challan_list/view" target="_blank"><i class="fa fa-star-o" aria-hidden="true"></i> Delivery Challan Report </a></li>
                      
                                                <li><a href="https://instabill.co/income_tax_report/view" target="_blank"><i class="fa fa-star-o" aria-hidden="true"></i> Income Tax Report </a></li>
                        <li><a href="https://instabill.co/gst_tax_report/view" target="_blank"><i class="fa fa-star-o" aria-hidden="true"></i> GST Tax Report </a></li>
                                               </ul>
                    </div>
                  </div>
            
        </div>

    </div>
</div>

<span id="show-lable">Hello</span>
</template>

<script>
import CommonHeader from './CommonHeader.vue';

export default {
    name: 'AllReports',
    components: {
         CommonHeader
    },
    created() {

    },

}
</script>
<style>
button.back_user1 {
    background: linear-gradient(to top left, #ffae00 0%, #f66a59 100%) !important;
    border-color: #fd9d16;
    border-radius: 5px!important;
    color: #fff;
    padding: 4px 2%;
    margin-left: 37%;
    transition: 1s;
    float: right;
    font-size: 20px;
    margin-right: 5%;
}
</style>