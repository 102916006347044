import axios from 'axios';

export const checkEmail = (email) => {
  const re =
    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!re.test(email)) {
    return false;
  }

  return true;
};

export const postApi = (url, data, headers) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`https://instabill.co/api${url}`, data, {
        headers: headers,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const postApi2 = (url, data, headers) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`https://instabill.co/api${url}`, data, {
        headers: headers,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
