<template>

    <div class="sign_page">
                  <!--*************alert-->
            <div :class="alertClass" id="hidemessage" v-if="message"><p class="txt">{{alertMessage}}</p>
                <i class="fa fa-times close_msg" aria-hidden="true" @click="close_msg()"></i>
            </div>
        <!--*************alert-->
        <div class="sign_left">
             <img src="https://instabill.co/public/ca_images/profess.png" />
             <p><span>Join Instabill </span><br>
    Manage GST Filing, Automate Tally Accounting, Refer & Earn and many more....</p>
        </div>
        <div class="sign_right">
            <div class="inner_r">
                <img src="https://instabill.co/public/instabillpro_logo.png" />
                <h2 v-if="heading">Sign Up</h2>
                <form >
                    <div class="slide_1" v-if="info_1">
                        <div class="box_in">
                            <label>Name</label><br>
                            <input type="text" placeholder="Name" v-model="name">
                        </div>
                        <div class="box_in">
                            <label>Mobile</label><br>
                            <input type="text" placeholder="Mobile No." v-model="mobile">
                        </div>
                        <div class="box_in">
                            <label>Email</label><br>
                            <input type="text" placeholder="Email ID" v-model="email">
                        </div>
                        <div class="box_in">
                            <label>Password</label><br>
                            <input v-if="showPassword" type="text" class="input" v-model="password" />
                            <input v-else type="password" placeholder="Enter Password" class="input" v-model="password">
                            <span class="icon is-small is-right" @click="toggleShow">
                                <i class="fa" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                            </span>
                        </div>
                        <div class="box_in">
                            <label>Confirm-Password</label><br>
                            <input v-if="showRe_Password" type="text" class="input" v-model="re_password" />
                            <input v-else type="password" placeholder="Enter Confirm Password" class="input" v-model="re_password">
                            <span class="icon is-small is-right" @click="toggleShow1">
                                <i class="fa" :class="{ 'fa-eye-slash': showRe_Password, 'fa-eye': !showRe_Password }"></i>
                            </span>
                        </div>
                         <div class="box_in">
    
                        <button type="button" v-on:click="slide_sec()">Next</button>
                    
                    <p style="margin-top:10px">Have you an account?  <router-link to="/">Sign In </router-link></p>
                    </div>
                    </div>
                    <div class="slide_2" v-if="info2">
                        <div class="box_in">
                            <label>Firm Name</label><br>
                            <input type="text" placeholder="Enter Firm Name" v-model="firm_name">
                        </div>
                        <div class="box_in">
                            <label>Professional Category</label><br>
                            <select v-model="professional_category">
                                <option>CA</option>
                                <option>CS</option>
                                <option>Account Profession</option>
                                <option>Other</option>
                            </select>
                        </div>
                        <div class="box_in">
                            <label>Membership Number</label><br>
                            <input type="text" placeholder="Enter Membership No." v-model="membership_no">
                        </div>
                         <div class="box_in">
    
                        <button type="button" v-on:click="slide_sec1()" class="sub1" style="position:relative;padding: 10px;">Submit <img src="https://instabill.co/public/front/images/loader.gif" class="load_show" v-if="loader" style="width: 15px;left:26%;top:29%;"/></button>
                        <button type="button" class="back_slide" v-on:click="slide_bk()">Back</button>
                    </div>
                        
                        
                    </div>
    
                   
                    
                    
                </form>
                <div class="slide_3" v-if="info3">
                      <p class="msg"><span>Thanks for Submit the form.</span><br> Your Account is Under Approval</p>
                        <img src="https://instabill.co/public/ca_images/check-mark.png" class="check"/>
                    </div>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import {checkEmail, postApi} from '../assets/js/helper.js';
    export default {
        name: 'CreateAccount',
        data() {
            return {
                loader:false,
                info2: false,
                info3:false,
                info_1: true,
                showPassword: false,
                showRe_Password: false,
                password: null,
                heading:true,
                name:"",
                email:"",
                mobile:"",
                re_password:"",
                firm_name:"",
                professional_category:"CA",
                membership_no:"",
                   message: false,
                alertMessage:"",
                alertClass:"",
            }
        },
        methods: {
            slide_sec() {
                this.info_1 = false;
                this.info2 = true;
            },
            async slide_sec1(){
          //check all fields are filled
          if (
            this.name == '' || this.email == '' ||  this.mobile == '' || 
            this.password == '' ||  this.re_password == ''   ) {
                
                 this.alertMessage = "Please Enter Name,Email,Password and Mobile Number Field";
                    this.alertClass = "error_msg";
                    this.message = true;
                    setTimeout(() => this.message = false, 1000);
    
             
            return false;
          }
          //check email is valid
          if (!checkEmail(this.email)) {
             this.alertMessage = "Please enter valid email";
                    this.alertClass = "error_msg";
                    this.message = true;
                    setTimeout(() => this.message = false, 1000);
    
     
            return false;
          }
          //check password
          if (this.password != this.re_password) {
             this.alertMessage = "passwords doesn't match";
                    this.alertClass = "error_msg";
                    this.message = true;
                    setTimeout(() => this.message = false, 1000);
    
        
            return false;
          }
          else
          {
             this.loader = true;
          }
         
         const response  = await postApi(
            '/ca_register',
            {
              name: this.name,
              phone: this.mobile,
              email: this.email,
              password: this.password,
              firm_name:this.firm_name,
              professional_category:this.professional_category,
              membership_number:this.membership_no
            },
            {},
          )
            try{
         if (response.errorCode == '0') {
                this.info3 = true;
                this.info2 = false;
                this.heading = false; 
                return false;
              } else {
                 this.loader=false;
                  this.alertMessage = response.errorMsg;
                    this.alertClass = "error_msg";
                    this.message = true;
                    setTimeout(() => this.message = false, 1000);
          
              }
            }  
            catch(err){
                 this.loader=false;
                       this.alertMessage = err;
                    this.alertClass = "error_msg";
                    this.message = true;
                    setTimeout(() => this.message = false, 1000);
    
            }     
        },
        close_msg() {
                 this.message = false;
            },
            slide_bk() {
                this.info_1 = true;
                this.info2 = false;
            },
            toggleShow() {
                this.showPassword = !this.showPassword;
            },
             toggleShow1() {
                this.showRe_Password = !this.showRe_Password;
            },
            
        },
        computed: {
            buttonLabel() {
                return (this.showPassword) ? "Hide" : "Show";
            },
            buttonLabel1() {
                return (this.showRe_Password) ? "Hide" : "Show";
            }
        },
    
    }
    </script>
    
    <style scoped>
    .inner_r {
        padding-top: 3%;
        position: relative;
    }
    button.sub1 {
        width: 50%;
        float: left;
    }
    button.back_slide {
            background: #ffae00;
        color: #fff;
        border: 0px;
        padding: 2%;
        box-shadow: 0px 20px 50px 0px rgb(153 153 153 / 10%);
        transition: 1s;
        float: left;
        width: 48%;
        margin-left: 2%;
        height: 45px;
    }
    p.msg {
           color: #000;
        border: 0px;
        padding: 1% 5%;
        border-radius: 5px;
        transition: 1s;
        font-size: 20px;    line-height: 40px;
        margin-top: 19%;
    }
    p.msg span {
        font-size: 27px;
        font-weight: 500!important;
    }
    .box_in {
        margin-top: 3%;
    }
    
    .box_in input {
        width: 100%;
        border: 1px solid #ccc;
        padding: 2%;
        background: transparent;
        border-radius: 3px !important;
        font-size: 13px;
    }
    
    select {
        cursor: pointer;
    }
    img.load_show {
        position: absolute;
        right: 39%;
        z-index: 99999999;
        bottom: 21%;
    }
        .msg {
           position: absolute;
        top: 73%;
        z-index: 999;
        width: -webkit-fill-available;
    }
    img.check {
        margin-top: 10%;
    }
    p.txt {
        width: 91%;
        float: left;
        margin: 0;
    }
    .error_msg {
        position: fixed;
        top: 0%;
        z-index: 999;
        width: 20%;
        padding: 1%;
        background: #fff;
        box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
        border-radius: 5px;
        right: 0;
        border-left: 4px solid #db0014;
        transition: 1s;
        color: red;
    }
    </style>
    