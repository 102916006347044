<template>
    <CommonHeader />
    <div id="main-content">
        <div id="page-container">
                  <!--*************alert-->
        <div :class="alertClass" id="hidemessage" v-if="message"><p class="txt">{{alertMessage}}</p>
            <i class="fa fa-times close_msg" aria-hidden="true" @click="close_msg()"></i>
        </div>
    <!--*************alert-->
        <div class="my_profile">
            <h4> Change Password </h4>
            <form v-on:submit.prevent="changeCAPassword" method="post">
                <div class="my_pro">
                    <label class = 'control-label'>Existing Password </label>
                 <input type="password" v-model="old_password" required placeholder='Existing Password' class= 'form-control'>
                 </div> 
                 
                 <div class="my_pro">
                    <label class = 'control-label'>New Password </label>
                 <input type="password" v-model="new_password" required placeholder='New Password' class= 'form-control'>
                   </div>
       
                   <div class="my_pro">
                    <label class='control-label'>Confirm Password </label>
                 <input type="password" v-model="confirm_password" required placeholder='Confirm Password' class= 'form-control'>
                 </div>
                    <div class="clear"></div>
                    <br>
                 <div  class="update_btn">
                  <input type="submit" value='Update' style="    margin-top: 2%;
    width: 100%;
    background: #333;
    border: 0px;" class='btn btn-primary'/> 
    <img src="https://instabill.co/public/front/images/loader.gif"
                class="load_show"
                v-if="loader"
                style="width: 15px;"
              />
                </div>
                </form>
       
              
            </div>

    </div>
</div>
    </template>
    
    <script>
    import CommonHeader from './CommonHeader.vue';
    import { postApi } from "../assets/js/helper.js";
    export default {
        name: 'ChangePassword',
        components: {
            CommonHeader
        },
        data() {
            return {
                new_password:"",
                old_password:"",
                confirm_password:"",
                loader: false,
                message: false,
            alertMessage:"",
            alertClass:"",
                };
        },
     methods: {
           async changeCAPassword(){
             var id= localStorage.getItem('admin_id');
              const response = await postApi(
            "/changeCAPassword",
            {
                ca_id: id,
                new_password:this.new_password,
                old_password:this.old_password,
                confirm_password:this.confirm_password,
            },
            {}
          );
          try {
            if (response.errorCode == "0") {
                this.loader = true;
                 this.alertMessage = response.errorMsg;
                this.alertClass = "success";
                this.message = true;
                setTimeout(() => this.message = false, 3000);

                this.new_password= "";
                this.old_password="";
                this.confirm_password="";
                this.loader = false;
                return false;
                
                
            } 
            else if (response.errorCode == "1") {
                 this.alertMessage = response.errorMsg;
                this.alertClass = "error_msg";
                this.message = true;
                setTimeout(() => this.message = false, 3000);

           return false;
            } 
          } catch (err) {
            alert(err);
          }
    
            },
    
        },
        close_msg() {
             this.message = false;
        },
    
    }
    </script>
    <style scoped>
    .update_btn {
    position: relative;
    width: 46%;
}
    .my_pro {
    width: 46%;
    margin-right: 4%;
    position: relative;
    float: none;
}
.clear{clear:both;}
img.load_show {
    position: absolute;
    right: 39%;
    z-index: 99999999;
    bottom: 21%;
}
    .msg {
    position: fixed;
    top: 0%;
    z-index: 999;
    width: 20%;
    padding: 1%;
    background: #fff;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    border-radius: 5px;
    right: 0;
    border-left: 4px solid #ffae00;transition: 1s;
}
p.txt {
    width: 91%;
    float: left;
    margin: 0;
}
.error_msg {
    position: fixed;
    top: 0%;
    z-index: 999;
    width: 20%;
    padding: 1%;
    background: #fff;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    border-radius: 5px;
    right: 0;
    border-left: 4px solid #db0014;
    transition: 1s;
    color: red;
}
    </style>