<template>
  <div id="main-content">
    <div id="page-container">
      <!--**************************common**************-->
      <h1 class="hed">Client List</h1>
      <div class="clear"></div>
      <h1 class="head">
        <span class="tab selected" @click="bgcolor(3)" id="all">All</span>
        <span class="tab" @click="bgcolor(1)" id="expired">Expired</span>
        <span class="tab" @click="bgcolor(2)" id="active">Active</span>
      </h1>
      <div class="search search_client" style="">
        <input
          id="myInput"
          type="text"
          placeholder="Search.."
          @keyup="performSearch(this)" v-model="search"
        />
        <button style="padding: 2px;border: none;border-radius:10px;">
          <i class="fa fa-search" aria-hidden="true"></i>
        </button>
      </div>
      <div class="clearfix"></div>
      <table class="list-box">
        <tr class="list">
          <th>Profile Name</th>
          <th>GSTIN</th>
          <th>Current Plan</th>
          <th>Email</th>
          <th>Mobile No.</th>
          <th style="width:13%;text-align: center; padding-right: 3%;">
            Action
          </th>
        </tr>
        
        <tr v-if="clients == ''">
          <td colspan="5" align="center">No data Found</td>
        </tr>

        <tr v-else v-for="(client, index) in clients" :key="index">
          <td>{{ client.company_detail.company_name }}</td>
          <td>{{ client.company_detail.gstin_text }}</td>
          <td>
            <span class="amt">{{ client.package }}</span>
          </td>
          <td>{{ client.company_detail.email }}</td>
          <td>{{ client.company_detail.mobile }}</td>
          <td align="center" v-if="client.status == '0'">
            <a
              href=""
              class="badge badge-danger badge-pill"
              style="float: left;height: 26px;width: 50px;padding: 7px;margin: 1px"
              v-on:click="actionClientList(client.id, 2)"
              >Reject</a
            >

            <a
              href=""
              class="badge badge-success badge-pill"
              style="float: left;height: 26px;width: 50px;padding: 7px;margin: 1px"
              v-on:click="actionClientList(client.id, 1)"
              >Accept</a>
          </td>

          <td align="center" v-else>
            <router-link
              :to="{
                name: 'ViewDetails',
                params: {
                  user_id: client.user_id,
                  company_id: client.company_id,
                },
              }"
              ><button class="view_d">View Detail</button></router-link>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';

export default {
  name: "viewClientList",
  components:{
  },
  props: ["clients"],
  data() {
    return {
      search: "",
     };
  },
  methods: {
    actionClientList(id, status) {
      event.preventDefault();
      this.$emit("changeStatus", {
        id: id,
        status: status,
      });
    },
    bgcolor(id) {
      var div = document.querySelectorAll(".selected");
      for (var i = 0; i < div.length; i++) {
        div[i].classList.remove("selected");
      }

      if (id == "3") {
        document.getElementById("all").classList.add("selected");
        this.search = "";
      } else if (id == "2") {
        document.getElementById("active").classList.add("selected");
        this.search = "Active";
      } else if (id == "1") {
        document.getElementById("expired").classList.add("selected");
        this.search = "Expired";
      }
    },
    performSearch(){
 var value = (this.search).toLowerCase();

$("table tr").each(function(index) {
    if (index !== 0) {
        var id = $(this).children().text().toLowerCase()
        if (id.indexOf(value) < 0) {
           $(this).hide();
        }else {
            $(this).show();
        }
    }
 });
                },
  },
  
};
</script>

<style>
.amt {
  color: #f6f7fb;
  font-size: 12px;
  background: linear-gradient(85deg, #f66a59, #ffae00);
  padding: 2px 8px;
  border-radius: 5px;
  margin-top: 5px;
  display: inline-block;
}

h1.hed {
  color: #f97d40;
}

.clear {
  clear: both;
}

input#myInput {
  border: 2px solid black;
  background: transparent;
  margin:2px;
  /* border-bottom: 2px solid #ddd; */
}
.search.search_client {
    width: 25% !important;
    float: right;
}
h1.head {
    margin-left: 0px;
}
.search.search_client  i {
    background: linear-gradient(85deg, #f66a59, #ffae00);
    padding: 10px!important;
    color: white;
    border-radius: 3px!important;
}
.search.search_client input {
    border: 1px solid #d6d0d0 !important;
    padding: 2%;
    border-radius: 5px !important;
}
.search i {
  background: linear-gradient(85deg, #f66a59, #ffae00);
  padding: 10px;
  color: white;
  border-radius: 10px;
}

table.list-box {
  margin-top: 2%;
}

.search {
  margin-top: 16px;
  width: 25%;
  float: left;
}
.selected {
  background: linear-gradient(85deg, #f66a59, #ffae00) !important;
  color: #fff !important;
}
h1.hed {
  color: #f97d40;
  width: auto;
  float: left;
  color: #fb8f29;
  border-bottom: 1px solid #fb8f29;
  padding-bottom: 1%;
  display: inline;
}

h1.head {
  width: 70%;
  float: left;
}
</style>
