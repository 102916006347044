<template>
    <CommonHeader />
    <div id="main-content">
        <div id="page-container">
          <!--*************alert-->
        <div :class="alertClass" id="hidemessage" v-if="message"><p class="txt">{{alertMessage}}</p>
            <i class="fa fa-times close_msg" aria-hidden="true" @click="close_msg()"></i>
        </div>
    <!--*************alert-->
            <!--**************************common**************-->
            <h1 class="hed">GST Return</h1>
    
            <div class="clearfix"></div>
            <div class="wrap">
                <div class="gst-box">
                    <h3>{{pending_gstr1}}</h3>
                    <h4>GSTR-1</h4>
                    <p>Pending</p>
                    <!-- <span>No. of days are left <span>XX</span></span> -->
                </div>
                <div class="gst-box">
                    <h3>{{pending_gstr3b}}</h3>
                    <h4>GSTR-3B</h4>
                    <p>Pending</p>
                </div>
                <div class="gst-box">
                    <h3>{{pending_cmp08}}</h3>
                    <h4>CMP-08</h4>
                    <p>Pending</p>
                </div>
                <div class="gst-box">
                    <h5>GST Return Filed</h5>
                    <p>GSTR-1<span>{{filed_gstr1}}</span></p>
                    <p>GSTR-3B<span>{{filed_gstr3b}}</span></p>
                    <p>CMP-08<span>{{filed_cmp08}}</span></p>
                </div>
            </div>
    <div class="wrap1">
            <h1 class="head">
                <span class="tab selected" @click="bgcolor(1)" id="all">All</span>
                <span class="tab" @click="bgcolor(2)" id="filed">Filed</span>
                <span class="tab" @click="bgcolor(3)" id="not_filed">Not Filed</span>
                <select v-model="month" class="wi" id="Month">
                    <option value="">Month</option>
                    <option value="01">January</option>
                    <option value="02">February</option>
                    <option value="03">March</option>
                    <option value="04">April</option>
                    <option value="05">May</option>
                    <option value="06">June</option>
                    <option value="07">July</option>
                    <option value="08">August</option>
                    <option value="09">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                    <option value="13">Jan-Mar</option>
                    <option value="14">Apr-June</option>
                    <option value="15">July-Sept</option>
                    <option value="16">Oct-Dec</option>
                   </select>
                <select v-model="year" class="wi" id="year">
                    <option value="2015">2015</option>
                    <option value="2016">2016</option>
                    <option value="2017">2017</option>
                    <option value="2018">2018</option>
                    <option value="2019">2019</option>
                    <option value="2020">2020</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    
                </select>
                <span class="new_search_button" type="submit" @click="get_gst_return">Submit <img src="https://instabill.co/public/front/images/loader.gif"
                class="load_show"
                v-if="loader"
                style="width: 15px;"
              /></span>

                <span @click="gst_return_excel"><img style="cursor:pointer;vertical-align: bottom;padding-left: 10px;" src="https://instabill.co/public/images/xls.png" alt=""> <img src="https://instabill.co/public/front/images/loader.gif"
                class="load_show"
                v-if="loader1"
                style="width: 15px;"
              /></span>
                   
                <div class="clearfix"></div>
            </h1>
    
            <div class="search search_gst" style=""><input id="myInput" type="text" placeholder="Search.." v-model="search" > <button style="padding:0;border:none;border-radius:10px;float: right;"><i class="fa fa-search" aria-hidden="true"></i></button></div>
        </div>
            <table class="list-box">
                <tr class="list">
                    <th>Insta ID</th>
                    <th>Company Name</th>
                    <th>Type Of Return</th>
                    <th>Period</th>
                    <th>Due Date</th>
                    <th style="width: 13%;">Status</th>
                </tr>
                <tr v-for="(gstReturn,index) in filteredGstreturn" :key="index">
                <td> {{gstReturn.insta_id}} </td>
                <td>  {{gstReturn.company_name}} <router-link  :to="{
                name: 'ViewDetails',
                params: {
                  user_id: gstReturn.user_id,
                  company_id: gstReturn.company_id,
                },
              }">  <img src="https://instabill.co/public/ca_images/link.png"> </router-link></td>
                <td><span class="amt">{{gstReturn.type_of_return}}</span></td>
                <td>{{gstReturn.month_name}}/{{gstReturn.year}}</td>
                <td>{{gstReturn.due_date}}</td>
                <td><span :class="gstReturn.classname">{{gstReturn.status}}</span> <i class="fa fa-pencil-square-o" aria-hidden="true" v-on:click="opn_up_sts(gstReturn.id)"></i></td>
                </tr>
            </table>
            <div class="Update_status" v-if="update_Sts">
                <p class="head_sb">Update Status <span aria-hidden="" class="closed_open_m" v-on:click="cls()"><i class="fa fa-times" aria-hidden="true"></i></span></p>
               <form v-on:submit.prevent="add_gst_return" method="post">
                <div class="my_pro">
                    <label>ARN</label><br>
                    <input type="text" v-model="arn">
                </div>
                <div class="my_pro">
                    <label>Date of filing</label>   <br>
                    <datepicker  style="width:100%;text-align:left;cursor: pointer;padding: 3%;position:relative;" v-model="date" id="dof" inputFormat="dd-MM-yyyy" class="cal1"></datepicker>
                     
                    <i class="fa fa-calendar" aria-hidden="true"></i>
                </div>
                <div class="my_pro">
                    <label>Remark</label><br>
                    <textarea rows="2" v-model="remark" ></textarea>
                </div>
                <div class="my_pro">
                    <button class="up_sub" type="submit" >Submit</button>
                </div>
                
                
            </form>
            </div>
    
        </div>
    
    </div>
    
    </template>
    
    <script>
    import CommonHeader from './CommonHeader.vue';
    import datepicker from 'vue3-datepicker'
    import { postApi } from "../assets/js/helper.js";
    import axios from 'axios'
    export default {
        name: 'UserEarning',
        components: {
            datepicker,
            CommonHeader
        },
        created(){
            const d = new Date();
            let month = d.getMonth();
            let year = d.getFullYear();
        
         if(month == "0")
         {
           month=11;
         }
         else
         {
           month=month-1;
         }

          if(month.toString().length > 1 || month == 9)
{
    this.month=month+1;
}
else
{
   this.month="0"+(month+1);
}

if(month == "11")
         {
            this.year=year-1;
         }
         else
         {
         this.year=year;
        }

            
             this.getGstReturn(this.month,this.year);
        },
        data() {
            return {
                update_Sts: false,
                search:"",
                month:"",
                year:"",
                gstReturnData:"",
                arn:"",
                date:"",
                dof:"",
                remark:"",
                which_index:"",
                pending_gstr1:"0",
                pending_gstr3b:"0",
                pending_cmp08:"0",
                filed_gstr1:"0",
                filed_gstr3b:"0",
                filed_cmp08:"0",
                loader:false,
                loader1:false,
                message: false,
            alertMessage:"",
            alertClass:"",
            }
        },
        methods: {
            opn_up_sts(index) {
                this.which_index=index;
            if(this.gstReturnData[index].arn!="")
           {
this.arn=this.gstReturnData[index].arn;
this.dof=this.gstReturnData[index].date_of_filling;
this.date=new Date(this.dof);
this.remark=this.gstReturnData[index].remark;
           } 
           else
           {
            this.arn="";
            this.remark="";  
           }               
           this.update_Sts = true;
           
        },

            cls() {
                this.update_Sts = false;
            },
         
            async getGstReturn(month,year) {
                this.loader = true;
 const response = await postApi(
        "/get_gst_return",
        {
         month:month,
         year:year,
         ca_id:localStorage.getItem("admin_id"),
        },
        {}
      );
      try {
        if (response.errorCode == "0") {
          console.log(response.data);
          this.loader = false;
       this.gstReturnData=response.data;
       this.pending_gstr1=response.pending_gstr1;
                this.pending_gstr3b=response.pending_gstr3b;
                this.pending_cmp08=response.pending_cmp08;
                this.filed_gstr1=response.filed_gstr1;
                this.filed_gstr3b=response.filed_gstr3b;
                this.filed_cmp08=response.filed_cmp08;
       return false;
        } 
      } catch (err) {
        alert(err);
      }
            },
            get_gst_return(){
             this.getGstReturn(this.month,this.year);
            },
   
        bgcolor(id) {
      var div = document.querySelectorAll(".selected");
      for (var i = 0; i < div.length; i++) {
        div[i].classList.remove("selected");
      }
    
      if (id == "1") {
        document.getElementById("all").classList.add("selected");
        this.search = "";
        
      } else if (id == "2") {
        document.getElementById("filed").classList.add("selected");
        this.search = "#1";
      } else if (id == "3") {
        document.getElementById("not_filed").classList.add("selected");
        this.search = "#2";
      }
    },  
   async add_gst_return(){
  var index=this.which_index;
  var data=this.gstReturnData[index];
  
  var submit_arn=this.arn;
  var dof=document.getElementById("dof").value
  var remark=this.remark;

  const response = await postApi(
        "/add_gst_return",
        {
         arn:submit_arn,
         dof:dof,
         remark:remark,
         ca_id:localStorage.getItem("admin_id"),
         data:data
        },
        {}
      );
      try {
        if (response.errorCode == "0") {
        // alert("GST Return Filed Successfully");
         this.alertMessage="GST Return Filed Successfully";
            this.alertClass="success";
            this.message = true;
          this.date="";
            setTimeout(() => this.message = false, 3000);
          
        this.update_Sts = false;
        this.get_gst_return();   
       return false;
        } 
      } catch (err) {
        alert(err);
      }
    },
     close_msg() {
             this.message = false;
        },
      
        gst_return_excel(){

            this.alertMessage="Download in processing...";
            this.alertClass="success";
            this.message = true;
            setTimeout(() => this.message = false, 3000);

    var month= this.month;
    var year= this.year;
    var ca_id=localStorage.getItem("admin_id");
    
    axios({
    url:"https://instabill.co/api/gst_return_excel/"+ca_id+"/"+month+"/"+year+"",
    method:"GET",
    responseType:"blob",
        }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', "Gst_Return_Excel.csv")
      link.click();
    });
    },

        },
        computed: {
        filteredGstreturn: function () {
            var self = this;
            if(this.gstReturnData!="")
            {
            return this.gstReturnData.filter(function (person) {
                return person.insta_id.toLowerCase().indexOf(self.search.toLowerCase()) >= 0
                || person.company_name.toLowerCase().indexOf(self.search.toLowerCase()) >= 0
                || person.type_of_return.toLowerCase().indexOf(self.search.toLowerCase()) >= 0
                || person.month_name.toLowerCase().indexOf(self.search.toLowerCase()) >= 0
                || person.due_date.indexOf(self.search.toLowerCase()) >= 0
                || person.search_help.indexOf(self.search.toLowerCase()) >= 0
                || person.status.toLowerCase().indexOf(self.search.toLowerCase()) >= 0;
            });
        }
        else
        {
            return null;
        }
        }
    }
    }
    </script>
    
    <style scoped>
    
        .selected {
  background: linear-gradient(85deg, #f66a59, #ffae00) !important;
  color: #fff !important;
}
span.green {
    background: #04B76B;
    color: #fff;
    padding: 4px 6%;
    font-size: 12px;
    border-radius: 5px;
}
.search.search_gst{
    width: 25% !important;
    float: right;
}
    .my_pro i.fa.fa-calendar {
        position: absolute;
        right: 3%;
        top: 61%;
        color: #ffae00;
    }
    
    span.new_search_button {
            color: #f6f7fb;
        font-size: 14px;
        background: linear-gradient(85deg, #f66a59, #ffae00);
        padding: 7px 8px;
        border-radius: 5px;
        margin-top: 5px;
        display: inline-block;
        margin-left: 1%;
    }
    
    h1.hed {
        margin-left: 0px;
    }
    select#year {
        margin: 0% 1% 0% 2%!important;
    }
    .wi {
            width: 18% !important;
        padding: 7px 15px !important;
        background: #fff;
        color: #000 !important;
        margin-left: 0%!important;
        font-size: 15px;
        box-shadow: 0px 5px 25px -9px rgb(2 62 83 / 67%);
        margin-top: 1%;
        display: inline-block;
        border: 1px solid #dfe0e3 !important;
        transition: 0.5s;
        cursor: pointer;
        border-radius: 5px !important;
    }
    .wrap1 {
    width: 99%;
}
.search.search_gst i {
    background: linear-gradient(85deg, #f66a59, #ffae00);
    padding: 10px !important;
    color: white;
    border-radius: 3px !important;
}
.search.search_gst input {
    border: 1px solid #d6d0d0 !important;
    padding: 2%;    margin: 0 !important;
    border-radius: 5px !important;
}
    .wrap {    height: 145px;
        width: 99%;
        margin-left: 0%;margin-bottom: 1%;
    }
    
    i.fa.fa-pencil-square-o {
        width: 20px;
        height: 20px;
        text-align: center;
        border-radius: 5px;
        font-size: 16px;
        line-height: 18px;
        float: right;
        cursor: pointer
    }
    tr.list th {
    padding: 13px;
    font-size: 13px;}
    .Update_status {
        /* display: none; */
        float: right;
        z-index: 9999;
        position: fixed;
        right: 0;
        top: 0px;
        background: white;
        height: 100%;
        transition: 1s;
        border: 1px solid #d4d7dc;
        border-right: none;
        border-top: none;
        box-shadow: 0px 3px 21px 0px rgb(0 0 0 / 20%);
        width: 410px;
        padding: 15px;
    }
    .gst-box span {
        font-size: 14px;
    }
    .gst-box p {
        font-size: 15px;
        margin-top: 0%;
        margin: 0;
    }
    .my_pro {
        width: 100%;
    }
    .wi{    width: 18% !important;
        padding: 7px 15px !important;
        background: #fff;
        color: #000 !important;
        margin-left: 1%;
        font-size: 15px;
        box-shadow: 0px 5px 25px -9px rgb(2 62 83 / 67%);
        margin-top: 1%;
        display: inline-block;
        border: 1px solid #dfe0e3 !important;
        transition: 0.5s;
        cursor: pointer;
        border-radius: 5px !important;
        height: auto;}
    .my_pro label {
        font-family: 'Poppins', sans-serif;
        letter-spacing: 0.05em;
        font-size: 14px;
        color: #000000;
    }
    
    .v3dp__datepicker {
        width: 100%;
    }
    .my_pro textarea {
        padding: 2%;
        border: 1px solid #ddd;
        width: 100%;
        border-radius: 5px!important;
    }
    .v3dp__datepicker input {
        width: 100% !important;
        padding: 2%;
        text-align: left !important;cursor:pointer
    }
    
    button.up_sub {
        color: #f6f7fb;
        font-size: 19px;
        background: linear-gradient(85deg, #f66a59, #ffae00);
        padding: 1% 6%;
        border-radius: 5px;
        margin-top: 5px;
        display: inline-block;
        border: 0px;
        float: right;
    }
    .gst-box p span {
        float: right;
        padding-right: 13%;
    }
    .gst-box h5 {
        font-size: 22px;
        margin-top: 6%;
    }
     .success {
    position: fixed;
    top: 0%;
    z-index: 999;
    width: 20%;
    padding: 1%;
    background: #fff;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    border-radius: 5px;
    right: 0;
    border-left: 4px solid #ffae00;transition: 1s;
}
.my_pro input {
    height: 48px;
}
.v3dp__datepicker {
    width: 100% !important;
}
p.txt {
    width: 91%;
    float: left;
    margin: 0;
}
    </style>
    