<template>
  <CommonHeader />
  <div id="main-content">
      <div id="page-container">
      <h1 class="hed" style="    margin-bottom: 3%;">Client Details</h1>
      
          <!--**************************common**************-->
          <div class="profile" style="width:auto">
              <img :src="profile_img" class="profile_img" v-if="profile_img"/>
              <router-link :to="{ name: 'ViewDetails', params:{user_id:user_id , company_id:company_id} }"><button class="back_user_1" style="padding: 5px 0%;"><i class="fa fa-angle-double-left" aria-hidden="true"></i> Back</button></router-link>
          </div>
  <div class="clear"></div>
          <div class="field">
              <label for="gstin_text">GSTIN</label><br>
              <input type="text" name="gstin_text" maxlength="15" v-model="gstin_text" readonly id="gstin_text" placeholder="GSTIN" >
          </div>
          <div class="field">
              <label for="company_name">Company Name</label><br>
              <input type="text"  required="" v-model="company_name" readonly placeholder="Company Name">
          </div>
          <div class="field">
              <label for="address">Address</label><br>
              <input type="text" v-model="address" readonly required="" placeholder="Address">
          </div>
          <div class="field">
              <label for="pincode">Pincode</label><br>
              <input type="text" v-model="pincode" readonly placeholder="Pincode">
          </div>
          <div class="field">
              <label for="email">GST Composite Scheme</label><br>
              <label class="switch" v-if="off">
                  <span class="slider round "></span>
              </label>
          
              <label class="switch" v-if="on">
                  <span class="slider round orange"></span>
              </label>
          
            </div>
  
          <div class="field">
         <label for="email">Email</label><br>
      <input type="email" v-model="email" readonly required="" placeholder="Email">
      
    </div>
    <div class="field">
         <label for="mobile">Mobile</label><br>
      <input type="number" v-model="mobile" readonly required="" placeholder="Mobile">
     
    </div>
    <div class="field">
      <label for="business">Nature of Business</label>
        <input type="text" v-model="business_type" readonly required="" placeholder="Nature of Business">
    </div>
    <div class="field">
      <label for="business">Type of Entity</label>
          <input type="text" v-model="entity_type" readonly required="" placeholder="Nature of Business">
    </div>
    <div class="field">
        <label for="cin">CIN</label><br>
      <input type="text" v-model="cin" readonly placeholder="CIN">
      
    </div>
    <div class="field">
        <label for="pan">PAN </label><br>
      <input type="text" v-model="pan_card" readonly placeholder="PAN">
      
    </div>
    <div class="field">
          <label for="Website">Website </label><br>
      <input type="text" v-model="website" readonly placeholder="Website">
      
     </div>
     <div class="field">
            <label for="licence">License Title </label><br>
          <input type="text" v-model="license" readonly placeholder="Licence Title">
        </div>
        <div class="field">
            <label for="licence-No">License Number </label><br>
          <input type="text" v-model="license_number" readonly placeholder="Licence Number" >
          
        </div>
         
      </div>
  </div>
  
  </template>
  
  <script>
  import CommonHeader from './CommonHeader.vue';
  import { postApi } from "../assets/js/helper.js";
  export default {
      name: 'UserProfile',
      components: {
           CommonHeader
      },
      data()
      {
          return{
               companyId:this.company_id,
               gstin_text:"",
               company_name:"",
               adress:"",
              pincode:"",
              gst_composite_scheme:"",
              email:"",
              mobile:"",
              business_type: "",
              entity_type:"",
              cin:"",
              pan_card:"",
              website:"",
              license:"",
              license_number:"",
              profile_img:"",
              off:false,
              on:false,
               
          }
      },
      props:["user_id","company_id"],
       created() {
   this.getProfileData();
      },
    methods: {
      async getProfileData() {
   const response = await postApi(
          "/view_user_profile",
          {
            company_id: this.companyId,
          },
          {}
        );
        try {
          if (response.errorCode == "0") {
            console.log(response.data);
            if(response.data.gst_composite_scheme=="0")
            {
            this.off="true"
            }
            else
            {
            this.on="true"
            }
         this.gstin_text=response.data.gstin_text;
               this.company_name=response.data.company_name;
               this.address=response.data.address;
              this.pincode=response.data.pincode;
              this.gst_composite_scheme=response.data.gst_composite_scheme;
              this.email=response.data.email;
              this.mobile=response.data.mobile;
              this.business_type=response.data.business_type;
              this.entity_type=response.data.entity_type;
              this.cin=response.data.cin;         
              this.pan_card=response.data.pan_card;
              this.website=response.data.website;
              this.license=response.data.license;
              this.license_number=response.data.license_number;
              this.profile_img=response.data.company_logo;
  
         return false;
          } 
        } catch (err) {
          alert(err);
        }
   },
    }
  }
  </script>
  
  
  <style scoped>
  .orange{
    background:#ff8300;
  }
  .field input {
      background: #fff;
  }
  </style>>
  