<template>

    <CommonHeader />
     <!--*************alert-->
  
</template>

<script>
import CommonHeader from './CommonHeader.vue'

export default {
    name: 'ViewList',
    components: {
        CommonHeader
    }

}
</script>

<style>

</style>
