<template>
    <CommonHeader />
    <div id="main-content">
        <div id="page-container">
            <h1 class="hed">Company Name </h1>
            <router-link to="/ViewDetails" style="color:white" class="back_link"><i class="fa fa-angle-double-left" aria-hidden="true"></i> Back </router-link>
            <div class="clear"></div>
            <div class="wrap1">
            <h1 class="head">
                <span class="tab selected" @click="bgcolor(1)" id="all">All</span>
                <span class="tab" @click="bgcolor(2)" id="filed">Filed</span>
                <span class="tab" @click="bgcolor(3)" id="not_filed">Not Filed</span>
                <select v-model="month" class="wi" id="Month">
                    <option value="">Month</option>
                    <option value="01">January</option>
                    <option value="02">February</option>
                    <option value="03">March</option>
                    <option value="04">April</option>
                    <option value="05">May</option>
                    <option value="06">June</option>
                    <option value="07">July</option>
                    <option value="08">August</option>
                    <option value="09">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                    <option value="13">Jan-Mar</option>
                    <option value="14">Apr-June</option>
                    <option value="15">July-Sept</option>
                    <option value="16">Oct-Dec</option>
                   </select>
                <select v-model="year" class="wi" id="year">
                    <option value="2015">2015</option>
                    <option value="2016">2016</option>
                    <option value="2017">2017</option>
                    <option value="2018">2018</option>
                    <option value="2019">2019</option>
                    <option value="2020">2020</option>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    
                </select>
                <span class="new_search_button" type="submit" @click="get_gst_return">Submit <img src="https://instabill.co/public/front/images/loader.gif"
                class="load_show"
                v-if="loader"
                style="width: 15px;"
              /></span>

                <span @click="gst_return_excel"><img style="cursor:pointer;vertical-align: bottom;padding-left: 10px;" src="https://instabill.co/public/images/xls.png" alt=""> <img src="https://instabill.co/public/front/images/loader.gif"
                class="load_show"
                v-if="loader1"
                style="width: 15px;"
              /></span>
                   
                <div class="clearfix"></div>
            </h1>
    
            <div class="search search_gst" style=""><input id="myInput" type="text" placeholder="Search.." v-model="search" > <button style="padding:0;border:none;border-radius:10px;float: right;"><i class="fa fa-search" aria-hidden="true"></i></button></div>
        </div>
            <table class="list-box">
                <tr class="list">
                    <th>Period</th>
                    <th>Type of GST Return</th>
                    <th>ARN</th>
                    <th>Status</th>
                    <th style="    width: 21%;">Action</th>
                    
                    
                </tr>
                <tr>
                    <td>May 2024</td>
                    <td></td>
                    <td></td>
                   
                    <td></td>
                    <td>
                        <button class="amt"><i class="fa fa-download" aria-hidden="true" data-v-ce39f946=""></i> Download JSON</button>
                        <button class="amt">GST Filling</button>
                    </td>
                    
                </tr>
            </table>
        </div>
        </div>
        
      

     <div class="sidebar_gst_filling" style="display: ;">
        <p class="head_sb">GSTR-1 May 2024</p>
    <span class="closed_open_m1"><i class="fa fa-times" aria-hidden="true" data-v-ce39f946=""></i></span>
       
        <form>
            <input type="text" placeholder="Enter OTP">
            <a href="" class="resend_otp">Resend OTP</a>
            <div class="clear"></div>
            <button type="submit">Submit <img src="https://instabill.co/public/front/images/loader.gif"
                                        class="load_show1"
                                        style="width: 15px;"
                                      /></button>
        </form>
     </div>

        <!--*******download_excel_sidebar end*****-->
        <div class="gstr_json_sidebar" v-if="isModalVisible3">
                <p class="head_sb">GSTR JSON <span aria-hidden="" class="closed_open_m" v-on:click="closeModal3()"><i class="fa fa-times" aria-hidden="true"></i></span></p>
    
                <div class="select_da">
                    <select name="month" id="month" v-model="month">
                       <option value="01">January</option>
                        <option value="02">February</option>
                        <option value="03">March</option>
                        <option value="04">April</option>
                        <option value="05">May</option>
                        <option value="06">June</option>
                        <option value="07">July</option>
                        <option value="08">August</option>
                        <option value="09">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                        <option value="13">Quarter1</option>
                        <option value="14">Quarter2</option>
                        <option value="15">Quarter3</option>
                        <option value="16">Quarter4</option>
                        <option value="1">Apr-June</option>
                        <option value="2">Jul-Sep</option>
                        <option value="3">Oct-Dec</option>
                        <option value="4">Jan-Mar</option>
                  </select>
                    <select name="year" id="year" v-model="year">
                        <option value="2008">2008</option>
                        <option value="2009">2009</option>
                        <option value="2010">2010</option>
                        <option value="2011">2011</option>
                        <option value="2012">2012</option>
                        <option value="2013">2013</option>
                        <option value="2014">2014</option>
                        <option value="2015">2015</option>
                        <option value="2016">2016</option>
                        <option value="2017">2017</option>
                        <option value="2018">2018</option>
                        <option value="2019">2019</option>
                        <option value="2020">2020</option>
                        <option value="2021">2021</option>
                        <option value="2022">2022</option>
                        <option value="2023">2023</option>
                        <option value="2024">2024</option>
                        <option value="2025">2025</option>
                        
                    </select>
                    <span class="new_search_button" ><i class="fa fa-search "></i></span>
                    <div class="clearfix"></div>
                </div>
    
                <div class="ac_detail" v-if="gstr">
                    <div class="left-a">
                        <p><b>GSTR -1 </b></p>
                    </div>
                    <p class="down" v-on:click="gstr1Download"><i class="fa fa-download " aria-hidden="true"></i></p>
                </div>
    
                <div class="ac_detail" v-if="gstr">
                    <div class="left-a">
                        <p><b>GSTR -3B </b></p>
                    </div>
                    <p class="down" v-on:click="gstr3bDownload"><i class="fa fa-download " aria-hidden="true"></i></p>
                </div>
                
                    <div class="ac_detail" v-if="cmp">
                    <div class="left-a">
                        <p><b>CMP -08 </b></p>
                    </div>
                    <p class="down" v-on:click="cmp08Download"><i class="fa fa-download" aria-hidden="true"></i></p>
                </div>
               
            </div>
    

  <!--*******download_excel_sidebar end*****-->
   </template>
   <script>
    import CommonHeader from './CommonHeader.vue';
   export default {
     name: "Companydetails",
     components: {
            CommonHeader
        },
     data() {
       return {
       };
     },
  
    
   };
   </script>
   <style>
   .sidebar_gst_filling {
    float: right;
    z-index: 9999;
    position: fixed;
    right: 0;
    top: 0px;
    background: white;
    height: 100%;
    transition: 1s;
    border: 1px solid #d4d7dc;
    border-right: none;
    border-top: none;
    box-shadow: 0px 3px 21px 0px rgb(0 0 0 / 20%);
    width: 350px;
    padding: 15px;
}
   .amt{    border: 0px;
    margin-left: 2%;
    padding: 1% 2%;}
   .success {
       position: fixed;
       top: 0%;
       z-index: 999;
       width: 20%;
       padding: 1%;
       background: #fff;
       box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
       border-radius: 5px;
       right: 0;
       border-left: 4px solid #ffae00;
       transition: 1s;
   }
   img.load_show1 {
    display: none;
}
   a.resend_otp {
    display: inline-block;
    font-size: 12px;float: right;
    margin-bottom: 5%;
    border-bottom: 1px solid blue;
}
   .head_sb {line-height: 23px;
}
   .gstr_json_sidebar span.new_search_button {
    float: right;
    margin-right: 5%;
}
a.back_link {
    float: right;
    color: #000 !important;
    margin-top: 13px;
    font-size: 14px;
}
span.closed_open_m1 {
    position: absolute;
    right: 5%;
    top: 3%;
    color: #000;
    cursor: pointer;
}
   .wi {
    width: 18% !important;
    padding: 7px 15px !important;
    background: #fff;
    color: #000 !important;
    margin-left: 1%;
    font-size: 15px;
    box-shadow: 0px 5px 25px -9px rgb(2 62 83 / 67%);
    margin-top: 1%;
    display: inline-block;
    border: 1px solid #dfe0e3 !important;
    transition: 0.5s;
    cursor: pointer;
    border-radius: 5px !important;
    height: auto;
}

span.new_search_button {
    color: #f6f7fb;
    font-size: 14px;
    background: linear-gradient(85deg, #f66a59, #ffae00);
    padding: 7px 8px;
    border-radius: 5px;
    margin-top: 5px;
    display: inline-block;
    margin-left: 1%;
}
div#page-container h1 {
    padding-left: 0px;
    margin-left: 0px;
}
input#myInput {
    border: 1px solid #d6d0d0 !important;
    padding: 2%;
    margin: 0 !important;
    border-radius: 5px !important;
}
table.list-box {
    width: 100%!important}
.search.search_gst {margin-right: 0%;
    width: 25% !important;
    float: right;
}

.search.search_gst button i {
    background: linear-gradient(85deg, #f66a59, #ffae00);
    padding: 10px !important;
    color: white;
    border-radius: 3px !important;
}
   .wi {
    width: 18% !important;
    padding: 7px 15px !important;
    background: #fff;
    color: #000 !important;
    margin-left: 1%;
    font-size: 15px;
    box-shadow: 0px 5px 25px -9px rgb(2 62 83 / 67%);
    margin-top: 1%;
    display: inline-block;
    border: 1px solid #dfe0e3 !important;
    transition: 0.5s;
    cursor: pointer;
    border-radius: 5px !important;
    height: auto;
}
   .sidebar_gst_filling {
    display: none;
    float: right;
    z-index: 9999;
    position: fixed;
    right: 0;
    top: 0px;
    background: white;
    height: 100%;
    transition: 1s;
    border: 1px solid #d4d7dc;
    border-right: none;
    border-top: none;
    box-shadow: 0px 3px 21px 0px rgb(0 0 0 / 20%);
    width: 350px;
    padding: 15px;
}

.sidebar_gst_filling input {
    width: 100%;
    border: 1px solid #eee;
    padding: 3%;
    font-size: 14px;
    margin-bottom: 5%;
}

.sidebar_gst_filling button {
    background: linear-gradient(to top left, #ffae00 0%, #f66a59 100%) !important;
    border-color: #fea40d;
    color: #fff;
    border: 0px;
    padding: 3% 6%;
    border-radius: 5px;
}
   p.txt {
       width: 91%;
       float: left;
       margin: 0;
   }
   
   .error_msg {
       position: fixed;
       top: 0%;
       z-index: 999;
       width: 20%;
       padding: 1%;
       background: #fff;
       box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
       border-radius: 5px;
       right: 0;
       border-left: 4px solid #db0014;
       transition: 1s;
       color: red;
   }
   </style>