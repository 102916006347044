<template>
 <!--*************alert-->
    <div :class="alertClass" id="hidemessage" v-if="message">
        <p class="txt">{{alertMessage}}</p>
        <i class="fa fa-times close_msg" aria-hidden="true" @click="close_msg()"></i>
    </div>
    <!--*************alert-->
  <div class="sign_page">
    <div class="sign_left">
      <img src="https://instabill.co/public/ca_images/sign.png" style="width: 65%;" />
      <p style="font-size: 19px;">
        Login to manage client GST filing, connect Tally, Instabill Referral
        Earning and many more...
      </p>
    </div>
    <div class="sign_right">
      <div class="inner_r">
        <!-- <img src="https://instabill.co/public/INSTABILL.svg" /> -->
        <img src="https://instabill.co/public/instabillpro_logo.png" />
        <h2>Sign In</h2>
        <form v-on:submit.prevent="login">
          <div class="box_in">
            <label>Email</label><br />
            <input type="text" placeholder="Enter Email ID" v-model="email" />
          </div>
          <div class="box_in">
            <label>Password</label><br />
            <input
              v-if="showPassword"
              type="text"
              class="input"
              v-model="password"
            />
            <input  v-else
              type="password"
              placeholder="Enter Password"
              class="input"
              v-model="password"
            />
            <span class="icon is-small is-right" @click="toggleShow">
              <i
                class="fa"
                :class="{
                  'fa-eye-slash': showPassword,
                  'fa-eye': !showPassword,
                }"
              ></i>
            </span>
          </div>

          <div class="box_in">
            <div class="fxt-checkbox-area">
              <div class="checkbox">
                <input id="checkbox1" type="checkbox" />
                <label for="checkbox1">Keep me logged in</label>
              </div>
            </div>
<router-link to="/forgetPassword" class="switcher-text" > Forgot Password </router-link>
          </div>
          <div class="box_in">
            <button type="submit">
              Login
              <img
                src="https://instabill.co/public/front/images/loader.gif"
                class="load_show"
                v-if="loader"
                style="width: 15px;"
              />
            </button>
            <p style="margin-top:10px">
              Don't have an account?
              <router-link to="/signup">Sign Up </router-link>
            </p>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { checkEmail, postApi } from "../assets/js/helper.js";
export default {
  name: "signIn",
  data() {
    return {
      showPassword: false,
      password: "",
      loader: false,
      email: "",
       message: false,
            alertMessage: "",
            alertClass: "",
    };
  },
beforeCreate(){
var checker=localStorage.getItem('admin_id');
  if(checker ==null)
  {
    console.log("signin page");
  }
  else
  {
    this.$router.push("/Dashboard");
  }
}
  ,
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    async login() {
      if (this.email == "") {
        this.alertMessage = "Please Enter Email ID";
                this.alertClass = "error_msg";
                this.message = true;
                setTimeout(() => this.message = false, 1000);
      
        return false;
      }

      //check email is valid
      if (!checkEmail(this.email)) {
         this.alertMessage = "Please enter valid email";
                this.alertClass = "error_msg";
                this.message = true;
                setTimeout(() => this.message = false, 1000);

     
        return false;
      }

      if (this.password == "") {
         this.alertMessage = "Please Enter Password";
                this.alertClass = "error_msg";
                this.message = true;
                setTimeout(() => this.message = false, 1000);
   
        return false;
      }

      if (this.email != "" || this.password != "") {
        this.loader = true;
      }

      const response = await postApi(
        "/ca_login",
        {
          email: this.email,
          password: this.password,
        },
        {}
      );
      try {
        if (response.errorCode == "0") {
          console.log(response.data);
          if (response.data.account_status == "0") {
            this.loader = false;
            this.alertMessage = "Your Account is Under Approval";
                this.alertClass = "success";
                this.message = true;
                setTimeout(() => this.message = false, 1000);

          
          } else {
            localStorage.setItem('admin_id',response.data.id);
            localStorage.setItem('admin_name',response.data.name);
            
            this.$router.push("/Dashboard");
          }
          return false;
        } else {
          this.loader = false;
            this.alertMessage = response.errorMsg;
                this.alertClass = "error_msg";
                this.message = true;
                setTimeout(() => this.message = false, 1000);

         
        }
      } catch (err) {
        alert(err);
      }
    },
     close_msg() {
             this.message = false;
        },
  },
};
</script>
<style>
.success {
    position: fixed;
    top: 0%;
    z-index: 999;
    width: 20%;
    padding: 1%;
    background: #fff;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    border-radius: 5px;
    right: 0;
    border-left: 4px solid #ffae00;
    transition: 1s;
}

p.txt {
    width: 91%;
    float: left;
    margin: 0;
}

.error_msg {
    position: fixed;
    top: 0%;
    z-index: 999;
    width: 20%;
    padding: 1%;
    background: #fff;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    border-radius: 5px;
    right: 0;
    border-left: 4px solid #db0014;
    transition: 1s;
    color: red;
}
</style>