<template>
     <!--*************alert red-->
     <div :class="alertClass" id="hidemessage" v-if="message">
        <p class="txt">{{alertMessage}}</p>
        <i class="fa fa-times close_msg" aria-hidden="true" @click="close_msg()"></i>
    </div>
    <!--*************alert red-->
      <!--*************alert green-->
      <div :class="alertClassg" id="hidemessageg" v-if="messageg">
        <p class="txt">{{alertMessageg}}</p>
        <i class="fa fa-times close_msgg" aria-hidden="true" @click="close_msgg()"></i>
    </div>
    <!--*************alert green-->
    <CommonHeader />
     <div id="main-content">
        <div id="page-container">
            <!--**************************common**************-->
                <h1 class="hed" style="margin-left: 0%;">Banking</h1>

                <button class="back_user_1 btn" @click="goBack" style="padding: 5px 1%;"><i class="fa fa-angle-double-left" aria-hidden="true"></i> Back</button>

                <div class="clear"></div>
            <div class="bank_Sec">
            <div class="banktab">
    
                <div class="" style="">
                <div class="card card-statistics" style="">
                  <div class="card-body">
                    <div class="d-flex flex-column flex-md-row align-items-center justify-content-between" style="width:90%; margin-bottom: -20px;">
                      <!-- <div class="statistics-item" style="min-width:60%;">
                          <div class="row">
                              <div class="col-6">
                              </div>
                          </div>
                          <p style="font-size:16px; margin-left:0px;">  {{this.bank_name}} - {{this.ifsc_code}}</p>
                        <p style="font-size:16px; margin-left:0px;"> A/C Number : {{this.account_no}}</p>
                        <h3><i class="fa fa-rupee" style="font-size:2rem;"></i>
                       {{this.bank_balance}}            
                        </h3>
                        <label class="badge badge-outline-success badge-pill" style="margin-top: 6px;">Bank Balance<span id="revenue_last"></span> </label>
           <p style="font-size: 9px;padding-top: 19px;padding-bottom: 3%;">Last Updated on  {{this.created_at}} 
        </p>
      
                      </div> -->
                      <div class="statistics-item" style="margin-top: 0px; min-width:100%; padding-left:6px;">
                          <div class="row">
                              <div class="col-12">
                                <p style="margin-bottom: 0px;
                                text-transform: uppercase;">{{bank_name}}</p>
                                <p>A/C Number : {{account_no}}</p>
                                
                              </div>
                            
                          </div>  
                          <div>
                         <h3 style=" font-size:2rem;"><i class="fa fa-rupee"></i> <span id="instabill_balance">{{this.instabill_balance}}</span></h3>
                        <label class="badge badge-outline-success badge-pill">Pending Entry </label>
                        <label class="badge badge-outline-success badge-pill"><span id="pending_entry">{{this.pending_entry}}</span></label></div><br>
                      
                       <p style="font-size:10px;">Opening Balance <i class="fa fa-inr" aria-hidden="true">  <span id="open_bal">{{this.opening_balance}}</span></i>
                        <span class="edit_s"  @click="opening_bal_sidebar()"> Edit</span>
                            </p>
                        
                      </div>
                      
                    </div>
                  </div>
                </div>
            </div>
                </div>
    
                <div class="banking_right">
              
              <div style="display:inline; float:right;">
               <div class="row headbank">
                   
                <div class="col-6">
                 <div>
                  
                  <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
                      
                  <button type="button" id="btnGroupDrop1" class="btn tn btn-outline-warning btn-rounded dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Switch Bank Account</button>
    
                    <div class="btn-group" role="group">
        <div class="dropdown-menu   drop-add-css1 " aria-labelledby="">
               
         <div  v-for="(accounts,index) in userAccountList"  :key="index">
          
            

            <div style="padding-top:15px;padding-left:15px;padding-bottom:15px; padding-right:15px; border-bottom:0.1px solid #DEDEDE;">
             
                <router-link
              :to="{
                name: 'ViewBankstatements',
                params: {
                  user_id: this.user_id,
                  company_id: this.company_id,
                  bank_id: accounts.id,
                },
              }"
              >
                <div class="" style="" >
                          
                        <span style="width:100%;" class="bank_N">   {{accounts.bank_name}} </span>
                      <div class="bank_list">
        
                     
                        <div class="details">
                           <span style="color:#808080;"> A/C Number:  {{accounts.account_no}}</span>
                      </div>
                     
                      <div class="details">
                        <span> <i class="fa fa-check" aria-hidden="true" style="color:#41DA39; font-size:11px;">Verified</i></span>
                          </div>
        
                      </div>
        
                       <div class="bank_list">
                        <div class="details">
                           <span style="color: #808080;">Bank Balance: {{accounts.bank_balance}}  </span>
                        </div>
                         <div class="details" style="color: #808080; font-size:10px; padding-top:5px;">
                             <span>Last Updated  {{accounts.created_at}} </span>
                         </div>
                         </div>
                  </div>
                </router-link>
              </div>
            

            </div>
    
<!-- end for loop -->

                      </div>
       
      </div>
    </div>
        </div>
        </div>
    
    </div>
    
    </div>
    </div>
            </div>
    
    <div class="filter">
    <ul>
    <li style="width: 25%;">
        <datepicker v-model="date" inputFormat="dd-MM-yyyy" id="start_date" class="cal1"></datepicker>
        <span style="    float: left;
        width: 10%;
        text-align: center;
        margin-top: 2%;
        margin-left: -4px;">To</span>
        <datepicker v-model="date2" inputFormat="dd-MM-yyyy" id="end_date" class="cal1"></datepicker>
                    
    </li>
    <li>
    <input type="button" value="Submit" @click="get_account_statement()" class="btn btn-success orange_Color">
    </li>
    
    <li>
        <div class="btn-group btn-group_map">
            <button type="button" class="btn  btn-rounded active" @click="MapUnmapCheck('0')" id="first" style="background: linear-gradient(85deg, rgb(246, 106, 89), rgb(255, 174, 0)); color: rgb(255, 255, 255); border: 1px solid rgb(246, 106, 89);">Unmap</button>
           <button type="button" class="btn btn-rounded"  @click="MapUnmapCheck('1')" id="second"  style="background-color:#ffffff;color:#ffae00; border:1px solid #f66a59;">Map</button>
    </div>
    </li>
    <li style=" 
    display: flex;
    align-items: center;
    justify-content: center;">

        <div v-if="checkMapUnmap == '0'">
        <button type="button" class="btn btn-success orange_Color" @click="add_multiside()" v-if="showcheckboxmap">Map Entry</button>
        </div>
   
    <div v-if="checkMapUnmap == '1'">
        <button type="button" class="btn btn-success orange_Color" @click="edit_multiside()" v-if="showcheckboxmap">Edit Entry</button>
    </div>


    <div v-if="checkDeleteEntry == '1'">
        <button type="button" class="btn btn-success orange_Color" @click="delete_multiside()" v-if="checkDeleteEntry">Delete Entry</button>
    </div>

    
    </li>

    <li style="float: right;
        margin-right: 10px;">
    <div class="search searcha" style="">
        <!--  -->
     <input type="text" placeholder="Search.." @keyup="performSearch(this)"  v-model="search" >
    <button class="new_search_button">
    <i class="fa fa-search " style="background:linear-gradient(85deg, #f66a59, #ffae00); padding:10px;color:white;border-radius:10px;"></i></button>
    </div>
    </li>

    </ul>
    </div>
    
                <table class="list-box" style="width: 99% !important;">
                    <tr class="list">
                       <th> <input type="checkbox" v-model="select_all" @click="select" class="selectall">  </th>
                    <!-- <th></th> -->
                        <th>Date</th>
                        <th>Description</th>
                        <th>Tally Sync</th>
                        <th>Amount</th>
                        <th>In/Out</th>
                        <th style="width: 13%;text-align:right;">Action</th>
                    </tr>
                    <tbody>
                       
                        <tr v-for="(statement,index) in userAccountStatement"  :key="index">
                            <td  ><input type="checkbox" v-model="checkboxs" :value="statement.id" @change="checkCheckboxselection" class="selectbox" ></td>
                            <td>{{statement.VALUEDATE}}</td>
                            <td>{{statement.REMARKS}}</td>
                            <td>
                                <button class="sync" v-if="statement.tally_import == 1">Sync</button>
                                <button class="unsync" v-else>Unsync</button>
                            </td>
                            <td>{{statement.AMOUNT}}</td>
                            <td > 
                                <span v-if="statement.TYPE == 'CR'">In</span>
                                <span v-else>Out</span>
                            </td>
                            <td style="width: 17%;text-align:right;">
                                <button type="button" class="btn btn-success orange_Color" style="margin-right: 5%;padding: 0px 9px !important;height: 30px;border-radius: 5px !important;" @click="add_side(statement.id)"  v-if="this.checkMapUnmap == '0'"> Add </button>
                                <button type="button" class="btn btn-success orange_Color" style="margin-right: 5%;padding: 0px 9px !important;height: 30px;border-radius: 5px !important;" @click="edit_side(statement.id)" v-else> Edit </button>
                                <button type="button" class="btn btn-success orange_Color" style="margin-right: 5%;padding: 0px 9px !important;height: 30px;border-radius: 5px !important;" @click="delete_entry(statement.id)"> Delete </button> 
                            </td>
                        </tr>
        
    
                </tbody>
                </table>
        </div>
     </div>
    
     <!--sidebar open-->
     <div id="setting2" class="setting gst-side" style="display: block; overflow-y: auto;" v-if="setting2">
            
      <form action="https://instabill.co/import_otherbank_header" method="post" enctype="multipart/form-data">         
            
    <div style="margin-bottom:20px; " class="">
    <div class="search" align="" style="color:#cccc;float:left;margin-bottom:5px; margin-top:7px;    width: 92%;">
        <div class="add-gst" style="margin-bottom: 0px;"><h3>ACCOUNT details</h3>
        <div></div>
       
        </div>
        
         <div class="gstIn" style="margin-bottom:10px;">
        
      <label style="color: #000;margin-bottom: 5px;font-weight: 600;" for="other_account_no">ACCOUNT NUMBER*</label><br>
      <input style="border: 1px solid gray; padding: 7px;border-radius: 5px;" type="text" id="other_account_no" name="other_account_no" placeholder="ACCOUNT NUMBER*" required=""><br>
    
         </div>
             <div class="gstIn-corporate" style="margin-bottom:10px;">
         
      <label style="color: #000;margin-bottom: 5px;font-weight: 600;" for="other_corporate_id">Bank Name*</label><br>
      <input style="border: 1px solid gray; padding: 7px;border-radius: 5px;" type="text" id="other_corporate_id" name="other_corporate_id" placeholder="BANK NAME*" required=""><br>
    
         </div>
         
         <div class="gst-user" style="margin-bottom:10px;">
       
              <label style="color: #000;margin-bottom: 5px;font-weight: 600;" for="other_bank_user_id">IFSC Code*</label><br>
              <input style="border: 1px solid gray; padding: 7px;border-radius: 5px;" type="text" id="other_bank_user_id" name="other_bank_user_id" placeholder="IFSC Code*" required="">
            
         </div>
         
        <div class="gst-user" style="margin-bottom:10px;">
                <label style="color: #000;margin-bottom: 5px;font-weight: 600;" for="bank_user_id">IMPORT DOCUMENT*</label>
                <div class="panel panel-default" style="">
     
       <div id="_2_1" class="uploader_purchase"> 
      
         <input id="file-upload_1" type="file" name="filePurchaseUpload" required="" accept=".csv">
    
         <label for="file-upload_1" id="file-drag_1">
           <img id="file-image_1" src="#" alt="" class="hidden1">
           <div id="start1">
             <i class="fa fa-download" aria-hidden="true"></i>
             <div>Browse a file </div>
             <div id="notimage_1" class="hidden1" style="color :red">Please select an CSV file format</div>
             <span id="file-upload-btn" class="btn btn-primary">Browse</span>
           </div>
           <div id="response1" class="hidden1">
             <div id="messages1"></div>
             <progress class="progress1" id="file-progress_1" value="0">
               <span>0</span>%
             </progress>
           </div>
         </label>
    
         <div class="form-group" style="display: none;">
    
           <input type="checkbox" name="header" checked=""> File contains header row?
    
         </div>
    </div>
    
                </div>
                <div class="clear"></div>
                <div class="gst-user" style="margin-bottom:10px;">
                    <button type="submit" class="btn btn-success account_submit">SUBMIT</button>
         
                </div>
       
          </div>
      <button type="button" class="close" @click="setting_close1" id="setting_close1" data-dismiss="alert" style="top: 0;position: absolute;top:10px;right: 15px;font-size: 2rem;">
      <span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
         </div>
        </div>
        <!--*********************other bank modal end************-->
       </form> 
                 
    </div>
    
    
     <!--Add unmap Entry -->
     <div id="setting2"  class="setting gst-side" style="display: block; overflow-y: auto;" v-if="isadd">
        <form v-on:submit.prevent="add_unmap_entry" method="post">  
  
        <div class="search" align="" style="color:#cccc;float:left;margin-bottom:5px; margin-top:7px;    width: 92%;">
          <div class="add-gst" style="margin-bottom: 0px;"><h3>RECORD TRANSACTION</h3>
               <div></div>
          </div>
           <div class="gstIn" style="margin-bottom:10px;">     
        <label style="color: #000;margin-bottom: 5px;font-weight: 600;" for="other_account_no">Party Type*</label><br>
        <select name="party_type" required @change="select_party_type()" v-model="party_type" placeholder="Party Type" class="add_width">
            <option value="">Select Party Type</option>
            <option value="0">Customer </option>
            <option value="1">Vendor </option>
            <option value="2">Employee </option>
            <option value="3">Others </option>
            </select>
      
           </div>
              

           
           <div class="gstIn-corporate" style="margin-bottom:10px;">
           
        <label style="color: #000;margin-bottom: 5px;font-weight: 600;" for="other_corporate_id">Party Name*</label><br>
        <input list="p_name" v-model="party_name" required="" placeholder="Party Name" class="add_width"> 
    <datalist id="p_name"  v-if="this.party_type == 0 ">
    <option v-for="(partyname,index) in partynamearr"  :key="index"> {{partyname.company_name}}</option>
    </datalist>
    <datalist id="p_name" v-else>
        <option v-for="(partyname,index) in partynamearr"  :key="index"> {{partyname.vendor_name}}</option>
        </datalist>
    
           </div>
           
           <div class="gst-user" style="margin-bottom:10px;">
                <label style="color: #000;margin-bottom: 5px;font-weight: 600;" for="other_bank_user_id">Description (OPTIONAL)</label><br>
                <textarea placeholder="Provide nature of expense, income, advance, or any other nature of transaction" id="memo" v-model="memo" rows="5" cols="25" class="add_width"></textarea>
           </div>
    
    
           <div class="clear"></div>
                <div class="gst-user" style="margin-bottom:10px;">
                    <button type="submit" class="btn btn-success account_submit">SUBMIT</button>
         
                </div>
    
        <button type="button" class="close" @click="isadd_close1" id="isadd_close1" data-dismiss="alert" style="top: 0;position: absolute;top:10px;right: 15px;font-size: 2rem;">
        <span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
           </div>
     
          <!--*********************other bank modal end************-->
        </form>    
    
      </div>
    
       <!--Edit map Entry -->
     <div id="setting2"  class="setting gst-side" style="display: block; overflow-y: auto;" v-if="isedit">
        <form v-on:submit.prevent="edit_unmap_entry" method="post">  
      <div style="" class="">
      <div class="search" align="" style="color:#cccc;float:left;margin-bottom:5px; margin-top:7px;    width: 92%;">
          <div class="add-gst" style="margin-bottom: 0px;"><h3>EDIT RECORD TRANSACTION</h3>
               <div></div>
          </div>
           <div class="gstIn" style="margin-bottom:10px;">     
        <label style="color: #000;margin-bottom: 5px;font-weight: 600;" for="other_account_no">Party Type*</label><br>
        <select name="party_type" required @change="select_party_type()" v-model="party_type" placeholder="Party Type" class="add_width">
            <option value="">Select Party Type</option>
            <option value="0">Customer</option>
            <option value="1">Vendor</option>
            <option value="2">Employee</option>
            <option value="3">Others </option>
            </select>
      
           </div>
              
           <div class="gstIn-corporate" style="margin-bottom:10px;">
           
        <label style="color: #000;margin-bottom: 5px;font-weight: 600;" for="other_corporate_id">Party Name*</label><br>
        <input list="p_name" v-model="party_name" required="" placeholder="Party Name" class="add_width"> 
    <datalist id="p_name"  v-if="this.party_type == 0 ">
    <option v-for="(partyname,index) in partynamearr"  :key="index"> {{partyname.company_name}}</option>
    </datalist>
    <datalist id="p_name" v-else>
        <option v-for="(partyname,index) in partynamearr"  :key="index"> {{partyname.vendor_name}}</option>
        </datalist>
    
           </div>
           
           <div class="gst-user" style="margin-bottom:10px;">
                <label style="color: #000;margin-bottom: 5px;font-weight: 600;" for="other_bank_user_id">Description (OPTIONAL)</label><br>
                <textarea placeholder="Provide nature of expense, income, advance, or any other nature of transaction" id="memo" v-model="memo" rows="5" cols="25" class="add_width"></textarea>
           </div>
    
    
           <div class="clear"></div>
                <div class="gst-user" style="margin-bottom:10px;">
                    <button type="submit" class="btn btn-success account_submit">SUBMIT</button>
         
                </div>
    
        <button type="button" class="close" @click="isedit_close1" id="isedit_close1" data-dismiss="alert" style="top: 0;position: absolute;top:10px;right: 15px;font-size: 2rem;">
        <span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
           </div>
        </div>
          <!--*********************other bank modal end************-->
        </form>    
    
      </div>
    
    
       <!--Add unmap Multi Entry -->
     <div id="setting2"  class="setting gst-side" style="display: block; overflow-y: auto;" v-if="isaddmulti">
        <form v-on:submit.prevent="add_unmap_multientry" method="post">  
      <div style="" class="">
      <div class="search" align="" style="color:#cccc;float:left;margin-bottom:5px; margin-top:7px;    width: 92%;">
          <div class="add-gst" style="margin-bottom: 0px;"><h3>RECORD TRANSACTION</h3>
               <div></div>
          </div>
           <div class="gstIn" style="margin-bottom:10px;">     
        <label style="color: #000;margin-bottom: 5px;font-weight: 600;" for="other_account_no">Party Type*</label><br>
        <select name="party_type" required @change="select_party_type()" v-model="party_type" placeholder="Party Type" class="add_width">
            <option value="">Select Party Type</option>
            <option value="0">Customer</option>
            <option value="1">Vendor</option>
            <option value="2">Employee</option>
            <option value="3">Others</option>
            </select>
      
           </div>
              
           <div class="gstIn-corporate" style="margin-bottom:10px;">
           
        <label style="color: #000;margin-bottom: 5px;font-weight: 600;" for="other_corporate_id">Party Name*</label><br>
        <input list="p_name" v-model="party_name" required="" placeholder="Party Name" class="add_width"> 
    <datalist id="p_name"  v-if="this.party_type == 0 ">
    <option v-for="(partyname,index) in partynamearr"  :key="index"> {{partyname.company_name}}</option>
    </datalist>
    <datalist id="p_name" v-else>
        <option v-for="(partyname,index) in partynamearr"  :key="index"> {{partyname.vendor_name}}</option>
        </datalist>
    
           </div>
           
           <div class="gst-user" style="margin-bottom:10px;">
                <label style="color: #000;margin-bottom: 5px;font-weight: 600;" for="other_bank_user_id">Description (OPTIONAL)</label><br>
                <textarea placeholder="Provide nature of expense, income, advance, or any other nature of transaction" id="memo" v-model="memo" rows="5" cols="25" class="add_width"></textarea>
           </div>
    
    
           <div class="clear"></div>
                <div class="gst-user" style="margin-bottom:10px;">
                    <button type="submit" class="btn btn-success account_submit">SUBMIT</button>
         
                </div>
    
        <button type="button" class="close" @click="isadd_multiside_close1" id="isadd_multiside_close1" data-dismiss="alert" style="top: 0;position: absolute;top:10px;right: 15px;font-size: 2rem;">
        <span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
           </div>
        </div>
          <!--*********************other bank modal end************-->
        </form>    
    
      </div>
   
      <!--add opening ban balance sidebar-->
    <div id="opening_balance" class="setting gst-side form-width"  v-if="opening_balance_sidebar">
        <form v-on:submit.prevent="add_openbalance" method="post">  
        <div style="margin-bottom:20px; " class="">
    <div class="search" align="" style="color:#cccc;float:left;margin-bottom:5px; margin-top:7px;">
        <div class="add-gst" style="margin-bottom: 0px;"><h3>Add Opening Bank Balance</h3>
        </div>
         
         <div class="gstIn" style="margin-bottom:10px;">
      <label style="color:#000;margin-bottom: 5px;font-weight: 600;" for="p_name">Amount*</label>
       <input type="number" name="opening_amount" required v-model="open_bal"   placeholder="Enter Amount" style="border: 1px solid gray; padding: 7px;border-radius: 5px; width: 100%;">
         </div>
         
          <div class="gstIn" style="margin-bottom:10px;">
      <label style="color:#000;margin-bottom: 5px;font-weight: 600;" for="p_name">Date of Opening Balance*</label>
       <input type="date" name="opening_date" required id="opening_date" v-model="open_bal_date" placeholder="Enter Amount" style="border: 1px solid gray; padding: 7px;border-radius: 5px; width: 100%;">
         </div>
        <br>
         <button id="opening_balances1" class="btn btn-primary ">Cancel</button>
          <button class="verify-otp btn btn-primary" style="margin-left:20px;" type="submit">Save</button>
        
          </div>
         </div>
        </form>    
    <button type="button"  @click="close_opening_bal_sidebar" class="close" data-dismiss="alert" id="opening_balances2" style="    top: 0;
        position: absolute;
        top: 14px;
        right: 15px;
        font-size: 40px;
        font-weight: 100;"><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
    </div>
    

<!--edit map Multi Entry -->
<div id="setting2"  class="setting gst-side" style="display: block; overflow-y: auto;" v-if="iseditmulti">
    <form v-on:submit.prevent="edit_map_multientry" method="post">  
  <div style="" class="">
  <div class="search" align="" style="color:#cccc;float:left;margin-bottom:5px; margin-top:7px;    width: 92%;">
      <div class="add-gst" style="margin-bottom: 0px;"><h3>RECORD TRANSACTION</h3>
           <div></div>
      </div>
       <div class="gstIn" style="margin-bottom:10px;">     
    <label style="color: #000;margin-bottom: 5px;font-weight: 600;" for="other_account_no">Party Type*</label><br>
    <select name="party_type" required @change="select_party_type()" v-model="party_type" placeholder="Party Type" class="add_width">
        <option value="">Select Party Type</option>
        <option value="0">Customer</option>
        <option value="1">Vendor</option>
        <option value="2">Employee</option>
        <option value="3">Others</option>
        </select>
  
       </div>
          
       <div class="gstIn-corporate" style="margin-bottom:10px;">
       
    <label style="color: #000;margin-bottom: 5px;font-weight: 600;" for="other_corporate_id">Party Name*</label><br>
    <input list="p_name" v-model="party_name" required="" placeholder="Party Name" class="add_width"> 
<datalist id="p_name"  v-if="this.party_type == 0 ">
<option v-for="(partyname,index) in partynamearr"  :key="index"> {{partyname.company_name}}</option>
</datalist>
<datalist id="p_name" v-else>
    <option v-for="(partyname,index) in partynamearr"  :key="index"> {{partyname.vendor_name}}</option>
    </datalist>

       </div>
       
       <div class="gst-user" style="margin-bottom:10px;">
            <label style="color: #000;margin-bottom: 5px;font-weight: 600;" for="other_bank_user_id">Description (OPTIONAL)</label><br>
            <textarea placeholder="Provide nature of expense, income, advance, or any other nature of transaction" id="memo" v-model="memo" rows="5" cols="25" class="add_width"></textarea>
       </div>


       <div class="clear"></div>
            <div class="gst-user" style="margin-bottom:10px;">
                <button type="submit" class="btn btn-success account_submit">SUBMIT</button>
     
            </div>

    <button type="button" class="close" @click="isadd_multiside_close3" id="isadd_multiside_close3" data-dismiss="alert" style="top: 0;position: absolute;top:10px;right: 15px;font-size: 2rem;">
    <span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
       </div>
    </div>
      <!--*********************other bank modal end************-->
    </form>    

  </div>

    </template>
   

<script>
        import CommonHeader from './CommonHeader.vue';
        import datepicker from 'vue3-datepicker';
        import { postApi } from "../assets/js/helper.js";
        import axios from 'axios';
        import $ from 'jquery';
         export default {
             name: 'ViewBankstatement',
            components: {
                 datepicker,
                CommonHeader
            },
            props:["user_id","company_id"],
            data() {
                return {
                    date: new Date(new Date().getFullYear(),new Date().getMonth(),1),
                    date2: new Date(new Date().getFullYear(),new Date().getMonth()+1,0),
                    mltpl_btn: false,
                    setting2: false,
                    userAccountList:null,
                    bank_id:null,
                    bank_balance:0.00,
                    pending_entry:"xxx",
                    instabill_balance:"xxx",
                    opening_balance:"xxx",
                    opening_balance_date:null,
                    userAccountStatement:null,
                    account_no:"xxx",
                    created_at:"xxx",
                    checkboxs:[],
                    checkMapUnmap:0,
                    showcheckboxmap:false,
                    checkDeleteEntry:false,
                    isadd:false,
                    isaddmulti:false,
                    isedit:false,
                    iseditmulti:false,
                    opening_balance_sidebar:false,
                    party_type:"",
                    party_name:"",
                    partynamearr:null,
                    trans_id:"",
                    memo:"",
                    bank_name:"",
                    ifsc_code:"",
                    open_bal:"",
                    search:"",
                    alertClass: "",
                    message: false,
                    alertClassg: "",
                    messageg: false,
                    select_all:false
                }
            },
            created() {
                
                const reloaded = localStorage.getItem('reloaded2');
   if (reloaded !== 'true') {
       localStorage.setItem('reloaded2', 'true');
       location.reload();
   }
                this.getAccountList(this.user_id,this.company_id);  
            
            },
           
           
       
            methods: {
            
                select(){        
    this.checkboxs=[];


    if(this.search!=""){
    if(!this.select_all){

let result = this.userAccountStatement
  if (this.search){
    result = result.filter(event =>
      event.REMARKS.toLowerCase().includes(this.search.toLowerCase()) 
      )
  }

for(let i in result){
    this.checkboxs.push(result[i].id);
}
this.showcheckboxmap=true;
this.checkDeleteEntry=true;
    }else{
        this.checkDeleteEntry=false;
        this.showcheckboxmap=false;
    }

}else{
        if(!this.select_all){
for(let i in this.userAccountStatement){
    this.checkboxs.push(this.userAccountStatement[i].id);
}
this.showcheckboxmap=true;
this.checkDeleteEntry=true;
    }else{
        this.checkDeleteEntry=false;
        this.showcheckboxmap=false;
    }
}

   },

delete_multiside() {
    
    let text1 = "Are you sure you want to delete ? ";
    if (confirm(text1) == true) {

    let formData = new FormData();
              formData.append('ids', this.checkboxs);
               formData.append('bank_id', this.bank_id);
            
              formData.append('header', true);
          
              axios.post("https://instabill.co/api/multidelete_account_statement", formData, {
              headers: {
              },
            }).then((response) => {
             console.log(response);

             this.alertMessageg = response.data.errorMsg;
                this.alertClassg = "error_msgg";
                this.messageg = true;
                setTimeout(() => this.messageg = false, 3000);
      
      
    this.search="";
     if(this.checkMapUnmap == "0"){
                        this.get_account_statement();
                        this.showcheckboxmap=false;
                      this.checkDeleteEntry=false;
                    }else{
                        this.get_map_account_statement();
                        this.showcheckboxmap=true;
                   this.checkDeleteEntry=true;
                    }
      }).catch((error) => {
            console.log(error);
            });
        }else{
            console.log("nothing do");
        }

},
close_msg() {
             this.message = false;
        },
close_msgg() {
             this.messageg = false;
        },
    
   performSearch(){
 var value = (this.search).toLowerCase();

$("table tr").each(function(index) {
    if (index !== 0) {
        var id = $(this).children().text().toLowerCase()
        if (id.indexOf(value) < 0) {
           $(this).hide();
        }else {
            $(this).show();
        }
    }
 });
                },

                add_unmap_entry(){
            let formData = new FormData();
              formData.append('party_type', this.party_type);
              formData.append('party_name', this.party_name);
              formData.append('user_id', this.user_id);
              formData.append('bank_id', this.bank_id);
              formData.append('company_id', this.company_id);
              formData.append('trans_id', this.trans_id);
              formData.append('memo', this.memo);
              formData.append('header', true);
          
              axios.post("https://instabill.co/api/pro_transaction_bind", formData, {
              headers: {
              },
            }).then((response) => {
             console.log(response);
    
    if(response.data.errorCode=="1")
    {
        this.alertMessage = response.data.errorMsg;
                this.alertClass = "error_msg";
                this.message = true;
                this.select_all=false;
                setTimeout(() => this.message = false, 3000);
    
    }
    else{
        this.isadd = false;
        this.party_type ="";
        this.party_name ="";
        this.trans_id ="";
        this.memo ="";
        this.alertMessageg = response.data.errorMsg;
                this.alertClassg = "error_msgg";
                this.messageg = true;
                this.select_all=false;
                setTimeout(() => this.messageg = false, 3000);

     
        this.get_account_statement();
      
    }
         }).catch((error) => {
            console.log(error);
            });
          
          
            } ,
            async get_transaction_by_id(){
       const response = await postApi(
                "/get_transaction_by_id",
                {
                    id:this.trans_id,
                  bank_id: this.bank_id,   
                 },
                {}
              );
              try {
                this.party_name=response.party_name;
                this.party_type=response.party_type;
                this.memo=response.memo;
            this.select_party_type();
                return false;
              } catch (err) {
              alert(err);
              }
     
     
                },
                edit_unmap_entry(){
            let formData = new FormData();
              formData.append('party_type', this.party_type);
              formData.append('party_name', this.party_name);
              formData.append('user_id', this.user_id);
              formData.append('bank_id', this.bank_id);
            
              formData.append('company_id', this.company_id);
              formData.append('trans_id', this.trans_id);
              formData.append('memo', this.memo);
              formData.append('header', true);
          
              axios.post("https://instabill.co/api/pro_edit_transaction_bind", formData, {
              headers: {
              },
            }).then((response) => {
             console.log(response);
    
    if(response.data.errorCode=="1")
    {
    this.alertMessage = response.data.errorMsg;
                this.alertClass = "error_msg";
                this.message = true;
                setTimeout(() => this.message = false, 3000);
    }
    else{
        this.isedit = false;
        this.party_type ="";
        this.party_name ="";
        this.trans_id ="";
        this.memo ="";
        this.alertMessageg = response.data.errorMsg;
                this.alertClassg = "error_msgg";
                this.messageg = true;
                setTimeout(() => this.messageg = false, 3000);
        // this.get_map_account_statement();
      
    }
         }).catch((error) => {
            console.log(error);
            });
          
          
            } ,
            add_unmap_multientry(){
            let formData = new FormData();
              formData.append('party_type', this.party_type);
              formData.append('party_name', this.party_name);
              formData.append('user_id', this.user_id);
              formData.append('bank_id', this.bank_id);
            
              formData.append('company_id', this.company_id);
              formData.append('trans_ids', this.checkboxs);
              formData.append('memo', this.memo);
              formData.append('header', true);
          
              axios.post("https://instabill.co/api/pro_multitransaction_bind", formData, {
              headers: {
              },
            }).then((response) => {
             console.log(response);
    
    if(response.data.errorCode=="1")
    {
 
    this.alertMessage = response.data.errorMsg;
                this.alertClass = "error_msg";
                this.message = true;
                setTimeout(() => this.message = false, 3000);
    }
    else{
        this.isaddmulti = false;
        this.party_type ="";
        this.party_name ="";
        this.trans_id ="";
        this.memo ="";
        this.checkboxs =[];
        this.alertMessageg = response.data.errorMsg;
                this.alertClassg = "error_msgg";
                this.messageg = true;
                setTimeout(() => this.messageg = false, 3000);
      
        this.get_account_statement();
        this.showcheckboxmap=false;
        this.checkDeleteEntry=false;
    }

         }).catch((error) => {
            console.log(error);
            });
          
          
            } ,
            async select_party_type(){
       if(this.party_type == "0"){
        //call Customer api
        const response = await postApi(
                "/get_customer_test",
                {
                userId:this.user_id,
                company_id: this.company_id,    
                },
                {}
              );
              try {
                if (response.errorCode == "0") {
                    this.partynamearr=response.data;
                }else{
                   this.partynamearr=null;
                }
                return false;
              } catch (err) {
              alert(err);
              }
       }
       else if(this.party_type == "1"){
     //call Vendor api
     const response = await postApi(
                "/get_vendor",
                {
                userId:this.user_id,
                company_id: this.company_id,    
                },
                {}
              );
              try {
                if (response.errorCode == "0") {
                    this.partynamearr=null;
                    this.partynamearr=response.data;
                }else{
                   this.partynamearr=null;
                }
                return false;
              } catch (err) {
              alert(err);
              }
       }
       else{
    this.partynamearr=null;
       }
                },
                checkCheckboxselection(){
                  if(this.checkboxs.length == 0){
                      this.showcheckboxmap=false;
                      this.checkDeleteEntry=false;
                }else{
                   //Show Button Map 
                   this.showcheckboxmap=true;
                   this.checkDeleteEntry=true;
                 }
                    
    // var total=$('.selectbox').length;
    
    // var number=$('.selectbox:checked').length;
  

    // if(total == number)
    // {
    //  $('.selectall').prop('checked',true); 
    // }
    // else
    // {
    //  $('.selectall').prop('checked',false); 
    // }
    
                },
                add_openbalance(){
            let formData = new FormData();
              formData.append('user_id', this.user_id);
              formData.append('bank_id', this.bank_id);
            
              formData.append('company_id', this.company_id);
              formData.append('amount', this.open_bal);
              formData.append('date', this.open_bal_date);
              formData.append('header', true);
          
              axios.post("https://instabill.co/api/add_opening_detail", formData, {
              headers: {
              },
            }).then((response) => {
             console.log(response);
    
    if(response.data.errorCode=="1")
    {
        this.alertMessage = response.data.errorMsg;
                this.alertClass = "error_msg";
                this.message = true;
                setTimeout(() => this.message = false, 3000);

    }
    else{
        this.alertMessageg = response.data.errorMsg;
                this.alertClassg = "error_msgg";
                this.messageg = true;
                setTimeout(() => this.messageg = false, 3000);
        this.opening_balance_sidebar=false;
        this.getAccountDetail();
    }
         }).catch((error) => {
            console.log(error);
            });
          
          
            } ,
            edit_map_multientry(){
            let formData = new FormData();
              formData.append('party_type', this.party_type);
              formData.append('party_name', this.party_name);
              formData.append('user_id', this.user_id);
              formData.append('bank_id', this.bank_id);
            
              formData.append('company_id', this.company_id);
              formData.append('trans_ids', this.checkboxs);
              formData.append('memo', this.memo);
              formData.append('header', true);
          
              axios.post("https://instabill.co/api/pro_multiedittransaction_bind", formData, {
              headers: {
              },
            }).then((response) => {
             console.log(response);
    
    if(response.data.errorCode=="1")
    {
        this.alertMessage = response.data.errorMsg;
                this.alertClass = "error_msgg";
                this.message = true;
                setTimeout(() => this.message = false, 3000);
 
    }
    else{
        this.iseditmulti = false;
        this.party_type ="";
        this.party_name ="";
        this.trans_id ="";
        this.memo ="";
        this.checkboxs =[];
        this.alertMessageg = response.data.errorMsg;
                this.alertClassg = "error_msgg";
                this.messageg = true;
                setTimeout(() => this.messageg = false, 3000);
        this.get_map_account_statement();
        this.showcheckboxmap=false;
        this.checkDeleteEntry=false;
    }

   


         }).catch((error) => {
            console.log(error);
            });
          
          
            } ,
            
            delete_entry(id){
                let text = "Are you sure you want to delete ? ";
                if (confirm(text) == true) {

            let formData = new FormData();
              formData.append('id', id);
               formData.append('bank_id', this.bank_id);
            
              formData.append('header', true);
          
              axios.post("https://instabill.co/api/delete_account_statement", formData, {
              headers: {
              },
            }).then((response) => {
             console.log(response);
 
    this.alertMessageg = response.data.errorMsg;
                this.alertClassg = "error_msgg";
                this.messageg = true;
                setTimeout(() => this.messageg = false, 3000);
        if(this.checkMapUnmap == "0"){
                        this.get_account_statement();
                    }else{
                        this.get_map_account_statement();
                    }
      }).catch((error) => {
            console.log(error);
            });
          
        }
        else{
            console.log("nothing do");
        }

            } 
            
            ,

               //find which coloumn select ---->>>>  (Unmap / Map)
                MapUnmapCheck(checkMapUnmapid){
                   
                    this.checkMapUnmap=checkMapUnmapid;
                    if(checkMapUnmapid == "0"){
                        this.get_account_statement();
                    }else{
                        this.get_map_account_statement();
                    }
               
               
                },
                GFG_Fun_1() {
                    this.mltpl_btn = true;
                },
                setting_side() {
                    this.setting2 = true;
                },
                setting_close1() {
                    this.setting2 = false;
                },
                add_side(trans_id) {
                    this.trans_id=trans_id;
                    this.isadd = true;
                },
                isadd_close1() {
                    this.isadd = false;
                },
                edit_side(trans_id) {
                    this.trans_id=trans_id;
                    this.isedit = true;
                    this.get_transaction_by_id();
    },
                isedit_close1() {
                    this.isedit = false;
                },
                add_multiside() {
                    this.isaddmulti = true;
                },
                isadd_multiside_close1() {
                    this.isaddmulti = false;
                },
                isadd_multiside_close3() {
                    this.iseditmulti = false;
                },
                edit_multiside() {
                    this.iseditmulti = true;
                },
                isedit_multiside_close1() {
                    this.iseditmulti = false;
                },

                opening_bal_sidebar() {
                    this.opening_balance_sidebar = true;
                },
                close_opening_bal_sidebar() {
                    this.opening_balance_sidebar = false;
                },
    
            async getAccountList(userId,companyId) {
               
         const response = await postApi(
                "/get_bank_accounts",
                {
                user_id:userId,
                company_id: companyId,
                },
                {}
              );
              try {
                if (response.errorCode == "0") {
                  console.log(response.data);
               this.userAccountList=response.data;
            this.bank_id=response.data[0].id;
    this.getAccountBalance();
    this.getAccountDetail();
    this.get_account_statement();
                }
                return false;
              } catch (err) {
              alert(err);
              }
         }  ,
    
         async getAccountBalance() {
         const response = await postApi(
                "/get_bank_balance",
                {
               bank_id:this.bank_id
                },
                {}
              );
              try {
                if (response.errorCode == "0") {
            this.bank_balance=response.data.EFFECTIVEBAL;
                }
                return false;
              } catch (err) {
              alert(err);
              }
         },
    
         async getAccountDetail() {
         const response = await postApi(
                "/get_instabill_pending",
                {
               user_id:this.user_id,
               company_id: this.company_id,     
               bank_id:this.bank_id
                },
                {}
              );
              try {
                if (response.errorCode == "0") {
            this.pending_entry=response.data.pending_entry;
            this.instabill_balance=response.data.instabill_balance;
            this.opening_balance=response.data.opening_balance;
            this.open_bal=response.data.opening_balance;
            this.open_bal_date=response.data.opening_balance_date;
            this.opening_balance_date=response.data.opening_balance_date;
            this.account_no=response.data.account_no;
            this.created_at=response.data.created_at;
            this.bank_name=response.data.bank_name;
            this.ifsc_code=response.data.ifsc_code;
            
                }
                return false;
              } catch (err) {
              alert(err);
              }
         },
    
         async get_account_statement() {
            this.select_all=false;
         const response = await postApi(
                "/get_account_statement",
                {
               start_date:this.date,
               end_date: this.date2,     
                  bank_id:this.bank_id
                },
                {}
              );
              try {

                if (response.errorCode == "0") {
                    this.userAccountStatement=response.data;
                }
                else if(response.errorCode == "2"){
                    this.userAccountStatement=null;
                    this.alertMessageg = "All Bank Entries is already mapped";
                this.alertClassg = "error_msgg";
                this.messageg = true;
                setTimeout(() => this.messageg = false, 3000);
      
        return false;
                    // alert("Data Not Found");
                    // this.userAccountStatement=null;
                }
                else{
                    this.userAccountStatement=null;
                    this.alertMessage = "Data Not Found";
                this.alertClass = "error_msg";
                this.message = true;
                setTimeout(() => this.message = false, 3000);
      
        return false;
                    // alert("Data Not Found");
                    // this.userAccountStatement=null;
                }
                return false;
              } catch (err) {
              alert(err);
              }
         },
    
    async get_map_account_statement() {
        this.select_all=false;
         const response = await postApi(
                "/get_map_account_statement",
                {
               start_date:this.date,
               end_date: this.date2,     
                bank_id:this.bank_id
                },
                {}
              );
              try {
                if (response.errorCode == "0") {
                    this.userAccountStatement=response.data;
                }else{
                    this.alertMessage = "Data Not Found";
                this.alertClass = "error_msg";
                this.message = true;
                setTimeout(() => this.message = false, 3000);
                    // alert("Data Not Found");
                    this.userAccountStatement=null;
                }
                return false;
              } catch (err) {
              alert(err);
              }
         },
         
         goBack() {
        this.$router.go(-1)
      },   
                
            }
              
         }
    
         $(function () {
            $('#first').click(function () {
            $('#first').css({ "background": "linear-gradient(85deg, rgb(246, 106, 89), rgb(255, 174, 0))", "color": "#fff", "border": "1px solid rgb(246, 106, 89)" });
            $('#second').css({ "background": "#fff", "color": "#ffae00", "border": "1px solid #f66a59" });
            
            })
    });
         $(function () {
            $('#second').click(function () {
            $('#second').css({ "background": "linear-gradient(85deg, rgb(246, 106, 89), rgb(255, 174, 0))", "color": "#fff", "border": "1px solid rgb(246, 106, 89)" });
            $('#first').css({ "background": "#fff", "color": "#ffae00", "border": "1px solid #f66a59" });
            
            })
    });
    

    </script>
    
    <style>
 
    .uploader_purchase #response1.hidden1 {
        display: none;
    }
    div#mltpl-btn {
        position: absolute;
        width: 10%;
        background: #fff;
        border-radius: 5px!important;
    }
    button#first {    background: linear-gradient(85deg, rgb(246, 106, 89), rgb(255, 174, 0));
    
        border: 1px solid rgb(246, 106, 89);
        background: #fff;
        border: 1px solid #f66a59;
    }
    div#mltpl-btn div input {
        background: transparent;
        color: #000;
        font-size: 13px;
        border: 0px!important;
        border-bottom: 1px solid #e4dddd!important;
        width: 100%;
        padding: 6%;
    }
    div#start1 i {
        background: transparent;
        color: #565656;
    }
    li .v3dp__datepicker input {
        height: 38px!important;
        border-radius: 5px!important;
        text-align: center!important;
    }
    table.list-box {
        width: 99%;
         margin-left: 0%;}
    .card.card-statistics {
        background: linear-gradient(85deg, #f66a59, #ffae00);
        color: #ffffff;
    }
    button.unsync {
        background: #FF5E6D;
        color: #fff;
        border: 0px;
        border-radius: 18px;
        padding: 4px 11px;
        font-size: 13px;
    }
    .selected {border-radius:5px;
        background: #fda110!important;}
    .banktab {
        float: left;
        width: 35%!important;
        margin-right: 2%;
    }
    button.sync {
        background: #04b76b;
        color: #fff;
        border: 0px;
        border-radius: 18px;
        padding: 4px 11px;
        font-size: 13px;
    }
    .banking_right {
        width: 44%;
        float: right;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    .bank_Sec {
        margin-top: 2%;
        overflow: hidden;
    }
    label.badge.badge-outline-success.badge-pill {
        color: #fff;
        border: 1px solid #fff;
        padding: .5rem .75rem;
        margin-right: 1%;
    }
    
    .p_banking_add.p_banking_add_p {
        width: 38%;
        float: left;
        min-width: 38%!important;
        padding-top: 1%!important;
        border: 1px solid #F5A623;
        border-radius: 50px;
        padding: 6px 2%!important;
        text-align: center;
    }
    img.img-fluid.aa_p {
        position: absolute;
        left: 4%;
        width: 30px;
        height: 30px;
        top: 21%;
    }
    
    .p_banking_add.p_banking_add_p {
        width: 24%;
        float: left;
        min-width: 38%!important;
        padding-top: 8px!important;
        padding-bottom: 9px!important;
        position: relative;
        text-align: right;
    }
    .filter ul li {
        list-style: none;
        float: left;
        margin-right: 1%;
    }
    
    .filter ul {
        padding: 0px;
    }
    
    .orange_Color {
        color: #fff;
        background: linear-gradient(to top left, #ffae00 0%, #f66a59 100%) !important;
        border-color: #fea40d;
        border-radius: 5px!important;
        border: 0px!important;
        height: 38px;    margin-right: 3px!important;
        padding: 1% 4px!important;
    }
    button.btn.btn-success.orange_Color {
    border-radius: 5px !important;
    padding: -0px 9px !important;
    font-size: 15px;
}
    
    .searcha {
        display: inline-block !important;
        height: calc(2.25rem + 2px) !important;
        line-height: 1.5 !important;
        color: #495057 !important;
        vertical-align: middle !important;
        background: #fff !important;
        background-size: 8px 10px !important;
        border: 1px solid #ced4da !important;
        border-radius: 0.25rem !important;
        transition: all ease-out 2s !important;
        margin: 0px;
        margin-right: 15px;
        position: relative;width: 178px!important;
    }
    .search {
        width: 100%!important;
    }
    .searcha button {
        position: absolute;
        right: 0;
        top: 0px;
        border-radius: 0px!important;
        border: 0px;
        height: 100%;
        padding: 0px;
    }
    .searcha button i {
        border-radius: 0px!important;
    }
    .searcha input {
        width: 100%;
        height: 100%;
        background: transparent;
        border: 0px;
        padding: 1% 2%;
    }
    button#btnGroupDrop1 {
        color: #fff;
        background: linear-gradient(85deg, #f66a59, #ffae00);
    }
    .dropdown-menu.show a{    padding: 0% 4%;}
    .dropdown-menu.drop-add-css1.show {
        right: 0px !important;
        left: -400px !important;
        top: 98% !important;
        overflow-y: scroll;
        min-height: 218px;
        width: 400px;
        transform: none !important;
        max-height: 159px !important;
    }
    span.bank_N {
        color: #fc991b;
    }
    .banking_right a#setting_side {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    button#btnGroupDrop1 {margin-right: 5px;
        border-radius: 50px!important;
    }
    .bank_list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    button#btnGroupDrop1:hover {
        color: #fff;
    }
    label.badge.badge-outline-success.badge-pill {
        color: #fff;
        border: 1px solid #fff;
        padding: 0.5rem 0.75rem;
        margin-right: 1%;
    }
    .btn-group.btn-group_map {
        border-radius: 50px;
    }
    
    button#first {
        border-radius: 50px 0px 0px 50px!important;
    }
    .v3dp__datepicker {
        display: inline;
        float: left;
        width: 43%!important;
        margin-top: 0px;
        height: 38px;
        margin-right: 2%;
    }
    span.edit_s {
        color: #fff;
        border: 1px solid #fff;
        padding: 4px 7px;
        margin-right: 1%;
        border-radius: 50px;
        margin-left: 2%;
        cursor: pointer;
    }
    .add-gst h3 {
        color: #f97d40;
        font-size: 19px;
        text-transform: uppercase;
    }
    p.txt {
    width: 91%;
    float: left;
    margin: 0;
}

div#setting2 input {
    border: 1px solid #ccc !important;
    width: 100%;
    padding: 2%;
    border-radius: 5px;
}
.gstIn-corporate {
    margin: 9% 0%;
    margin-bottom: 8% !important;
}
div#setting2 textarea {
    border: 1px solid #ccc !important;
    width: 100%;
    padding: 2%;
    border-radius: 5px;
}
.error_msg {
    position: fixed;
    top: 0%;
    z-index: 999;
    width: 20%;
    padding: 1%;
    background: #fff;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    border-radius: 5px;
    right: 0;
    border-left: 4px solid #db0014;
    transition: 1s;
    color: red;
}
.error_msgg {
    position: fixed;
    top: 0%;
    z-index: 999;
    width: 25%;
    padding: 1%;
    background: #fff;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    border-radius: 5px;
    right: 0;
    border-left: 4px solid #00db92;
    transition: 1s;
    color: #00db92;
}
    div#opening_balance {
        display: block;
        float: right;
        z-index: 9999;
        position: fixed;
        right: 0;
        top: 0px;
        background: white;
        /* color: white; */
        width: 410px;
        height: 100%;
        overflow-y: auto;
        transition: right 1s linear;
        border: 1px solid #d4d7dc;
        border-right: none;
        border-top: none;
        box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
        padding: 1% 2%;
    }
    .gstIn label {
        font-size: 16px;
    }
    button#second {
        border-radius: 0px 50px 50px 0px!important;
    }
    button.verify-otp {
        background: linear-gradient(to top left, #ffae00 0%, #f66a59 100%) !important;
        border-color: #fea40d;
        color: #fff;    border-radius: 5px !important;
    }
    button#opening_balances1 {
        background: #4c4c4d !important;
        border: 0px;
        border-radius: 5px !important;
    }
    .clear{clear:both}
    </style>